import React, {useState, useEffect} from "react";


import MusicDashboard from "../../layouts/musicDashboard";
import Spinner from 'react-bootstrap/Spinner';
import config from '../../config/config';

import CrateSongTrack from '../../components/songTrack/crateSongTrack'
import {connect} from 'react-redux';
import * as crateActions from '../../store/crate/actions';
import * as songActions from '../../store/song/actions';

import { useNavigate } from "react-router-dom";


const CrateIndex = ({ getCrateList, crateSongs, crateGenres, getCustomerCrates, displayLoading, hideLoading, clearAllTrack, downloadBatchFiles, userProfile }) => {
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const perPageLimit =  config.itemsCountPerPage;
	const [currentGenreId, setCurrentGenreId] = useState(0);
	const [ dataProcessingDownload, setDataProcessingDownload ] = useState(false);
	const [ dataProcessingClearCrate, setDataProcessingClearCrate ] = useState(false);

	useEffect(() => { 
		loadPageData()
	},[]);
	
	const loadPageData = async () => {
		await getCrateList({page: page, pageLimit: perPageLimit, genre_id: currentGenreId}, () => {
			// this.setState({genres: this.props.song.latestSong});
		})
		await getCustomerCrates();
	}

	const getGenreCrate = async(genreID) => {
		setCurrentGenreId(genreID);
		await getCrateList({page: page, pageLimit: perPageLimit, genre_id: genreID}, () => {
		
		})
	}

	const emptyCrate = async () => {
		setDataProcessingClearCrate(true);
		await clearAllTrack();
		await getCustomerCrates();
		setDataProcessingClearCrate(false);
	}

	const downloadBatch = async () => {
		setDataProcessingDownload(true);
		if(userProfile && userProfile.group_id == 2){
			if(userProfile.payment_profile_status == 'paid'){
				const data = {
					genre_id: currentGenreId
				}
				await downloadBatchFiles(data).then(res => {
				if(res.payload.response && res.payload.response.status === 403){
					alert(res.payload.response.data.message);
				} else if(res.payload.data) {
					const data = res.payload.data;
					const zipUrl = `${config.downloadSelfUrl}tmp/${data.zip}`;
					window.location.assign(zipUrl);
				}
				});
				await getCrateList({page: 1, pageLimit: perPageLimit, genre_id: 0}, () => {
				
				});
				setCurrentGenreId(0);
			} else if(userProfile.payment_profile_status == 'unpaid'){
				navigate('/subscription/plans')
			}
		} else if(userProfile && userProfile.group_id == 3){
				const data = {
				genre_id: currentGenreId
				}
				await downloadBatchFiles(data).then(res => {
				if(res.payload.response && res.payload.response.status === 403){
					alert(res.payload.response.data.message);
				} else if(res.payload.data) {
					const data = res.payload.data;
					const zipUrl = `${config.downloadSelfUrl}tmp/${data.zip}`;
					window.location.assign(zipUrl);
				}
				});
				await getCrateList({page: 1, pageLimit: perPageLimit, genre_id: 0}, () => {
				
				});
				setCurrentGenreId(0);
		}
		setDataProcessingDownload(false);
	}
	
  return (
    <MusicDashboard accountPages={true}>
       <div className="content-sec">
            <div className="play-list-sec">
                <div className="top-heading">
                    <h2>My Crate</h2>
                    <div className="btn-sec">
                        { dataProcessingDownload ? <button className="primary-btn" ><Spinner animation="border" variant="dark" size="sm" /></button> : <button className="primary-btn" onClick={() => downloadBatch()}>Download All</button> }
  						{ dataProcessingClearCrate ? <button className="secondary-btn" ><Spinner animation="border" variant="dark" size="sm" /></button> : <button className="secondary-btn" onClick={() => emptyCrate()}>Clear All</button> }
                    </div>
                </div>
                <div className="inner-block">
                    <div className="top-list">
                        <ul>
                            { crateGenres && crateGenres.length > 0 ? <li className={currentGenreId == 0 ? 'active': ''} onClick={()=> getGenreCrate(0)}><span >All</span></li> : ''}
                            {
                                crateGenres && crateGenres.length > 0 ?
                                crateGenres.map((crate, index) => {
                                    return <li className={ currentGenreId == crate.genre_id ? 'active' : ''} key={`crategenre${crate.genre_id}`} onClick={()=> getGenreCrate(crate.genre_id)}><span dangerouslySetInnerHTML={{__html: crate.name}} ></span></li>
                                })
                                : ''
                            }
                        </ul>
                    </div>
                    {
                        crateSongs && crateSongs.length > 0 ?
                        <CrateSongTrack getParentCustoemrCrates={getGenreCrate} songs={crateSongs} currentGenreId={currentGenreId} perPageLimit={perPageLimit} page={page}  />
                        : ''
                    }
                </div>
        
          {/* { crateSongs && crateSongs.length > 0 ?  <Button variant="light" className="primary-bg" onClick={() => downloadBatch()}>Download batch</Button> : '' }
					{ crateSongs && crateSongs.length > 0 ? <Button variant="light" className="ml-4" onClick={() => emptyCrate()}>clear all tracks</Button> : '' } */}
          

        
          
          </div>
        </div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
    crateGenres: state.crate.crateGenres,
	crateSongs: state.crate.crateSongs,
	userProfile: state.customer.customerProfile    
  };
}

function mapDispatchToProps(dispatch) {
  return {
      getCrateList: (post) => dispatch(crateActions.getCrateList(post)),
      getCustomerCrates: () => dispatch(songActions.getCustomerCrates()),
      clearAllTrack: () => dispatch(crateActions.clearAllTrack()),
      downloadBatchFiles: (data) => dispatch(crateActions.downloadBatchFiles(data))

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CrateIndex);