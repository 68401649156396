import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import {connect} from 'react-redux';

// import * as themeAction from '../../store/theme/actions';


const  Search = ({ }) => {

    const navigate = useNavigate();
    const [toggleClass, setToggleClass] = useState(false);
    const [searchTerm, setSearchTerm ] = useState();
    const [toggleSearch, setToggleSearch] = useState(false);

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    }
  
    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            if(searchTerm && searchTerm.length > 2){
                navigate(`/search/result/${searchTerm}`);
            }
        }
    }
    
    return (
            
            <InputGroup className={toggleSearch ? "search" : "search" }>
                
                    <InputGroup.Text id="Search">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.76557 14.9599C12.1866 14.9599 14.9599 12.1866 14.9599 8.76557C14.9599 5.34457 12.1866 2.57129 8.76557 2.57129C5.34457 2.57129 2.57129 5.34457 2.57129 8.76557C2.57129 12.1866 5.34457 14.9599 8.76557 14.9599Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M17.4283 17.4283L13.1426 13.1426" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                    </InputGroup.Text>
                
                <Form.Control
                  placeholder="Search for songs or anything..."
                  aria-label="Search"
                  aria-describedby="Search"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
            </InputGroup>

);
  
}


function mapStateToProps(state) {
  return {
    
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(Search);
