import React , {useState, useEffect} from "react";
import "./singlePageLayout.scss";


//custom components ----------------------------------------------------------------
import {connect} from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import { showLoading, hideLoading } from 'react-redux-loading-bar';



const SinglePageLayout = ({children, themeType}) => {

  useEffect(() => {
    console.log('themeType', themeType)
		if(themeType === 'light-theme'){
			document.body.classList.add('light-theme');
		} else {
			document.body.classList.add('dark-theme');
		}

	},[]);
  
  return (
    <div className="App">
      {/* <LoadingBar className="loading" style={{ backgroundColor: '#ffa800', height: '3px', position: 'fixed', zIndex: '999' }} updateTime={100} maxProgress={95} progressIncrease={10} /> */}
      {children}
    </div>
  );
};

function mapStateToProps(state) {
  
  if (state.authentication.loggedIn !== undefined && state.authentication.loggedIn) {
     // window.location.href= "/";
  }
 
  return {
    loggingIn: state.authentication.loggedIn,
    themeType: state.theme.theme,
  };
    
}

function mapDispatchToProps(dispatch) {
  return {
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SinglePageLayout);