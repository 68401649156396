import React, { useState, useEffect } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";

// import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import RightSide from "../../components/rightSide/rightSide";
import Pagination from "react-js-pagination";

import MusicDashboard from '../../layouts/musicDashboard';


import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import * as actions from '../../store/trending/actions';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
// import RightSide from "../../components/rightSide/rightSide";
// import Player from "../../components/player/player";

const TrendingTrackAlbums = ({ getTrendingTracksAlbums, albums, displayLoading, hideLoading }) => {

    const [ page, setPage ] = useState(0);

    useEffect(() => { 
		loadPageData();
	},[])

	const loadPageData = async() => {
		displayLoading();
		await getTrendingTracksAlbums({page: 0, limit: 42, pagination: true});
		hideLoading();
    }

    const handlePageChange = async (pageNumber) => {
		displayLoading();
		setPage(pageNumber);
		await getTrendingTracksAlbums({page: pageNumber, limit: 42, pagination: true});
		hideLoading();
	}
    
    

    return (
        <MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true}} />}>
        <div className="content-sec">
            {/* <div className="most-download">
                <CuratedSpotlightCarousel />
            </div> */}
            <div className="latest-spotlights">
                <div className="top-heading">
                    <h2>Trending Tracks</h2>
                </div>
                <div className="latest-spot-block">
                {
                    albums && albums.tracks && albums.tracks.length > 0 ?
                    albums.tracks.map((album, index) => {
                                return <Link className="box" key={`newreleasespotlight${album.trending_track_id}`} to={`/trending-tracks/songs/${album.trending_track_id}`}>
                                            <img src={album.cover_image ? album.cover_image : ''} />
                                            <div className="latest-info"> <h5>{album.created_at}</h5></div>            
                                    </Link>
                        })
                    : ''
                }
                </div>
            </div>
            <div className="pagingnation-sec">
            {
			albums && albums.pagination ? 
				<Pagination
					activePage={albums.pagination.page}
					itemsCountPerPage={albums.pagination.perPageLimit}
					totalItemsCount={albums.pagination.totalCount}
					// pageRangeDisplayed={pageRangeDisplayed}
					onChange={handlePageChange}
					itemClass="page-item"
					linkClass="page-link"
			/> : ''
			}
            </div>
        </div>
    </MusicDashboard>
    )

}
function mapStateToProps(state) {
    return {
        albums: state.trending.albums
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        getTrendingTracksAlbums: (data) => dispatch(actions.getLatestTrendingTrackAlbums(data)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(TrendingTrackAlbums);