import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {connect} from 'react-redux';


const  ImageSliderLoader = ({ itemCount }) => {

    var indents = [];
    for (var i = 0; i < itemCount; i++) {
    indents.push(<div className="wrapper-row-image" key={`wrapper-row${i}`}>
                    <div className="wrapper-image">
                        <div className="image"></div>
                    </div>
                    <div className="wrapper-image">
                        <div className="image"></div>
                    </div>
                    <div className="wrapper-image">
                        <div className="image"></div>
                    </div>
                    <div className="wrapper-image">
                        <div className="image"></div>
                    </div>
                    <div className="wrapper-image">
                        <div className="image"></div>
                    </div>
                    <div className="wrapper-image">
                        <div className="image"></div>
                    </div>
                </div>);
    }

    const settings = {
		className: "slider variable-width",
		dots: false,
		infinite: true,
		centerMode: false,
		slidesToShow: 4,
		slidesToScroll: 4,
		variableWidth: true,
		arrows: true,
		responsive: [
		  {
			breakpoint: 1700,
			settings: {
			  slidesToShow: 3,
			  slidesToScroll: 3,
			}
		  },
		  {
			breakpoint: 1600,
			settings: {
			  slidesToShow: 2,
			  slidesToScroll: 2,
			}
		  },
		  {
			breakpoint: 1000,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			}
		  },
		]
	};

    return (
        <Slider {...settings}>{indents}</Slider>	
    );
  
}


function mapStateToProps(state) {
	return {
		
	};
}
 
function mapDispatchToProps(dispatch) {
	return {
		
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageSliderLoader);
