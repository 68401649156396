import * as types from './type';

import { Song }  from  '../../services/song';

export function getHomePageGenreSongs() {
    return {
      type: types.GET_HOME_GENRE_SONG,
      payload: Song.getHomePageGenreSongs(),
    };
};

export function getNewRelease(params) {
  return {
    type: types.GET_NEW_RELEASE_SONG,
    payload: Song.getNewRelease(params),
  };
}

export function getTop20Albums(){
  return {
    type: types.GET_TOP_20_ALBUMS,
    payload: Song.getTop20Albums(),
  };
}

/**
 * @method addSongToCrate
 * @param {*} songId, versionId
 * @description add song to User Cruate
 * @returns array
 */
export function addSongToCrate(songId, versionId){
  return {
    type: types.ADD_SONG_TO_CRATE,
    payload: Song.addSongToCrate(songId, versionId),
  };
}


/**
 * @method addSongToFavourite
 * @param {*} songId 
 * @description add song to User Favourite
 * @returns array
 */
export function addSongToFavourite(songId){
  return {
    type: types.ADD_SONG_TO_FAVOURITE,
    payload: Song.addSongToFavourite(songId),
  };
}

/**
 * @method getCustomerCrates
 * @param {*}  
 * @description return Customer Cruate
 * @returns array
 */
export function getCustomerCrates(songId){
  return {
    type: types.ADD_SONG_TO_CRATE,
    payload: Song.getCustomerCrates(songId),
  };
}

/**
 * @method getCustomerFavouriteSongs
 * @param {*}  
 * @description return Customer Favourite Songs
 * @returns array
 */
export function getCustomerFavouriteSongs(data){
  return {
    type: types.GET_CUSTOMER_FAVOURITE_SONGS,
    payload: Song.getCustomerFavouriteSongs(data),
  };
}

/**
 * @method getFavouriteSongs
 * @param {*}  
 * @description return Favourite Songs
 * @returns array
 */
export function getFavouriteSongs(){
  return {
    type: types.GET_FAVOURITE_SONGS,
    payload: Song.getFavouriteSongs(),
  };
}

/**
 * @method setCurrentSong
 * @param {song}  
 * @description set current song
 * @returns current song
 */
export function setCurrentSong (song) {
  
  return {
      type: types.SET_CURRENT_SONG,
      payload: song,
  };
}




/**
 * @method renewSong
 * @param {data}  
 * @description renew song
 * @returns jsng
 */
export function renewSong (data) {
  
  return {
      type: types.RENEW_SONG,
      payload: Song.renewSong(data),
  };
}


/**
 * @method createSong
 * @param {data}  
 * @description create a new song entry
 * @returns json
 */
export function createSong (data) {
  
  return {
      type: types.CREATE_NEW_SONG,
      payload: Song.createSong(data),
  };
}


/**
 * @method getSongEntry
 * @param {data}  
 * @description get Uploaded song entity by self
 * @returns json
 */
export function getSongEntry (data) {
  
  return {
      type: types.EDIT_SONG_ENTITY,
      payload: Song.getSongEntry(data),
  };
}


  /**
   * @method updateSong
   * @param {data}  
   * @description update song entity 
   * @returns json
   */
  export function updateSong (data) {
  
  return {
      type: types.UPDATE_SONG_ENTITY,
      payload: Song.updateSong(data),
  };

  
}

/**
  * @method deleteSong
  * @param {data}  
  * @description delete song entity 
  * @returns json
  */
export function deleteSong (data) {
  return {
      type: types.DELETE_SONG_ENTITY,
      payload: Song.deleteSong(data),
  };
}

/**
* @method getSearchResultSongs
* @param {data}  
* @description return mp3 search result 
* @returns json
*/
export function getSearchResultSongs (data) {
  return {
      type: types.MP3_SEARCH_RESULT,
      payload: Song.getSearchResultSongs(data),
  };
}

/**
* @method getChartSongs
* @param {data}  
* @description return mp3 search result 
* @returns json
*/
export function getChartSongs (data) {
  return {
      type: types.CHART_SONGS,
      payload: Song.getChartSongs(data),
  };
}

export function searchMatches(data) {
  return {
      type: types.AUTOCOMPLETE_SEARCH_SONGS,
      payload: Song.searchMatches(data),
  };
}

export function setPlayerSong(data) {
  return {
      type: types.SET_PLAYER_SONGS,
      payload: data,
  };
}