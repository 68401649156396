import React, {useState, useEffect} from "react";


import MusicDashboard from "../../layouts/musicDashboard";
import Spinner from 'react-bootstrap/Spinner';
import config from '../../config/config';

import CrateSongTrack from '../../components/songTrack/crateSongTrack'
import {connect} from 'react-redux';
import * as crateActions from '../../store/crate/actions';
import * as songActions from '../../store/song/actions';

import { useNavigate } from "react-router-dom";


const DropboxAuth = ({  }) => {
	

	useEffect(() => { 
		loadPageData()
	},[]);
	
	const loadPageData = async () => {
		
	}

	
	
  return (
    <MusicDashboard accountPages={true}>
       <div className="content-sec">
            <div className="play-list-sec">
                <div className="top-heading">
                    <div className="btn-sec">
                        <Spinner animation="border" variant="dark" size="lg" />
                    </div>
                </div>
          </div>
        </div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
    userProfile: state.customer.customerProfile    
  };
}

function mapDispatchToProps(dispatch) {
  return {
//    downloadBatchFiles: (data) => dispatch(crateActions.downloadBatchFiles(data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DropboxAuth);