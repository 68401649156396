import React from "react";
import {connect} from 'react-redux';


const  ImageLoader = ({ itemCount }) => {

    var indents = [];
    
    for (var i = 0; i < itemCount; i++) {
    indents.push(
                    <div className="wrapper-image"  key={`wrapper-row${i}`}>
                        <div className="image"></div>
                    </div>
                    
                );
    }

    return (
        <div className="wrapper-row-image wrapper-multiple-image">{indents}</div>	
    );
  
}


function mapStateToProps(state) {
	return {
		
	};
}
 
function mapDispatchToProps(dispatch) {
	return {
		
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageLoader);
