import React, {useState, useEffect} from "react";

import SinglePageLayout from "../../layouts/singlePageLayout/singlePageLayout";
import {connect} from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import logo from './../../assets/images/Logo.svg';

import * as actions from '../../store/customer/actions';

const ResetPassword = ({ resetPassword  }) => {
    const navigate = useNavigate();
    let match = useParams("/reset-password/:email/:token");
    const [ errors, setErrors ] = useState({})
    const [processing, setProcessing] = useState(false);
    const [resetErr , setResetErr ] = useState();
    const [showNewPass, setShowNewPass] = useState(false);
    const [newPasswordType, setNewPasswordType] = useState('password');
    const [showRepeatPass, setShowRepeatPass] = useState(false);
    const [repeatPasswordType, setRepeatPasswordType] = useState('password');
    const[ successMessage, setSuccessMessage ] = useState();
    const [formData, setFormData] = useState({
        password: '',
        confirm_password: ''
    });
    const [validated, setValidated] = useState(false);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value
      });
    }
    
    const showNewPassword = () => {
        if(!showNewPass){
            setNewPasswordType('text');
          } else {
            setNewPasswordType('password');
          }
          setShowNewPass(!showNewPass);
    }
    const showRepeatPassword = () => {
        if(!showRepeatPass){
            setRepeatPasswordType('text');
          } else {
            setRepeatPasswordType('password');
          }
          setShowRepeatPass(!showRepeatPass);
    }

    const findFormErrors = () => {
        const { password, confirm_password, } = formData
        const newErrors = {}
        
        // password errors
        if ( !password || password === '' ) newErrors.password = 'password cannot be blank!'
        // confirm password errors
        if ( !confirm_password || confirm_password === '' ) newErrors.confirm_password = 'confirm password cannot be blank!'
        else if ( confirm_password != password ) newErrors.confirm_password = 'password mismatch'
    
        return newErrors
    }

    const handleSubmit = (event) => {
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
    
        if ( Object.keys(newErrors).length > 0 ) {
          console.log(newErrors)
          // We got errors!
          setErrors(newErrors)
        } else {
          resetPasswordRequest(formData);
        }
    }

    const resetPasswordRequest = async(post) => {
        setProcessing(true);
        post.email = match.email;
        post.token = match.token;
        resetPassword(post).then(res => {
        
            if(res.payload.response && res.payload.response.status === 401){
                setResetErr(res.payload.response.data.message);
            } else if(res.payload.response && res.payload.response.status === 500){
                setResetErr(res.payload.response.data.message.message);
            } else if(res.payload.data) {
                setSuccessMessage('Password changed successfully.');
                setTimeout(
                    () => resetSuccessMessage(), 
                    3000
                );
            }
            setProcessing(false);
        });
    }

    const resetSuccessMessage = () => {
        window.location.href= "/sign-in";
    }

	
  return (
    <SinglePageLayout>
        <div className="sign-in">
			<div className="sign-in-left">
				<div className="top">
					<Link to={`/sign-in`} className="logo"><img src={logo} alt="Clubkillers sign in" title="Clubkiller sing in"/></Link>
					<Link to={`/sign-in`} className="close">
						<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
						</svg>
					</Link>
				</div>
				<div className="form-sec">
					<Link to={`/sign-in`} className="back">
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
					</Link>
					<h1>Reset Password</h1>
					
					<div>
                        { resetErr && resetErr !== '' ? <div className="alert alert-danger">{resetErr}</div> : '' }
                        { successMessage && successMessage !== '' ? <div className="alert alert-success">{successMessage}</div> : '' }
						<Form noValidate validated={validated} onSubmit={handleSubmit} id="forgetPasswordForm" className="popup-form">
                            <div className="form-group" controlId="formBasicEmail" >
                                <Form.Label className="text-5">New Password</Form.Label>
                                <InputGroup className="input-pass" controlId="formNewPassword" >
                                    <Form.Control type={newPasswordType} placeholder="New Password" name="password"  onChange={handleChange} isInvalid={ !!errors.password } autocomplete="off" />
                                    <InputGroup.Text>
                                        {/* <i onClick={showNewPassword} class={ showNewPass ? 'lnir lnir-eye' : 'lnir lnir-eye strike'}></i> */}
                                        <svg onClick={showNewPassword} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.80658 7.06992C6.03344 1.64336 13.9666 1.64336 17.1934 7.06992C18.2689 8.87846 18.2689 11.1215 17.1934 12.9301C13.9666 18.3566 6.03344 18.3566 2.80658 12.9301C1.73114 11.1215 1.73114 8.87846 2.80658 7.06992Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1615 10.0531C13.1615 11.7991 11.7455 13.2141 9.9995 13.2141C8.2535 13.2141 6.8385 11.7991 6.8385 10.0531C6.8385 8.30611 8.2535 6.89111 9.9995 6.89111C11.7455 6.89111 13.1615 8.30611 13.1615 10.0531Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                            { showNewPass ? '' : <path d="M17.5 3L2 17" stroke="#D7D7D7" strokeLinecap="round"/> }
                                        </svg>
                                    </InputGroup.Text>
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">
                                    { errors.password }
                                </Form.Control.Feedback>
                            </div>
                            <div className="form-group">
                                <Form.Label className="text-5">Confirm Password</Form.Label>
                                <InputGroup className="input-pass" controlId="formConfirmPassword" >
                                    
                                    <Form.Control type={repeatPasswordType} placeholder="Confirm Password" name="confirm_password"  onChange={handleChange} isInvalid={ !!errors.confirm_password } autocomplete="off" />
                                    <InputGroup.Text>
                                        {/* <i onClick={showRepeatPassword} class={ showRepeatPass ? 'lnir lnir-eye' : 'lnir lnir-eye strike'}></i> */}
                                        <svg onClick={showRepeatPassword} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.80658 7.06992C6.03344 1.64336 13.9666 1.64336 17.1934 7.06992C18.2689 8.87846 18.2689 11.1215 17.1934 12.9301C13.9666 18.3566 6.03344 18.3566 2.80658 12.9301C1.73114 11.1215 1.73114 8.87846 2.80658 7.06992Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1615 10.0531C13.1615 11.7991 11.7455 13.2141 9.9995 13.2141C8.2535 13.2141 6.8385 11.7991 6.8385 10.0531C6.8385 8.30611 8.2535 6.89111 9.9995 6.89111C11.7455 6.89111 13.1615 8.30611 13.1615 10.0531Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                            { showRepeatPass ? '' : <path d="M17.5 3L2 17" stroke="#D7D7D7" strokeLinecap="round"/> }
                                        </svg>
                                    </InputGroup.Text>
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">
                                    { errors.confirm_password }
                                </Form.Control.Feedback>
                            </div>
							
							<div className="form-group">
                            { processing ? <button className="primary-btn"><Spinner animation="border" variant="dark" size="sm" /></button> : <button type="submit" className="primary-btn">Save changes</button> }
							</div>
						</Form>
						
					</div>
				</div>
			</div>
			<div className="sign-in-right">
				<div className="inner-bg">
					<div className="content">
						<div className="custom-font">Enjoy Your Favourite Music With Club Killers</div>
						<h2>Club Killers - an mp3 pool, offering special edits to Dj's and disc jockeys.</h2>
					</div>
				</div>
			</div>
		</div>
    </SinglePageLayout>
  );
}

function mapStateToProps(state) {
  return {
	
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetPassword: (post) => dispatch(actions.resetPassword(post)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);