import React, { useState, useEffect } from "react";

import {connect} from 'react-redux';
import InfoButton from '../../elements/songElements/infoButton';
import GenresInfoButton from '../../elements/songElements/genresInfoButton';
import FavouriteButton from '../../elements/songElements/favouriteButton';
import DownloadAllButton from '../../elements/songElements/downloadAllButton';
import DropboxAllButton from '../../elements/songElements/dropboxAllBtn';
import SongToggleButton from '../../elements/songElements/songToggleButton';
import PlayPauseButton from '../../elements/songElements/playPauseButton';
import SongVersion from './songVersion';

const  SongTrack = ({ songs, sectionName, activeBtn }) => {

    const [toggleSong, setToggleSong ] = useState(false);
    const [activeSongId, setActiveSongId] = useState(0);

    const setSongToggle = (songId) => {
        
        if(activeSongId == songId ){
            setToggleSong(!toggleSong)
        } else {
            setToggleSong(true)
        }
        setActiveSongId(songId)
    }

    return (
        <div className="play-list">
        {
            songs && songs.length > 0 ? 
                songs.map((song, index)=>{
                    return <div className={toggleSong && activeSongId == song.entity_id ? "block active" : "block"} key={`song${sectionName}${song.entity_id}`}>
                                <div className="play-track" onClick={() => setSongToggle(song.entity_id)}>
                                    <PlayPauseButton parentComponent="songTrack" song={song} />
                                    <div className="track-name">
                                        <span className="text-2" dangerouslySetInnerHTML={{__html: song.title}}></span>
                                        <span className="text-5" dangerouslySetInnerHTML={{__html: song.artist}}></span>
                                    </div>
                                </div>
                                <div className="play-track-content">
                                    <div>
                                        <span className="text-5">{song.max_bpm}</span>
                                        <span className="text-4">{song.song_key}</span>
                                        <span className="text-5">{song.created_at}</span>
                                    </div>
                                    {/* <div className="divider">
                                        <InfoButton song={song} />
                                        <GenresInfoButton song={song} />
                                    </div> */}
                                    <div className="divider">
                                        <FavouriteButton song={song} />
                                        { activeBtn == 'download' ? <DownloadAllButton entity_id={song.entity_id} /> : ''}
                                        { activeBtn == 'dropbox' ? <DropboxAllButton entity_id={song.entity_id} /> : ''}
                                    </div>
                                    <div>
                                        <SongToggleButton songId={song.entity_id} setSongToggle={setSongToggle} toggleSong={toggleSong}  />
                                    </div>
                                </div>
                                <div className="version-container">
                                {
                                    song.version && song.version.length > 0 ?
                                        song.version.map((version, index) => {
                                            return <div className='block-inner-content' key={`song${sectionName}version${song.entity_id}${version.song_version_id}`}>
                                                <SongVersion version={version} songId={song.entity_id} songSectionId={song.song_section_id} song={song} />
                                            </div>
                                        })
                                    : ''
                                }
                                </div>
                        </div>
                    })
            : ''
        }
        </div>
    );
  
}


function mapStateToProps(state) {
  return {
    activeBtn: state.activeDownloadBtn.activeDownloadBtn
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(SongTrack);
