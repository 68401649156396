import React, { useState, useEffect } from "react";


import Top30TrendingTracks from '../../components/top30TrendingTracks/top30TrendingTracks';
import CuratedGenres from '../../components/spotlight/curatedGenres';
import RecentPlayedSong from './recentlyPlayedSongs';

import {connect} from 'react-redux';

const RightSide = ({ blocks}) => {
  
  useEffect(() => { 
//    console.log('blocks.recentPlayedSong', blocks.recentPlayedSong)
  },[])

  return (
	<div className="right-trending">
					{/* { blocks && blocks.nowPlaying ?  <NowPlaying /> : '' }
					{ blocks && blocks.recentPlayedPlayList ?  <RecentPlayedPlayList /> : '' }
					
					{ blocks && blocks.suggestedCollection ?  <SuggestedCollection /> : '' } */}
					{ blocks && blocks.recentPlayedSong ?  <RecentPlayedSong /> : '' }
					{ blocks && blocks.curatedFilter ?  <CuratedGenres /> : '' }
					{ blocks && blocks.top30TrendingTracks ? 
															<Top30TrendingTracks />
					: ''
					}
					{/* {
						blocks && blocks.mostDownloaded && mostDownloadedToday && mostDownloadedToday.length > 0 ?
							<aside>
								<h3>Most Downloaded Today</h3>
								<Titletracks type="mostdownloaded" songs={mostDownloadedToday} />
							</aside>
						: ''
					} */}
		</div>
  );
  
}



function mapStateToProps(state) {
  return {
    
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(RightSide);