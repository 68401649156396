import React, {useEffect, useState} from "react";

//custom components
import MusicDashboard from '../../layouts/musicDashboard';
import SongTrack from '../../components/songTrack/songTrack';
import RightSide from "../../components/rightSide/rightSide";
import Spinner from 'react-bootstrap/Spinner';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
// import Player from "../../components/player/player";
import Pagination from "react-js-pagination";

import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import { useLocation, useParams } from "react-router-dom";
import {connect} from 'react-redux';
import * as songActions from '../../store/song/actions';
import * as genreActions from '../../store/genres/actions';
import * as versionAction from '../../store/version/actions'


const  SearchResultMp3 = ({getSearchResultSongs, searchResult, paginationData, displayLoading, hideLoading, genres, getGenresWithCount, versions, getVersions }) => {
  let match = useParams("/search/result/:searchTerm");
  const location = useLocation();
  const [ processingFilter, setProcessingFilter ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ order, setOrder ] = useState('entity_id');
  const [ orderDir, setOrderDir ] = useState('DESC');
  const [ songSectionId, setSongSectionId ] = useState(1);
  const [currentGenreId, setCurrentGenreId] = useState();
  const [ searchTerm, setSearchTerm ] = useState();
  const [ searchModel , setSearchModel ] = useState({
    bpm: '',
    song_key: '',
    year: '',
    title: '',
    artist: '',
  })
  const [ selectedVersion, setSelectedVersion ] = useState();
  
  
  useEffect(() => { 
    // load Page Data
    loadPageData();
  },[]);
  
  const loadPageData = async() => {
    displayLoading();
    if(match){
      setSearchTerm(match.searchTerm);
      await getSearchResultSongs({page: page, order: order, orderDir, song_section_id: songSectionId, searchTerm: match.searchTerm});
    }
    setCurrentGenreId('all');
    setSelectedVersion('0');
   
    await getVersions();
    await getGenresWithCount();

    
    hideLoading();
    
		
  }
  const handlePageChange = (pageNumber) => {
      setPage(pageNumber);
      
      displayLoading();
      if(match){
        if(currentGenreId === 'all'){
          getSearchResultSongs({page: pageNumber, order: order, orderDir, song_section_id: songSectionId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
            hideLoading();
          })
        } else {
          getSearchResultSongs({page: pageNumber, order: order, orderDir, song_section_id: songSectionId, genre_id: currentGenreId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
            hideLoading();
          })
        }
      }
      
  }

  const genresSong = (genre_id) => {
    setCurrentGenreId(genre_id)
    displayLoading();
    if(match){
      getSearchResultSongs({page: 1, order: 'entity_id', orderDir: 'DESC', song_section_id: songSectionId, genre_id: genre_id, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
        hideLoading();
      })
    }
  }
  const getAllSong = () => {
    setCurrentGenreId('all')
    displayLoading();
    getSearchResultSongs({page: 1, order: 'entity_id', orderDir: 'DESC', song_section_id: songSectionId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
      hideLoading();
    })
  }

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value);
    
  }

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
        setSearchModel({
            ...searchModel,
            [name]: value
        });
  }

  const filterResult = async () => {
    displayLoading();
    setProcessingFilter(true);
    if(currentGenreId === 'all'){
      getSearchResultSongs({page: 1, order: order, orderDir, song_section_id: songSectionId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
        hideLoading();
        setProcessingFilter(false);
      })
    } else {
      getSearchResultSongs({page: 1, order: order, orderDir, song_section_id: songSectionId, genre_id: currentGenreId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
        hideLoading();
        setProcessingFilter(false);
      })
    }
  }

  const getAllVersionSong = async(versionId) => {
    displayLoading();
    setSelectedVersion(versionId);
   
    getSearchResultSongs({page: 1, order: order, orderDir, song_section_id: songSectionId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: versionId}).then((res) => {
      hideLoading();
    })
  }

  return (
  <MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true}} />} middleSectionCssClass={'account-info-sec full-section'}>
        <div className="content-sec">
            <div className="play-list-sec account-block">
                <div className="top-heading">
                    <h2>Search Results</h2>
                </div>
                <div className="inner-block account-inner-block search-result">
                    <div className="cardMark mb-3 d-flex justify-content-between align-items-center">
                        <span>Filter Result By</span> 
                        { processingFilter ? <button className="primary-btn"><Spinner animation="border" variant="dark" size="sm" /></button> : <button className="primary-btn" onClick={() => filterResult()}>Apply Filter</button>} 
                    </div> 
                    <div>
                    <div className="form-content ">
                        <InputGroup className="form-group">
                            <Form.Control
                            placeholder="Search for songs or anything..."
                            aria-label="Search"
                            aria-describedby="Search"
                            onChange={handleChangeSearchTerm}
                            value={searchTerm}
                            />
                        </InputGroup>
            
                        <InputGroup className="form-group">
                                <Form.Control
                                placeholder="Search by song name"
                                aria-label="songTitle"
                                aria-describedby="songTitle"
                                onChange={handleChangeSearch}
                                value={searchModel.title}
                                name="title"
                                />
                            </InputGroup>
                    
                    <InputGroup className="form-group">
                            <Form.Control
                            placeholder="Search by artist"
                            aria-label="artist"
                            aria-describedby="artist"
                            onChange={handleChangeSearch}
                            value={searchModel.artist}
                            name="artist"
                            />
                        </InputGroup>
                    
                    <InputGroup className="form-group">
                            <Form.Control
                            placeholder="Search by BPM"
                            aria-label="BPM"
                            aria-describedby="BPM"
                            onChange={handleChangeSearchTerm}
                            value={searchModel.bpm}
                            name="bpm"
                            />
                        </InputGroup>
                    
                    <InputGroup className="form-group">
                            <Form.Control
                            placeholder="Search by year"
                            aria-label="songTitle"
                            aria-describedby="songTitle"
                            onChange={handleChangeSearch}
                            value={searchModel.year}
                            name="year"
                            />
                        </InputGroup>
                    
                    <InputGroup className="form-group">
                            <Form.Control
                            placeholder="Search by song key"
                            aria-label="artist"
                            aria-describedby="artist"
                            onChange={handleChangeSearch}
                            value={searchModel.song_key}
                            name="song_key"
                            />
                        </InputGroup>
                    </div>
                    <div className="cardMark mb-3 d-flex justify-content-between align-items-center"><span>Filter By Genre</span></div>
                        <div className="top-list">
                        { genres && genres.length > 0 ?
                            <ul className="badgeList d-none d-lg-block mb-4">
                                <li className={currentGenreId === 'all' ? 'active' : '' }><span onClick={() => getAllSong('0')}>All</span></li>
                                {
                                genres.map((genre, index)=>{
                                    return <li key={`genre-filter-${genre.genre_id}`} className={currentGenreId === genre.genre_id ? 'active' : '' }><span onClick={() => genresSong(genre.genre_id)} dangerouslySetInnerHTML={{__html:genre.name}}></span></li>
                                })
                                }
                            </ul>
                            : ''
                        }
                    </div>
                    </div>
                    <div className="cardMark mb-3 d-flex justify-content-between align-items-center"><span>Filter By Version</span></div>
                    <div className="top-list">
                    { 
                        versions && versions.length > 0 ?
                            <ul className="">
                                <li className={selectedVersion === '0' ? 'active' : '' }><span onClick={() => getAllVersionSong('0')}>All</span></li>
                                {
                                versions.map((version, index)=>{
                                    return <li key={`version-filter-${version.version_id}`} className={selectedVersion === version.version_id ? 'active' : '' }><span  onClick={() => getAllVersionSong(version.version_id)}>{version.name}</span></li>
                                })
                                }
                            </ul>
                        : ''
                    }
                    </div>
                
                    <SongTrack songs={searchResult}   /> 
                </div>
            </div>
        
            <div className="pagingnation-sec">
            {
            paginationData ? 
                <Pagination
                    activePage={paginationData.page}
                    itemsCountPerPage={paginationData.perPageLimit}
                    totalItemsCount={paginationData.totalCount}
                    // pageRangeDisplayed={pageRangeDisplayed}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
            /> : ''
            }
            </div>
        </div>
    </MusicDashboard>
  );
}


function mapStateToProps(state) {
  return {
    searchResult: (state.song.searchResult && state.song.searchResult.songs ) ? state.song.searchResult.songs : [],
    paginationData:  (state.song.searchResult && state.song.searchResult.pagination) ? state.song.searchResult.pagination : '',
    genres: state.genre.genresWithCount,
    top20Albums: state.song.top20Albums,
    versions: state.version.versions
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getSearchResultSongs: (post) => dispatch(songActions.getSearchResultSongs(post)),
    getGenresWithCount: () => dispatch(genreActions.getGenresWithCount()),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    getVersions: () => dispatch(versionAction.getVersions())
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultMp3);