import * as types from './type';

const initialState = {
  dropboxItems: '',
  dropboxMode: true,
  dropboxSongs: ''
};



export default function dropboxReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.ADD_OR_REMOVE_TO_DROPBOX: {
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          dropboxSongs: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    case types.GET_DROPBOX_SONGS: {
      if (action.payload && action.payload.data) {
        
        return {
          ...state,
          dropboxSongs: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    
    case types.REMOVE_ALL: {
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          dropboxSongs: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.DOWNLOAD_ALL: {
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          dropboxSongs: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.DROPBOX_MODE_ENABLED: {
      if (action.payload) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          dropboxMode: action.payload
        }
      } else {
        return {
          ...state
        }
      }
    }

    
    case types.ADD_SONG_ENTITY_TO_DROPBOX: {
      if (action.payload) {
        return {
          ...state,
          dropboxSongs: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    

    default: {
      return {
        ...state
      };
    }
    
  }

}