import React, { useState, useEffect } from "react";
import Search from "../search/search";
import ProfileImage from '../profileImage/profileImage';
import ActiveDownloadBtn from '../activeDownloadBtn/activeDownloadBtn';
import DropboxItemsModal from '../../elements/mp3Modal/dropboxItemsModal';
import config from '../../config/config';
import {connect} from 'react-redux';
import * as dropboxAction from '../../store/dropbox/actions';
import * as commonAction from '../../store/common/actions';
import * as downloadActions from '../../store/download/actions';


const  MiddleHeader = ({ getDropboxSongs, dropboxSongs, customerProfile, uploadFilesOnDropBox, setLeftMenuDisplay, isLeftmenuExpand, getCustomerDownloads }) => {

  const [ showSelectWindow, setShowSelectWindow ] = useState(false);
  const [ leftExpand, setLeftExpand ] =  useState(isLeftmenuExpand);


  useEffect(() => { 
    getCustomerDownloads();
  },[]);
  
    const displayDropboxDownloads = () => {
        setShowSelectWindow(!showSelectWindow);
    }


    const toggleLeftmenu = () => {              
        setLeftMenuDisplay(!leftExpand);
        setLeftExpand(!leftExpand);
    }


    return (
      <div className="top-header">
        <div className="burger-menu">
					<span className="cp" onClick={() => toggleLeftmenu()}>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M5 7.99902H19.0004M5 11.999H19.0004M5 15.999H19.0004" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path>
						</svg>
					</span>
				</div>
        {/* <script type="text/javascript" src="https://www.dropbox.com/static/api/2/dropins.js" id="dropboxjs" data-app-key="9xu23uv21md3xav"></script> */}
        
        <div>
			<ActiveDownloadBtn displayDropboxDownloads={displayDropboxDownloads}  />
			<Search />
			<ProfileImage />
        </div>
        { showSelectWindow  ? <DropboxItemsModal showState={showSelectWindow} ShowFunction={() => setShowSelectWindow(!showSelectWindow)}  /> : '' }
			</div>
    );
  
}


function mapStateToProps(state) {
    return {
      dropboxSongs: state.dropbox.dropboxSongs,
      customerProfile: state.customer.customerProfile,
      isLeftmenuExpand: state.common.isLeftmenuExpand
    };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getDropboxSongs: () => dispatch(dropboxAction.getDropboxSongs()),
    uploadFilesOnDropBox: () => dispatch(dropboxAction.uploadFilesOnDropBox()),
    setLeftMenuDisplay: (data) => dispatch(commonAction.setLeftMenuDisplay(data)),
    getCustomerDownloads: () => dispatch(downloadActions.getCustomerDownloads()),
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(MiddleHeader);
