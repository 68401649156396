import * as types from './type';

const initialState = {
    audioInstance: ''
};

export default function playerReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_PLAYER_INSTANCE:{
            
            if (action.payload && action.payload) {
                return {
                    audioInstance:  action.payload
                }
            }
        }
        case types.GET_PLAYER_INSTANCE: {
            return {
                audioInstance: state.audioInstance
            }
        }
        default: {
            return {
              ...state
            };
          }
    }
}