import * as types from './type';


export function addSongVersionToPlayList(song) {
  
    const songVersion = {
        name: song.name,
        writer: song.writer,
        img: song.img,
        src: song.src,
        id: song.id,
        autoPlay: true,
        version: song.version
      // name: song.name,
      // musicSrc: song.src,
      // cover: 'http://res.cloudinary.com/alick/image/upload/v1502689731/Despacito_uvolhp.jpg',
      // singer: song.writer,
      // songId: song.songId,
      // versionId: song.songVersion,
      // songInfo: song.songInfo,
      // singer: song.writer,
    }
    return {
      type: types.ADD_SONG_TO_PLAYLIST,
      payload: songVersion,
    };
};

export function setPlayerState(playerState){
  return {
    type: types.SET_PLAYER_STATE,
    payload: playerState
  }
}



export function getPlayList() {
    return {
      type: types.GET_CUSTOMER_PLAYLIST
    };
};

export function setPlayListIndex(index){
  return {
    type: types.SET_PLAYLIST_INDEX,
    payload: index
  }
}


