import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import RightSide from "../../components/rightSide/rightSide";
import SongTrack from '../../components/songTrack/songTrack';

import MusicDashboard from '../../layouts/musicDashboard';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import * as actions from '../../store/curated/actions';
import SongTrackLoader from "../../components/loader/songTrackLoader";



const  CuratedSpotlightDetail = ({  getCuratedSongs, curatedSongs, displayLoading, hideLoading }) => {

    let match = useParams("/mp3/spotlight/detail/:id");
    const navigate = useNavigate();

    const [ page, setPage ] = useState(1);
    const [dataProcessing, setDataProcessing] = useState();
   
    

    useEffect(() => { 
		loadData()
    },[match.id])
    

	const loadData = async() => {
        displayLoading();
		await getCuratedSongs({page: page, curated_list_id: match.id}).then((res) => {
			// console.log('res', curatedSongs)
        });
        hideLoading();
    }
    

	return (
			<MusicDashboard rightContent={<RightSide blocks={{curatedFilter: true }} />}>
			    <div className="content-sec">
                    <div className="top-heading">
                        <div className="back-arrow-head">
                            <Link to={`/mp3/spotlight/home`}>
                                <span>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </Link>
                            <h2>Curated Spotlights</h2>
                        </div>
                        <Link to={`/mp3/spotlight/home`} className="text-2">See all</Link>
                    </div>
                    <div className="play-list-sec">
                        {
                            curatedSongs? 
                                <div className="arrow-heading">
                                    {
                                        curatedSongs.previous_curated_list ? 
                                        <Link to={`/mp3/spotlight/detail/${curatedSongs.previous_curated_list}`}>
                                            <span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17 12L7 12" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M9.5 9.5L7 12L9.5 14.5" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M11.9997 22.8337C6.01659 22.8337 1.16634 17.9834 1.16634 12.0003C1.16634 6.01724 6.01659 1.16699 11.9997 1.16699C17.9828 1.16699 22.833 6.01724 22.833 12.0003C22.833 17.9834 17.9828 22.8337 11.9997 22.8337Z" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </span>
                                        </Link> :
                                        <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17 12L7 12" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M9.5 9.5L7 12L9.5 14.5" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11.9997 22.8337C6.01659 22.8337 1.16634 17.9834 1.16634 12.0003C1.16634 6.01724 6.01659 1.16699 11.9997 1.16699C17.9828 1.16699 22.833 6.01724 22.833 12.0003C22.833 17.9834 17.9828 22.8337 11.9997 22.8337Z" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    }
                                    <h3><span dangerouslySetInnerHTML={{__html: curatedSongs.title}}></span> {curatedSongs.created_at}</h3>
                                    {
                                        curatedSongs.next_curated_list ? 
                                        <Link to={`/mp3/spotlight/detail/${curatedSongs.next_curated_list}`}>
                                            <span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7 12H17" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 9.5L17 12L14.5 14.5" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12.0003 22.8337C17.9834 22.8337 22.8337 17.9834 22.8337 12.0003C22.8337 6.01724 17.9834 1.16699 12.0003 1.16699C6.01724 1.16699 1.16699 6.01724 1.16699 12.0003C1.16699 17.9834 6.01724 22.8337 12.0003 22.8337Z" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </span>
                                        </Link>
                                        :
                                        <span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 12H17" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M14.5 9.5L17 12L14.5 14.5" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12.0003 22.8337C17.9834 22.8337 22.8337 17.9834 22.8337 12.0003C22.8337 6.01724 17.9834 1.16699 12.0003 1.16699C6.01724 1.16699 1.16699 6.01724 1.16699 12.0003C1.16699 17.9834 6.01724 22.8337 12.0003 22.8337Z" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span> 
                                    }
                                </div>
                                : <SongTrackLoader itemCount={20} />
                            }
                        <div className="inner-block">
                        {curatedSongs && curatedSongs.songs ? <SongTrack songs={curatedSongs.songs} /> : ''}
                        </div>
                    </div>
                </div>
            </MusicDashboard>
        );
}

function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        curatedSongs: state.curated.curatedSongs,
        curatedAlbums: state.curated.curatedAlbums
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
        getCuratedSongs: (data) => dispatch(actions.getCuratedSongs(data)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(CuratedSpotlightDetail);
