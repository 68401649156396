import React, { useState, useEffect } from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import SongTrack from '../../components/songTrack/songTrack';
import { Link, useNavigate } from "react-router-dom";
import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {connect} from 'react-redux';
import * as actions from '../../store/playlist/actions';

const RecentPlayed = ({ getPlayList, playList, displayLoading, hideLoading, userProfile}) => {
    const navigate = useNavigate();

    useEffect(() => { 
        if(userProfile.is_premium_member && userProfile.is_premium_member == 1){
            displayLoading();
            getPlayList();
            hideLoading();
        } else {
            navigate('/my-library/favourite')
        }
    },[])
    // console.log(playList)
	
		return (
            <MusicDashboard accountPages={true}>
                <div className="content-sec">
                    <div className="play-list-sec">
                        <div className="top-heading">
                            <h2>My Library</h2>
                        </div>
                       
                       <div className="inner-block">
                            <div className="top-list">
                                <ul>
                                    <li><Link to={`/my-library/favourite`}>Favourites</Link></li>
                                    <li className='active'><span>Recently Played</span></li>
                                    <li><Link to={`/my-library/download-rescue`}>Downloaded Songs</Link></li>
                                </ul>
                                <div className="top-heading">
                                    <h2>Recently Played</h2>
                                </div>
                                { playList && playList.length> 0 ? <SongTrack songs={playList} /> : <div className="alert alert-danger">No Songs found in your current playlist</div> }
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: '35px'}}>
                        <CuratedSpotlightCarousel title={'Suggested for you'}/>
                    </div>
                </div>
            </MusicDashboard>
		)
	
}



function mapStateToProps(state) {
    return {
        playList: state.playlist.playList,
        userProfile: state.customer.customerProfile,
    };
}
    
function mapDispatchToProps(dispatch) {
    return {
        getPlayList: () => dispatch(actions.getPlayList()),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentPlayed);