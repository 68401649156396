import React, { useState, useEffect } from "react";

import {connect} from 'react-redux';
import * as actions from '../../store/customer/actions';

import { useNavigate, Link } from "react-router-dom";


const  ProfileImage = ({ loggingIn, customerData, getCustomerProfile }) => {

    const navigate = useNavigate();
    
    useEffect(() => { 
		if(loggingIn !== undefined && loggingIn === false){
            // navigate('/sign-in');
            window.location.href = '/sign-in';
		} 
    },[])
    
    useEffect(() => { 
        if(!customerData.customerProfile){
            getCustomerProfile({token: localStorage.user}) 
        }
    },[])

    useEffect(() => { 
        if(customerData.customerProfile && customerData.customerProfile.is_active != 1){
            navigate('/logout')
        }
    },[customerData.customerProfile]);
    
    if(customerData && customerData.customerProfile){
        return (
            <div className="profile-name">
                <Link to={`/profile/update`}>
                {
                    customerData.customerProfile.profile_picture ? 
                        <img src={customerData.customerProfile.profile_picture} alt={customerData.customerProfile.dj_name ? customerData.customerProfile.dj_name : ''} title={customerData.customerProfile.dj_name ? customerData.customerProfile.dj_name : ''}/> 
                    : 
                    <div className="circle">
                        <p className="circle-inner">{customerData.customerProfile.dj_name ? Array.from(customerData.customerProfile.dj_name)[0] : Array.from(customerData.customerProfile.firstname)[0]}</p>
                    </div>
                }
                
                <span className="text-5">{customerData.customerProfile.dj_name}</span>
                </Link>
            </div>

        );
    } else {
        return null
    }
  
}


function mapStateToProps(state) {
    return {
        loggingIn: state.authentication.loggedIn,
        customerData: state.customer,
    };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getCustomerProfile: (data) => dispatch(actions.getCustomerProfile(data))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImage);
