import * as types from './type';

import { Trending }  from  '../../services/trending';

export function getLatestTrendingSong() {
    return {
      type: types.GET_CURRENT_TRENDING_SONGS,
      payload: Trending.getLatestTrendingSong(),
    };
};

export function getLatestTrendingTrackAlbums(data) {
  return {
    type: types.GET_TRENDING_TRACK_ALBUMS,
    payload: Trending.getLatestTrendingTrackAlbums(data),
  };
};

export function getTrendingTrackSongs(data) {
  return {
    type: types.GET_TRENDING_TRACK_SONGS,
    payload: Trending.getTrendingTrackSongs(data),
  };
};