import React, {useEffect, useState} from "react";
import { showLoading, hideLoading } from 'react-redux-loading-bar';


import { useLocation, useParams, useNavigate } from "react-router-dom";
import MusicDashboard from "../../layouts/musicDashboard";
import AccountNav from '../../elements/accountElements/accountNav'; 

import { connect } from 'react-redux';
import * as subscriptionAction from '../../store/subscription/actions'

import { CanAddApplePay } from './canAddApplePay';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe("pk_test_51H0KA8IY2jIY0hCM9nSxMhp2YlTAcGtZ2HGyPJtuAumWNDgiK8RSJpzqh3Gj1czhAbGcpV0eYhEIgT9lIUT37ItV00NJv7OCYC");

const MobileWallet = ({ displayLoading, hideLoading, getServices, plans, getCustomerAgreement, agreement, coupon, subscriptionDetail, processApplePayment }) => {

    const navigate = useNavigate();
    let match = useParams("/subscription/in-app-payment/:plan_id");
    const location = useLocation();

    const [ planId, setPlanId ] = useState();
    const [selectedPlan, setSelectedPlan] = useState();
    const [ subscriptionData, setSubscriptionData ] = useState({
        payment_method: 'Mobile Walltet',
        service_id: ''
    });

    useEffect(() => { 
		// load Page Data
		loadPageData();
    },[]);
    
    useEffect(() => { 
        displayLoading();
		if(match.plan_id){
            setPlanId(match.plan_id)
        }else{
            navigate("/subscription/plans");
        }
        hideLoading();
    },[location]);

    if(agreement && agreement.profile_status == 'CREATED'){
        navigate('/subscription/plans');
    }

    const loadPageData = async() => {
        displayLoading();
        if(plans){
            setSelectedPlan(plans.find(  service  => service.id ===  match.plan_id.toString()));
        } else {
            await getServices({subscription_type_id: 1}).then((res) => {
                if(res && res.payload && res.payload.data && res.payload.status == 'true'){
                    setSelectedPlan(res.payload.data.find(  service  => service.id ===  match.plan_id.toString()));
                }
            });
        }
        if(!agreement){
            await getCustomerAgreement();
        }
        
        hideLoading();
    }

    const processPayment = (event) => {
        processApplePayment({applePay: event, coupon: coupon, subscription: subscriptionDetail}).then( res => {
            if(res && res.payload && res.payload.data && res.payload.data.status && res.payload.data.status == true){
                event.complete('success');
                // navigate(`/subscription/success/${res.payload.data.agreement.stripe_subscription_id}`);
                navigate('/subscription/success/complete')
            } else {
                event.complete('fail');
               navigate(`/subscription/failed`);
            }
            
        });
    }

    return (
        <MusicDashboard middleSectionCssClass={"account-info-sec"}>
          <div className="content-sec">
                <div className="account-block">
                    <div className="top-heading">
                        <h2>Account Setting</h2>
                    </div>
                    <AccountNav/> 
                    <div className="account-inner-block">
                        <div className="subscription-sec">
                        <div className="top">
								<h5>Hi, James! One more step to complete your order</h5>
								<h1>Confirm Payment</h1>
								<p className="text-3">Please review your membership details and billing information to complete your order.</p>
							</div>
							<div className="payment-block mobile-payment">
                                {
                                    selectedPlan ?
                                        <div className="right">
                                            <div className="your-plan box">
                                                <div className="top">
                                                    <h2>Your plan</h2>
                                                    
                                                </div>
                                                <div className="body-text">
                                                    <span className="text-2">PLan:</span>
                                                    <span className="text-3">{selectedPlan.title} 
                                                    {/* ${selectedPlan && coupon && coupon.discount_amount && parseInt(coupon.discount_amount) > 0  ?( parseInt(coupon.discount_amount) +  parseInt(selectedPlan.price)).toFixed(2) :  parseInt(selectedPlan.price).toFixed(2) }  */}
                                                    </span>
                                                        
                                                    <br />
                                                    
                                                    {  coupon && coupon.discount_amount && parseInt(coupon.discount_amount) > 0  ? <span className="text-2">Discount:</span> : ''}
                                                    {  coupon && coupon.discount_amount && parseInt(coupon.discount_amount) > 0  ? <span className="text-3">-${parseInt(coupon.discount_amount).toFixed(2)}</span> : ''}
                                                    
                                                    <span className="text-2">Total:</span>
                                                    <span className="text-3">${ parseInt(selectedPlan.price).toFixed(2) }</span>
                                                    
                                                </div>
                                                {
                                        subscriptionDetail ? 
                                            <Elements stripe={stripePromise}>
                                                <CanAddApplePay subscriptionData={subscriptionDetail} onConfirmPayment={processPayment}/>
                                            </Elements>
                                        : '' 
                                    }
                                            </div>
                                        </div>
                                    : ''
                                }
							</div>
                        </div>
                    </div>
                </div>
            </div>
            
        </MusicDashboard>
    )
}

function mapStateToProps(state) {
    return {
        agreement: state.subscription.agreement,
        plans: state.subscription.plans,
        coupon: state.coupon.coupon,
        subscriptionDetail: state.subscription.subscriptionDetail
    };
}

function mapDispatchToProps(dispatch) {
  	return {
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        getServices: (post) => dispatch(subscriptionAction.getServices(post)),
        getCustomerAgreement: () => dispatch(subscriptionAction.getCustomerAgreement()),
        processApplePayment: (post) => dispatch(subscriptionAction.processApplePayment(post))
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileWallet);