import React, { useState, useEffect } from "react";

import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import MusicDashboard from '../../layouts/musicDashboard';
import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner';
import config from '../../config/config';
import  API_BASE_URL  from '../../config/config';
import { FilePond, File, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import { Link } from 'react-router-dom';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import * as actions from '../../store/customer/actions';

const  PlaylistIndex = ({ displayLoading, hideLoading,  getCustomerPlayLists, playlistAlbums, authentication, getCustomerPlayListCoverImage, addPlaylistAlbum, userProfile }) => {

    const [validated, setValidated] = useState(false);
    const [ errors, setErrors ] = useState({});
    const [ submit, setSubmit ] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        cover_image: ''
    });
    const [files, setFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    const uploadURL = API_BASE_URL.apiUrl+"/customer/upload-customer-album-image.json?token="+authentication.user+"&store_id=1&lang_code=en";

    
    useEffect(() => { 
        displayLoading();
        getCustomerPlayLists();
        hideLoading();
    },[]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });    
    }

    const handleSubmit = (event) => { 
        event.preventDefault();
        event.stopPropagation();
        setOpen(!open);
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            displayLoading();
            setSubmit(true);
            addPlaylistAlbum(formData).then(res => {
                if(res && res.payload && res.payload.data){
                    setSubmit(false);
                    getCustomerPlayLists();
                    setOpen(!open);
                    setOpen(!open);
                }
            }); 
            hideLoading();
        }
    }

    const findFormErrors = () => {
        const { title, cover_image, song_entity_id } = formData
        const newErrors = {}

        
        // password errors
        if ( !cover_image || cover_image === '' ) newErrors.cover_image = 'Please upload cover image'
        if ( !title || title === '' ) newErrors.title = 'Playlist Name cannot be blank!'
        return newErrors
    }

    const getUploadedFile = (file) => {
        getCustomerPlayListCoverImage().then(res => {
            if(res && res.payload && res.payload.data){
                setFormData({
                    ...formData,
                    cover_image : res.payload.data.cover_image
                });  
            }
        });
    }
    

    return (
        <MusicDashboard  accountPages={true} >
       	<div className="content-sec">
			<div className="my-playlist-sec play-list-sec">
				<div className="top-heading">
					<h2>My Library</h2>
                    <Popup trigger=
                        {<button className="primary-btn create-btn">Create new</button>}
                        modal  open={open} onClose={closeModal}>
                        {
                            close => (
                                <div className='modal'>
                                    <div className='content'>
                                            <div className="inner-block">
                                                <div className="top-section">
                                                    <h1>Create New Folder</h1>
                                                    <span className="close" onClick={() => close()}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 16.5 12 12 7.5 7.5m9 9L12 12l4.5-4.5" stroke="#919191" strokeWidth="2" strokeMiterlimit="10"></path></svg>
                                                    </span>
                                                </div>
                                                <div className="form-content">
                                                    <Form  validated={validated} onSubmit={handleSubmit}>
                                                        <FilePond
                                                            files={files}
                                                            // onupdatefiles={setFiles}
                                                            allowMultiple={true}
                                                            maxFiles={1}
                                                            server={uploadURL}
                                                            name="UploadFilesForm[files]" 
                                                            labelIdle='Add Cover Image'
                                                            onprocessfile={(error, file) => {
                                                                // Set currently active file objects to this.state
                                                                getUploadedFile(file); 
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                                { errors.cover_image }
                                                        </Form.Control.Feedback>
                                                        <Form.Group className="form-group" controlId="formBasicFullname" >
                                                            <Form.Label className="text-3">Create folders to organize your playlists by genre, event, or more.</Form.Label>
                                                            <Form.Control type="text" placeholder="Playlist Name" name="title"  onChange={handleChange} isInvalid={ !!errors.full_name } />
                                                            <Form.Control.Feedback type="invalid">
                                                                { errors.title }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <p className="text-3"></p>
                                                        
                                                        <div className="btn-sec">
                                                            { submit ? <button className="primary-btn"><Spinner animation="border" variant="dark" size="sm" /></button> :<button className="primary-btn" type="submit">Create New Folder</button>}
                                                            <button className="secondary-btn" onClick={() => close()}>Cancel</button>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div> 
                                    </div>
                                    
                                </div>
                            )
                        }
                    </Popup>
                </div>
                
				<div className="playlist-block inner-block">
                    <div className="top-tab ">
                        <ul className="tab-sec">
                            <li><Link to={`/my-library/favourite`}><span>Favourites</span></Link></li>
                            <li className="divide active"><span>Playlists</span></li>
                            <li><Link to={`/my-library/download-rescue`}><span>Downloaded Songs</span></Link></li>
                        </ul>
                    </div>
                {
                    playlistAlbums && playlistAlbums.length > 0 
                    ? 
                        <div className="playlist-inner-block">
                        {
                            playlistAlbums.map((album, index) => {
                                return <div className="box" key={`playlistalbumsIndex${index}`}>
                                    <Link to={`/playlists/detail/${album.playlist_album_id}`}><img src={`${config.downloadSelfUrl}customer-album-images/${album.cover_image}`} alt={album.title} /></Link>
                                    <Link to={`/playlists/detail/${album.playlist_album_id}`}><p>{album.title}</p></Link>
							</div>
                            })
                        }
                        </div>
                    : ''
                }
                </div>
			</div>
			<CuratedSpotlightCarousel title={'Suggested for you'}/>
		</div>
		
    </MusicDashboard>
    );
  
}


function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    playlistAlbums: state.customer.playlistAlbums,
    userProfile: state.customer.customerProfile,
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    addPlaylistAlbum: (post) => dispatch(actions.addPlaylistAlbum(post)),
    getCustomerPlayLists: () => dispatch(actions.getCustomerPlayLists()),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    getCustomerPlayListCoverImage: () => dispatch(actions.getCustomerPlayListCoverImage()),
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistIndex);
