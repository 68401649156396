import * as types from './type';

const initialState = { 
    currentTrending: '' ,
    albums: '',
    trendingTrackSongs: ''
}


export default function trendingReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_CURRENT_TRENDING_SONGS:
            if(action.payload && action.payload.data && action.payload.data.songs){
                return {
                    ...state,
                    currentTrending: action.payload.data.songs
                };
            } 
        case types.GET_TRENDING_TRACK_ALBUMS:
        // console.log(action.payload.data)
            if(action.payload && action.payload.data){
                return {
                    ...state,
                    albums: action.payload.data
                };
            } 
        case types.GET_TRENDING_TRACK_SONGS:
            // console.log(action.payload.data)
                if(action.payload && action.payload.data){
                    return {
                        ...state,
                        trendingTrackSongs: action.payload.data
                    };
                } 
            
        default:
            return state
    }
}