import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import RightSide from "../../components/rightSide/rightSide";
import Pagination from "react-js-pagination";

import MusicDashboard from '../../layouts/musicDashboard';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import * as actions from '../../store/curated/actions';



const  CuratedSpotlightGenre = ({ loggingIn, getGenreCuratedAlbums, curatedGenres, displayLoading, hideLoading }) => {

    let match = useParams("/mp3/spotlight/genre/:id");
    const navigate = useNavigate();
    const [ page, setPage ] = useState(1);

   useEffect(() => { 
		loadData();
    },[ match.id])
    

	const loadData = async() => {
        displayLoading();
        await getGenreCuratedAlbums({ curated_genres_id: match.id, page: page, pageLimit: 36 });
        hideLoading();
    }
    
    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        displayLoading();
        await getGenreCuratedAlbums({curated_genres_id: match.id, page: pageNumber, pageLimit: 36});
        hideLoading();
	}

	return (
			<MusicDashboard rightContent={<RightSide blocks={{curatedFilter: true }} />}>
			    <div className="content-sec">
                    <div className="most-download">
                        <CuratedSpotlightCarousel />
                    </div>
                    <div className="latest-spotlights">
                        <div className="top-heading">
                            <h2>{curatedGenres && curatedGenres.current_genre ? curatedGenres.current_genre.title : '' }</h2>
                        </div>
                        <div className="latest-spot-block">
                        {
                            curatedGenres && curatedGenres.curatedlist && curatedGenres.curatedlist.length > 0 ?
                                curatedGenres.curatedlist.map((curatedSpotlight, index) => {
                                        return <Link className="box" key={`newreleasespotlight${curatedSpotlight}`} to={`/mp3/spotlight/detail/${curatedSpotlight.curated_list_id}`}>
                                                    <img src={curatedSpotlight.image ? curatedSpotlight.image : ''} />
                                                    <div className="latest-info"> <h5>{curatedSpotlight.title}</h5></div>            
                                            </Link>
                                })
                            : ''
                        }
                        </div>
                    </div>
                    <div className="pagingnation-sec">
                        {
                        curatedGenres && curatedGenres.pagination ? 
                            <Pagination
                                activePage={curatedGenres.pagination.page}
                                itemsCountPerPage={curatedGenres.pagination.perPageLimit}
                                totalItemsCount={curatedGenres.pagination.totalCount}
                                // pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                        /> : ''
                        }
                    </div>
                </div>
            </MusicDashboard>
        );
}

function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        curatedGenres: state.curated.curatedGenres
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
        getGenreCuratedAlbums: (data) => dispatch(actions.getGenreCuratedAlbums(data)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(CuratedSpotlightGenre);
