import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import {connect} from 'react-redux';
import * as actions from '../../store/song/actions';

const FavButton = ({ player, playList, addSongToFavourite, getCustomerFavouriteSongs, favouriteSongs, isPlaying, themeType }) => {

    const [ customerFavSongs, setCustomerFavSongs ] = useState(favouriteSongs);
    const [ isFav, setIsFav ] = useState(false);
    const [ currentSongId, setCurrentSongId ] = useState();
    const [ downloadInProcess, setDownloadInProcess ] = useState(false);

    useEffect(() => { 
        setCustomerFavSongs(favouriteSongs);
    },[favouriteSongs])
       
    useEffect(() => { 
        if(player && player.current && player.current.audio && player.current.audio.current){
            const matchedSong = getPlaylistItem(player.current.audio.current.src);
            if(matchedSong){
                setCurrentSongId(matchedSong.img)
            }
            if(matchedSong && customerFavSongs && customerFavSongs && customerFavSongs.length > 0 && ((customerFavSongs.indexOf(matchedSong.img.toString()) > -1))){
                setIsFav(true)
            } else {
                setIsFav(false)
            }
        }
   	},[isPlaying])

    const getPlaylistItem = (songSrc) => {
        var hasMatch = false;
		for (var index = 0; index < playList.length; ++index) {
            var songs = playList[index];
            if(songs.src == songSrc){
                hasMatch = songs;
                break;
            }
        }
        
		return hasMatch;
    }

    const addRemoveFromFav = async() => {
        setDownloadInProcess(true);
        await addSongToFavourite(currentSongId);
        await  getCustomerFavouriteSongs().then(res => {
            
            let hasMatch = false;
            
            if(res && res.payload && res.payload.data && res.payload.data.songs && res.payload.data.songs.length > 0 ){
                for (var index = 0; index < res.payload.data.songs.length; ++index) {
                    var song = res.payload.data.songs[index];
                    if(song.entity_id == currentSongId){
                        hasMatch = true;
                        break;
                    }
                }
                if(hasMatch){
                    setIsFav(true);
                } else {
                    setIsFav(false);
                }
            }
        });
        setDownloadInProcess(false)
    }

    if(downloadInProcess){
        return <Spinner animation="border" variant="danger" size="sm" />
    } else {
        return (
                <span onClick={() => addRemoveFromFav()} >
                    <svg width="16" height="14" viewBox="0 0 16 14" fill={ isFav ? '#EB4E27' : 'none'} xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.00003 13.2282L1.69146 7.51389C-1.73711 4.08532 3.30289 -2.49754 8.00003 2.82818C12.6972 -2.49754 17.7143 4.10818 14.3086 7.51389L8.00003 13.2282Z" stroke={themeType == 'dark-theme' ? "#D7D7D7": '#828282'} strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span> 
        )   
    }
   
    
   

}
function mapStateToProps(state) {
    return {
        player: state.player.audioInstance,
        playList: state.playlist.playList,
        favouriteSongs: state.song.favouriteSongs,
        isPlaying: state.isplaying.isPlaying,
        themeType: state.theme.theme,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        addSongToFavourite: (songId) => dispatch(actions.addSongToFavourite(songId)),
        getCustomerFavouriteSongs: () => dispatch(actions.getCustomerFavouriteSongs()),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FavButton);
  