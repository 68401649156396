import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';


const PlaylistButton = ({ song }) => {
        return (
            <span>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 15.5L9 15.5977" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4 10.5H16" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4 5.5H16" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.4258 12.5565C12.387 12.5259 12.3405 12.5069 12.2914 12.5016C12.2423 12.4962 12.1927 12.5048 12.1482 12.5263C12.1038 12.5477 12.0663 12.5813 12.0401 12.6231C12.0139 12.6648 12 12.7131 12 12.7624V18.238C12.0001 18.2873 12.014 18.3355 12.0403 18.3772C12.0665 18.4189 12.104 18.4524 12.1484 18.4738C12.1928 18.4953 12.2424 18.5038 12.2915 18.4984C12.3405 18.4931 12.3871 18.474 12.4258 18.4435L15.9 15.7061C15.9312 15.6816 15.9564 15.6503 15.9737 15.6146C15.991 15.579 16 15.5399 16 15.5002C16 15.4606 15.991 15.4215 15.9737 15.3858C15.9564 15.3501 15.9312 15.3188 15.9 15.2943L12.4258 12.5565Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>
        )
    
    
    

}
function mapStateToProps(state) {
    return {
        
    };
}
    
function mapDispatchToProps(dispatch) {
    return {
        
    };
}
    
export default connect(mapStateToProps, mapDispatchToProps)(PlaylistButton);
              