import React, { useState, useEffect } from "react";

import {connect} from 'react-redux';
import * as action from '../../store/activeDownloadBtn/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import * as customerAction from '../../store/customer/actions';
import * as dropboxAction from '../../store/dropbox/actions';

const  ActiveDownloadBtn = ({ activeBtn, setActiveDownloadBtn, dropbox, displayDropboxDownloads, updateProfile, getDropboxSongs, addRemoveToDropBox }) => {

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => { 
		if(location.hash){
			let accessTokenHash = location.hash.split('access_token=');
			const dropBoxToken = {
				dropbox_token: accessTokenHash[1]
			}
			updateProfile(dropBoxToken).then(res=>{
				navigate('/');
			})
		}
	},[location]);

	const makeActiveDownloadBtn = (activeBtn) => {
		setActiveDownloadBtn(activeBtn);
		if(activeBtn == 'dropbox'){
			getDropboxSongs();
		}
	}

	// const showDownloadPopUp = () => {
	// 	// setActiveDownloadBtn('dropbox');
	// 	// show pop up
	// 	displayDropboxDownloads();
	// }
	// console.log(dropbox)

	return (
	<div className="dropbox-sec">
		{
			activeBtn == 'dropbox' && dropbox && dropbox.items && dropbox.items.length > 0 ?
			<div className="dropbox-notification" onClick={() => displayDropboxDownloads()}>
				<svg width="24" height="25" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1.5 6.16667L5.66667 8.825L9.83333 6.16667L5.66667 3.5L1.5 6.16667ZM9.83333 6.16667L14 8.825L18.1667 6.16667L14 3.5L9.83333 6.16667ZM1.5 11.4583L5.66667 14.1167L9.83333 11.4583L5.66667 8.79167L1.5 11.4583ZM14 8.79167L9.83333 11.4583L14 14.1167L18.1667 11.4583L14 8.79167ZM5.69167 15L9.86667 17.6667L14.0333 15L9.86667 12.3417L5.69167 15Z" fill="#fff" />
					</svg>
					{dropbox.totalDropbox ? <span> {dropbox.totalDropbox }</span> : ''}
			</div>
			: ''
		}
		<div className="theme-mode">
			<div className="inner-mode">
				<div className={activeBtn == 'dropbox' ? "mode" : 'mode active'}>
					<svg onClick={() => makeActiveDownloadBtn('download')} width="24" height="25" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.9994 5.42871H12.1423C12.2939 5.42871 12.4392 5.48891 12.5464 5.59608C12.6535 5.70324 12.7137 5.84859 12.7137 6.00014V14.5716C12.7137 14.7231 12.6535 14.8685 12.5464 14.9756C12.4392 15.0828 12.2939 15.143 12.1423 15.143H1.85658C1.70503 15.143 1.55969 15.0828 1.45252 14.9756C1.34536 14.8685 1.28516 14.7231 1.28516 14.5716V6.00014C1.28516 5.84859 1.34536 5.70324 1.45252 5.59608C1.55969 5.48891 1.70503 5.42871 1.85658 5.42871H2.99944" stroke={activeBtn == 'download' ? "#EB4E27": "white"} strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M6.99902 0.857422V10.0003" stroke={activeBtn == 'download' ? "#EB4E27": "white"} strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M4.71387 7.71484L6.99958 10.0006L9.2853 7.71484" stroke={activeBtn == 'download' ? "#EB4E27": "white"} strokeLinecap="round" strokeLinejoin="round"/>
			
					</svg>
				</div>
				<div className={activeBtn == 'dropbox' ? "mode active" : 'mode'}>
					<svg onClick={() => makeActiveDownloadBtn('dropbox')} width="24" height="25" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1.5 6.16667L5.66667 8.825L9.83333 6.16667L5.66667 3.5L1.5 6.16667ZM9.83333 6.16667L14 8.825L18.1667 6.16667L14 3.5L9.83333 6.16667ZM1.5 11.4583L5.66667 14.1167L9.83333 11.4583L5.66667 8.79167L1.5 11.4583ZM14 8.79167L9.83333 11.4583L14 14.1167L18.1667 11.4583L14 8.79167ZM5.69167 15L9.86667 17.6667L14.0333 15L9.86667 12.3417L5.69167 15Z" fill="#fff" />
					</svg>
				</div>
			</div>
		</div>
	</div>
	);
    
  
}


function mapStateToProps(state) {
    return {
        activeBtn: state.activeDownloadBtn.activeDownloadBtn,
		dropboxItems: state.dropbox.dropboxItems.dropbox,
		dropbox: state.dropbox.dropboxSongs.dropbox
    };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    setActiveDownloadBtn:(data) => dispatch(action.setActiveDownloadBtn(data)),
	updateProfile:(data) => dispatch(customerAction.updateProfile(data)),
	getDropboxSongs: () => dispatch(dropboxAction.getDropboxSongs()),
	addRemoveToDropBox: (songId, versionId) => dispatch(dropboxAction.addRemoveToDropBox(songId, versionId)),
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(ActiveDownloadBtn);