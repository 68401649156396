import React, { useState, useEffect } from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import Table from 'react-bootstrap/Table';
import Pagination from "react-js-pagination";
import Spinner from 'react-bootstrap/Spinner';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import config from '../../config/config';
import {connect} from 'react-redux';
import * as billingAction from '../../store/billing/actions'

const BillingHistory = ({ billing, getBillingHistory, prepareInvoice, downloadBillingEntry, displayLoading, hideLoading }) => {
    const [  page, setPage ] = useState(1);
    const perPageLimit =  config.itemsCountPerPage;
    const [ order, setOrder ] = useState('history_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const [ activeBadge, setActiveBadge ] = useState('all');
    const [ years, setYears] = useState();
    const [ downloadFormat, setDownloadFormat ] = useState();
    const [processingBundle, setProcessingBundle ] = useState(false);
    const currentYear = new Date().getFullYear();
  
    useEffect(() => { 
      loadPageData()
    },[]);
    
    const loadPageData = async () => {
        displayLoading();
        var yearArray = []
        for (var i=currentYear; i > (currentYear-3); i--) {
            yearArray.push(i);
        }
        setYears(yearArray)
        await getBillingHistory({page: page, order: order, orderDir});
        hideLoading();
    }

    const handlePageChange = async (pageNumber) => {
        displayLoading();
        setPage(pageNumber);
        await getBillingHistory({page: pageNumber, order: order, orderDir, start_date: startDate, end_date: endDate}).then((res) => {});
        hideLoading();
    }
    
    const filterBillingHistory = async() => {
        displayLoading();
        await getBillingHistory({page: 1, order: order, orderDir, start_date: startDate, end_date: endDate}).then((res) => {});
        hideLoading();
    }
  
    const filterBillingHistoryByYear = async(YY) => {
        displayLoading();
        setActiveBadge(YY);
        if(YY === 'all'){
          await getBillingHistory({page: page, order: order, orderDir});
        } else {
          const start_date = {"day":1, "month":1, "year": YY};
          setStartDate(start_date);
          const end_date = {"day":31, "month":12, "year": YY}
          await getBillingHistory({page: 1, order: order, orderDir, start_date: start_date, end_date: end_date}).then((res) => {});
          setEndDate(end_date);
        }
        hideLoading();
    }
  
      const downloadBillingById = async (historyId, format='') => {
            displayLoading();
            await downloadBillingEntry({ history_id: historyId, format:format }).then((res) => {
                if(res.payload.data && res.payload.data.url){
                downloadFile(res.payload.data)
                }
            });
            hideLoading();
      }
  
      const dowloadInvoice = async(format) => {
          setDownloadFormat(format);
          setProcessingBundle(true);
          displayLoading();
          await prepareInvoice({format: format, startDate: startDate, endDate: endDate}).then((res) => {
              if(res.payload.data && res.payload.data.url){
                  downloadFile(res.payload.data)
              }
          });
          setProcessingBundle(false);
          hideLoading();
      }
  
      const downloadFile = (data) => {
        var downloadurl = `${config.uploadUrl}site/download?url=${data.url}&file=${data.file}`;
        // const downloadurl = data.url;
        window.location.assign(downloadurl);
    }
    
    return (
        <MusicDashboard accountPages={true} middleSectionCssClass={"account-info-sec"}>
                <div className="content-sec">
                    <div className="account-block">
                        <div className="top-heading">
                            <h2>Account Setting</h2>
                        </div>
                        <AccountNav/> 
                        <div className="account-inner-block">
                            <div className="billing-history">
                                <div className="title-heading">
                                    <h3>Billing History</h3>
                                </div>
                                {
                                    billing && billing.billing_history && billing.billing_history.length > 0 ?
                                
                                        <div className="billing-years">
                                            <ul className="top-bages">
                                                <li  onClick={() => filterBillingHistoryByYear('all')} className={activeBadge === 'all' ? 'active' : ''}><span >All</span></li>
                                                {
                                                    years && years.length > 0 ? 
                                                        years.map((year, index) => {
                                                        return <li key={`year${year}`} onClick={() => filterBillingHistoryByYear(year)} className={activeBadge === year ? 'active' : ''}><span >{year}</span></li>
                                                        })
                                                    : ''
                                                    }
                                            </ul>
                                        
                                            {/* <ul className="top-bages">
                                                <li className='calendar'>
                                                    <span className="start-picker">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.5 2.5C1.23478 2.5 0.98043 2.60536 0.792893 2.79289C0.605357 2.98043 0.5 3.23478 0.5 3.5V12.5C0.5 12.7652 0.605357 13.0196 0.792893 13.2071C0.98043 13.3946 1.23478 13.5 1.5 13.5H12.5C12.7652 13.5 13.0196 13.3946 13.2071 13.2071C13.3946 13.0196 13.5 12.7652 13.5 12.5V3.5C13.5 3.23478 13.3946 2.98043 13.2071 2.79289C13.0196 2.60536 12.7652 2.5 12.5 2.5H10.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M0.5 6.5H13.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M3.5 0.5V4.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M10.5 0.5V4.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M3.5 2.5H8.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </span>
                                                </li>
                                            </ul> */}
                                        </div>
                                    :
                                    ""
                                }
                                <div className="table-content">
                                    <Table hover responsive>
                                        <thead>
                                            <tr>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Payment Method</th>
                                            <th>Amount</th>
                                            <th>Invoice</th>
                                            </tr>
                                        </thead>
                                        {
                                            billing && billing.billing_history && billing.billing_history.length > 0 ?
                                            <tbody>
                                                {
                                                    billing.billing_history.map((history, index)=>{
                                                        return <tr key={`billing${index}`}>
                                                                <td>{history.payment_date}</td>
                                                                <td>{history.plan_name}</td>
                                                                <td>{history.payment_method}</td>
                                                                <td>{history.amount}</td>
                                                                <td className="text-uppercase">	
                                                                    <span  onClick={()=> downloadBillingById(history.history_id, 'pdf')} className="cp">PDF</span> &nbsp;
                                                                    <span onClick={()=> downloadBillingById(history.history_id, 'excel')} className="cp">Excel</span>
                                                                </td>
                                                        </tr>
                                                    })
                                                }	
                                                
                                            </tbody>
                                            : ''
                                        }
                                        {
                                            billing && billing.length == 0 ?
                                                <tbody><tr><td>You have no billing details.</td></tr></tbody>
                                            :
                                            ''
                                        }
                                    </Table>
                                </div>
                                {
                                    billing && billing.billing_history && billing.billing_history.length > 0 ?
                                        <div className="billing-button">
                                            { processingBundle && downloadFormat == 'pdf' ? <button className="primary-btn" disabled="true"><Spinner animation="border" variant="dark" size="sm" /></button> :  <button className="primary-btn cp" onClick={() => dowloadInvoice('pdf')}>Download Invoices (PDF)</button>}
                                            { processingBundle && downloadFormat == 'excel' ? <button className="primary-btn" disabled="true"><Spinner animation="border" variant="dark" size="sm" /></button> : <button className="secondary-btn cp" onClick={() => dowloadInvoice('excel')}>Download Invoices (Excel)</button> }
                                        </div>
                                    : ''
                                }
                            
                                <div className="pagingnation-sec">
                                {
                                    billing && billing.pagination ? 
                                        <Pagination
                                            activePage={billing.pagination.page}
                                            itemsCountPerPage={billing.pagination.perPageLimit}
                                            totalItemsCount={billing.pagination.totalCount}
                                            // pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                    /> : ''
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MusicDashboard>
    )
}

function mapStateToProps(state) {
    return {
        billing: state.billing.billing
    };
}
      
function mapDispatchToProps(dispatch) {
    return {
        getBillingHistory: (post) => dispatch(billingAction.getBilling(post)),
		prepareInvoice: (post) => dispatch(billingAction.prepareInvoice(post)),
        downloadBillingEntry: (post) => dispatch(billingAction.downloadBillingEntry(post)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BillingHistory);