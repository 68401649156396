import React, {useEffect, useState} from "react";

//custom components
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import Spinner from 'react-bootstrap/Spinner';
import MusicDashboard from "../../layouts/musicDashboard";
import AccountNav from '../../elements/accountElements/accountNav';


import SelectSongModal from '../../elements/mp3Modal/selectSongModal';

import Form from 'react-bootstrap/Form';
import { FilePond, File, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css'



import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';

import { useLocation, useParams, useNavigate } from "react-router-dom";

import config from '../../config/config';

import  API_BASE_URL  from '../../config/config';

import * as genresActions from '../../store/genres/actions';
import * as versionAction from '../../store/version/actions'
import * as fileContainerAction from '../../store/filecontainer/actions';
import * as songActions from '../../store/song/actions';

const AddSong = ({ getVersions, getGenresWithCount, genres, displayLoading, hideLoading, versions, getContainerSongsList, fileLists, createSong, searchSong, userProfile }) => {

    const navigate = useNavigate();

    const [ genresData, setGenresData ] = useState();
    const [ versionData, setVersionData ] = useState();
    const [ selectedSong, setSelectedSong ] = useState();
    const [ selectedVersion, setSelectedVersion ] = useState();
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [ expireDate, setExpireDate ] = useState();
    const [ currentLabel, setCurrentLabel ] = useState();
    const [ btnDisable, setBtnDisable ] = useState(false);
    const [autoCompleteResult, setAutoCompleteResult ] = useState([]);
   
    const [ formSubmited, setForrmSubmited ] = useState(false);
    const [ showSelectWindow, setShowSelectWindow ] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        artist: '',
        year: '',
        song_key: '',
        min_bpm: '',
        max_bpm: '',
        genres: '', 
        search_version_term: '',
        expire_date: ''
    });
    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    const uploadURL = API_BASE_URL.apiUrl+"/upload/songs.json?store_id=1&lang_code=en";
    const [files, setFiles] = useState([]);

	useEffect(() => { 
        // load Page Data
        if(userProfile && userProfile.user_song && userProfile.user_song == 1 ){
            loadPageData();
        } else {
            navigate('/profile/update');
        }
    },[]);
    
  
	const loadPageData = async() => {
    
        displayLoading();
        if(genres){
            setGenresData(genres)
        } else {
            await getGenresWithCount().then(res=> {
                
                if(res && res.payload && res.payload.data && res.payload.data.data && res.payload.data.data.genres){
                    setGenresData(res.payload.data.data.genres)
                }
            });
        }
        if(versions && versions.length > 0){
            setVersionData(versions);
        } else {
            getVersions().then(res=> {
                if(res && res.payload && res.payload.data && res.payload.data){
                    setVersionData(res.payload.data)
                }
            });
        }
		
		hideLoading();
    }
    
    const addNewSong = async(post) => {
        setBtnDisable(true);
        displayLoading()
        await createSong(post).then(res => {
            if(res && res.payload && res.payload.data){
                setVersionData('');
                navigate('/uploads/list')
            }
        });
        hideLoading();
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        }); 
        if(name === 'title'){
            searchMatches(value);
        }
    }

    const searchMatches = (value) => {
        if(value.length > 2){
            searchSong({searchTerm: value, song_sectoin_id: 1}).then(res => {
                if(res && res.payload && res.payload.data && res.payload.data.songs && res.payload.data.songs.length > 0){
                    setAutoCompleteResult(res.payload.data.songs);
                } else {
                    setAutoCompleteResult([])
                }
            })
        } else {
            setAutoCompleteResult([])
        }
    }

    const handleCheckboxChange = event => {
        let newArray = [...selectedGenres, event.target.value];
        if (selectedGenres.includes(event.target.value)) {
           newArray = newArray.filter(genreId => genreId !== event.target.value);
        }
        setSelectedGenres(newArray);
        setFormData({
            ...formData,
            ['genres']: newArray
        }); 
    };

    const findFormErrors = () => {
        const { title, artist, year, song_key, min_bpm, genres } = formData
        const newErrors = {}

        if ( !title || title === '' ) newErrors.title = 'Song title cannot be blank!'
        if ( !artist || artist === '' ) newErrors.artist = 'Artist cannot be blank!'
        if ( !year || year === '' ) newErrors.year = 'Year cannot be blank!'
        if ( !song_key || song_key === '' ) newErrors.song_key = 'Song Key cannot be blank!'
        if(!min_bpm || min_bpm === '') newErrors.min_bpm = 'BPM cannot be blank!'
        if(!genres || genres.length === 0) newErrors.genres = 'Please select genre!'
        // if( !payment_method || payment_method === '')  newErrors.payment_method = 'Select a payment method'
        // if( !terms_condition || terms_condition === '')  newErrors.terms_condition = 'Terms & Condition is require'

        return newErrors
    }

    const handleSubmit = (event) => { 
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setForrmSubmited(true);
        // console.log('formData', formData)
        if ( Object.keys(newErrors).length > 0 ) {
            
            // We got errors!
            setErrors(newErrors)
        } else {
            let postData = formData;
            // postData.versions = versionData;
            postData.expire_date = expireDate;
            addNewSong({songData: postData, versions: versionData});
        }
    }
    
    const changeLabelValue = (version, event) => {
        const { value } = event.target;
        setCurrentLabel(value);
        version.new_label = value;
    }

    const initChangeLabel = (version, newLabel) => {
        var foundIndex = versionData.findIndex(x => x.version_id === version.version_id);
        let newVersionData = versionData;
        newVersionData[foundIndex].new_label = newLabel;
        setVersionData(newVersionData);
        applyChanges(newVersionData);
    }
    
    const applyChanges = (version) => {
        setSelectedVersion(version);
    }

    const selectSong = (version) => {
        setSelectedVersion(version)
        getContainerSongsList();
        setShowSelectWindow(!showSelectWindow);
    }

    /**
     * update Version name of song
     * @param {*} version 
     * @param {*} event 
     */
    const saveLabel = (version, newLabel) => {
        var foundIndex = versionData.findIndex(x => x.version_id === version.version_id);
        let newVersionData = versionData;
        newVersionData[foundIndex].name = currentLabel;
        newVersionData[foundIndex].new_label = "";
        setVersionData(newVersionData);
        applyChanges(version);
    }

    const removeSong = (version) => { 
        var foundIndex = versionData.findIndex(x => x.version_id === version.version_id);
        let newVersionData = versionData;

        newVersionData[foundIndex].song = "";
        newVersionData[foundIndex].new_label = "";
        newVersionData[foundIndex].start_min = "";
        newVersionData[foundIndex].start_sec = "";

        setVersionData(newVersionData);
        if(selectedVersion){
            applyChanges('');
        } else{
            applyChanges(version);
        }
        
    }

    /**
     * update Version Start min of song
     * @param {*} version 
     * @param {*} event 
     */
    const updateVersionStartMin = (version , event) => {
        const { value } = event.target;

        var foundIndex = versionData.findIndex(x => x.version_id === version.version_id);
        let newVersionData = versionData;
        newVersionData[foundIndex].start_min = value;
        setVersionData(newVersionData);
        applyChanges(version);
        
    }

    /**
     * update Version Start sec of song
     * @param {*} version 
     * @param {*} event 
     */
    const updateVersionStartSec = (version , event) => {
        const { value } = event.target;

        var foundIndex = versionData.findIndex(x => x.version_id === version.version_id);
        let newVersionData = versionData;
        newVersionData[foundIndex].start_sec = value;
        setVersionData(newVersionData);
        applyChanges(version);
    }

    const setSong = (song) => {
        const ver  = selectedVersion;
        ver.song = song.file;
        
        const tags = {};
        Object.keys(song.tag).forEach(function(key) {
            // console.log('Key : ' + key + ', Value : ' + song.tag[key])
            tags[key] = song.tag[key][0];
        })
        setFormData(tags)
        setShowSelectWindow(!showSelectWindow)
    }

    const closePopUp = () => {
        setShowSelectWindow(!showSelectWindow)
    }

  return (
    <MusicDashboard accountPages={true} middleSectionCssClass={"account-info-sec full-section"}>
        <div className="content-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>Account Settings</h2>
                </div>
                <AccountNav />
                <div className="account-inner-block">
                <SelectSongModal showState={showSelectWindow} ShowFunction={() => setShowSelectWindow(!showSelectWindow)} fileLists={fileLists} setSong={setSong}  />
                <div className="cardMark mb-3 d-flex justify-content-between"><span>Add Song</span> <span className=""><Link to={`/uploads/index`} className="btn btn-light primary-bg">View All</Link></span></div>
                    <div>
                        <div>
                            {/* <FilePond name="UploadForm[mp3Files]" allowMultiple={true} server={config.apiUrl+'/upload/songs.json?store_id=1&lang_code=en'} /> */}
                            <FilePond
                                files={files}
                                onupdatefiles={setFiles}
                                allowMultiple={true}
                                maxFiles={2}
                                server={uploadURL}
                                name="UploadForm[mp3Files]" 
                                labelIdle='Upload Songs'
                            />
                        </div>
                    </div>
                
                    <Form  validated={validated} onSubmit={handleSubmit} className="upload-song-list">                    
                        <div className="form-content">
                            { versionData && versionData.length > 0 ? 
                                    versionData.map((version, v) => {
                                        return <div key={`version${v}`} className="form-group">
                                                { version.new_label !== ""  ?  
                                                    <div className="mb-1">
                                                        <input className="form-control" type="text" value={version.new_label} name="version_label" onChange={(e) => {
                                                                                changeLabelValue(version, e)
                                                                            }} />  
                                                        <span className="primary-btn" onClick={() => saveLabel(version, version.new_label)} >
                                                            save
                                                        </span> 
                                                    </div> 
                                                    : 
                                                        <Form.Label onClick={() => initChangeLabel(version, version.name)}>{version.new_label !== '' ? version.new_label : version.name}</Form.Label>
                                                }
                                                {   version.song !== '' ?  
                                                    <div className="selected-song-sec">
                                                            <p className="small">{version.song} 
                                                                <span onClick={() => removeSong(version)} className="lnir lnir-trash"  >
                                                                    <svg fill="#FFF" width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                        <path stroke="#fff" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                                                                    </svg>
                                                                </span>
                                                            </p>
                                                            <div className="d-flex">
                                                                <input className="time-control" type="text" name="min" placeholder="Min" onChange={(e) => {
                                                                    updateVersionStartMin(version, e)
                                                                }} /> 
                                                                <input className="time-control" type="text" name="sec" placeholder="Sec" onChange={(e) => {
                                                                    updateVersionStartSec(version, e)
                                                                }} />
                                                            </div>
                                                            <span className="small">play song start time (min/sec)</span>
                                                    </div> : 
                                                    <button type="button" className="secondary-btn" onClick={() => selectSong(version)}>{version.name}{version.song}</button>
                                                }
                                            </div>
                                    })
                                    : '' 
                                }
                            <div className="d-full-block">
                                    <Form.Group className="form-group" controlId="formDepartmentId" >
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control type="text" placeholder="Song Title" name="title" onChange={handleChange} isInvalid={ !!errors.title } value={formData.title} />
                                        {   autoCompleteResult && autoCompleteResult.length > 1 && autoCompleteResult.map((suggestion, i) =>
                                            <div className="suggestion col-md-12 justify-content-md-center">
                                                <Link to={`/uploads/edit-song/${suggestion.entity_id}`}>{suggestion.title}</Link>
                                            </div>
                                        )}
                                        <Form.Control.Feedback type="invalid">
                                            { errors.title }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                                    <Form.Group className="form-group" controlId="formDepartmentId" >
                                        <Form.Label>Artist</Form.Label>
                                        <Form.Control type="text" placeholder="Artist" name="artist" onChange={handleChange} isInvalid={ !!errors.artist } value={formData.artist} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.artist }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                            </div>
                            <div className="d-full-block">
                                
                                    <Form.Group className="form-group" controlId="formDepartmentId" >
                                        <Form.Label>Year</Form.Label>
                                        <Form.Control type="text" placeholder="Year" name="year" onChange={handleChange} isInvalid={ !!errors.year } value={formData.year} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.year }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                               
                                    <Form.Group className="form-group" controlId="formDepartmentId" >
                                        <Form.Label>BPM</Form.Label>
                                        <Form.Control type="text" placeholder="BPM" name="min_bpm" onChange={handleChange} isInvalid={ !!errors.min_bpm } value={formData.min_bpm} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.min_bpm }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                            </div>
                            <div className="d-full-block">
                                
                                    <Form.Group className="form-group" controlId="formDepartmentId" >
                                        <Form.Label>Key</Form.Label>
                                        <Form.Control type="text" placeholder="Song Key" name="song_key" onChange={handleChange} isInvalid={ !!errors.song_key } value={formData.song_key} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.song_key }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                                
                                    <Form.Group className="form-group" controlId="formDepartmentId" >
                                        <Form.Label>Insert Additional Search</Form.Label>
                                        <Form.Control type="text" placeholder="Additional Search Information" name="search_version_term" onChange={handleChange} isInvalid={ !!errors.cardholder_name } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.cardholder_name }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                            </div>
                            <div className="d-full-block">
                                    <Form.Group className="form-group" controlId="formDepartmentId" >
                                        <Form.Label>(Song by default will disable in 90 days) or pick disable date</Form.Label>
                                        {/* <Form.Control type="text" placeholder="Select a disable date" name="expire_date" onChange={handleChange} isInvalid={ !!errors.expire_date } /> */}
                                        <DatePicker
                                            onChange={setExpireDate}
                                            inputPlaceholder="From" 
                                            placeholder="Select a disable date"
                                            shouldHighlightWeekends
                                            name="expire_date"
                                            value={expireDate} 
                                            className="form-control"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.cardholder_name }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                            </div>
                            
                            
                            
                            <div className="upload-checkbox-section">
                            {
                                genresData && genresData.length > 0 ?
                                    genresData.map((item, index) => {
                                    return  <div key={`genrecheck${index}`}>
                                                <div className="card border-0">
                                                    <div className="card-body p-2">
                                                        <h3 className="sub-heading border-bottom border-dark pb-3 mb-3">{item.name}</h3>
                                                        { 
                                                            item.subgenre !== undefined && item.subgenre.length > 0 ?
                                                                item.subgenre.map((subgenres, si) => {
                                                                    return <div key={`subgenrrescheck${si}`}>
                                                                        <Form.Check 
                                                                            value={subgenres.genre_id}
                                                                            type='checkbox'
                                                                            id={`genres${subgenres.genre_id}`}
                                                                            onChange={handleCheckboxChange}
                                                                            name="genres[]"
                                                                            label={subgenres.name} 
                                                                            isInvalid={ !!errors.genres }
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            { errors.genres }
                                                                        </Form.Control.Feedback>
                                                                    </div>
                                                                })
                                                            : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                    })
                                : ''
                            }
                            </div>
                            {btnDisable ? <button  className="primary-btn" disabled="true"><Spinner animation="border" variant="dark" size="sm" /> </button> : <button  className="primary-btn" type="submit">Save</button>}
                        </div>
                    </Form>
                </div>
              </div>
        </div>

    </MusicDashboard>
  );
}
function mapStateToProps(state) {
    return {
        genres: state.genre.genresWithCount,
        versions: state.version.versions,
        fileLists: state.filecontainer.songlists,
        userProfile: state.customer.customerProfile,
    };
}

function mapDispatchToProps(dispatch) {
  return {
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
		getGenresWithCount: () =>dispatch(genresActions.getGenresWithCount()),
        getVersions: () =>dispatch(versionAction.getVersions()),
        getContainerSongsList: () => dispatch(fileContainerAction.getContainerSongsList()),
        createSong: (post) => dispatch(songActions.createSong(post)),
        searchSong: (post) => dispatch(songActions.searchMatches(post))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSong);