import React, { useState, useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link }  from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner';
import ImageSliderLoader from '../../components/loader/imageSliderLoader';
import {connect} from 'react-redux';
import * as actions from '../../store/song/actions';


const  MonthlyTopSongs = ({ top20Albums, getTop20Tracks }) => {

	useEffect(() => { 
		getTop20Tracks({page: 1, limit: 15});
	},[])

	const settings = {
		className: "slider variable-width",
		dots: false,
		infinite: true,
		centerMode: false,
		slidesToShow: 4,
		slidesToScroll: 4,
		variableWidth: true,
		arrows: true,
		responsive: [
		  {
			breakpoint: 1700,
			settings: {
			  slidesToShow: 3,
			  slidesToScroll: 3,
			}
		  },
		  {
			breakpoint: 1600,
			settings: {
			  slidesToShow: 2,
			  slidesToScroll: 2,
			}
		  },
		  {
			breakpoint: 1000,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			}
		  },
		]
	};

    return (
      <div className="slider-sec top-twenty">
			<div className="top-heading">
				<h2>Monthly Top 20</h2>
				<Link to={`/top20/tracks`} className="text-2">See all</Link>
			</div>
			{
				top20Albums && top20Albums.length > 0 ?
					<Slider {...settings}>
						{
							top20Albums.map((item, index) => {
								return <div key={`monthlyTop20${item.top20_track_id}`} className="item">
									<div className="box">
                                        <Link to={`/top20/track/${item.top20_track_id}`}><img src={item.cover_image} alt={item.title} title={item.title} /></Link>
                                        <Link to={`/top20/track/${item.top20_track_id}`}>	<h5>{item.title}</h5> </Link>
									</div>
								</div>
							})
						}
					</Slider>
				: <ImageSliderLoader itemCount={8} />
			}
			
		</div>
				
				
    );
  
}


function mapStateToProps(state) {
	return {
		top20Albums: state.song.top20Albums
	};
}
 
function mapDispatchToProps(dispatch) {
	return {
		getTop20Tracks: () => dispatch(actions.getTop20Albums())
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyTopSongs);
