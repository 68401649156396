import React, { useState, useEffect } from "react";

import {connect} from 'react-redux';
import InfoButton from '../../elements/songElements/infoButton';
import GenresInfoButton from '../../elements/songElements/genresInfoButton';
import FavouriteButton from '../../elements/songElements/favouriteButton';
import DownloadAllButton from '../../elements/songElements/downloadAllButton';
import SongToggleButton from '../../elements/songElements/songToggleButton';
import Spinner from 'react-bootstrap/Spinner';
import PlayPauseButton from '../../elements/songElements/playPauseButton';
import SongVersion from './songVersion';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as actions from '../../store/customer/actions';

const  PlaylistSongTrack = ({ songs, sectionName, albumId, removeSongFromCustomerPlaylist, displayLoading, hideLoading, getPlayListSongs }) => {

    // const [toggleSong, setToggleSong ] = useState(false);
    const [activeSongId, setActiveSongId] = useState(0);
    const [isRemoveProcess, setIsRemoveProcess ] = useState(false);

    // const setSongToggle = (songId) => {
    //     if(activeSongId == songId ){
    //         setToggleSong(!toggleSong)
    //     } else {
    //         setToggleSong(true)
    //     }
    //     setActiveSongId(songId)
    // }

    const removeSongFromPlalist = async(songId) => {
        setActiveSongId(songId);
        displayLoading();
        setIsRemoveProcess(true);
        await removeSongFromCustomerPlaylist({entity_id: songId, album_id: albumId}).then(res => {
             
        });
        await getPlayListSongs({album_id: albumId});
        setIsRemoveProcess(false);
        setActiveSongId(0);
        hideLoading();
    }

    

    return (
        <div className="play-list">
        {
            songs && songs.length > 0 ? 
                songs.map((song, index)=>{
                    return <div className="block" key={`song${sectionName}${song.entity_id}`}>
                                <div className="play-track">
                                    <PlayPauseButton parentComponent="songTrack" song={song} />
                                    <div className="track-name">
                                        <span className="text-2" dangerouslySetInnerHTML={{__html: song.title}}></span>
                                        <span className="text-5" dangerouslySetInnerHTML={{__html: song.artist}}></span>
                                    </div>
                                </div>
                                <div className="play-track-content">
                                    <div>
                                        <span className="text-5">{song.max_bpm}</span>
                                        <span className="text-4">{song.song_key}</span>
                                        <span className="text-5">{song.created_at}</span>
                                    </div>
                                    {/* <div className="divider">
                                        <InfoButton song={song} />
                                        <GenresInfoButton song={song} />
                                    </div> */}
                                    <div className="divider">
                                        <FavouriteButton song={song} />
                                        <DownloadAllButton entity_id={song.entity_id} />
                                    </div>
                                    <div>
                                        {
                                            isRemoveProcess && activeSongId ==  song.entity_id ? 
                                            <Spinner animation="border" variant="danger" size="sm" />
                                            :
                                            <span onClick={() => removeSongFromPlalist(song.entity_id)}>
                                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
                                                </svg>
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="version-container">
                                {
                                    song.version && song.version.length > 0 ?
                                        song.version.map((version, index) => {
                                            return <div className='block-inner-content' key={`song${sectionName}version${song.entity_id}${version.song_version_id}`}>
                                                <SongVersion version={version} songId={song.entity_id} songSectionId={song.song_section_id} song={song} />
                                            </div>
                                        })
                                    : ''
                                }
                                </div>
                        </div>
                    })
            : ''
        }
        </div>
    );
  
}


function mapStateToProps(state) {
  return {
    
  };
 }
 
 function mapDispatchToProps(dispatch) {
    return {
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        removeSongFromCustomerPlaylist: (post) => dispatch(actions.removeSongFromCustomerPlaylist(post)),
        getPlayListSongs: (post) => dispatch(actions.getPlayListSongs(post)),
        
    };
 }

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistSongTrack);
