import React, { useState, useEffect } from "react";

import {connect} from 'react-redux';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';

const GenresInfoButton = ({ song, themeType }) => {

    const getSongGenres = ( ) => {
        return song && song.genre_info && song.genre_info.length > 0 ?
            song.genre_info.map((genre, index) => {
                return <div className="d-flex" key={`genreInnfoSong${genre.genre_id}`}>
                    <Link to={`/genres/view/${genre.genre_id}`}>{genre.name}</Link>
                </div>
            })
        : 'dd'
    }    
        return (
            <span data-tooltip-id={`tooltipdate${song.entity_id}`} >
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.04009 16.8539L2.57151 8.85388C2.56177 8.77329 2.56931 8.69155 2.59363 8.6141C2.61795 8.53665 2.6585 8.46527 2.71256 8.40472C2.76663 8.34416 2.83298 8.29582 2.90719 8.26292C2.9814 8.23001 3.06177 8.2133 3.14294 8.21388H8.41151C8.53961 8.21467 8.66387 8.25764 8.7651 8.33614C8.86632 8.41464 8.93886 8.5243 8.97151 8.64817L9.42866 10.4996H16.8572C16.9363 10.4993 17.0145 10.5155 17.087 10.547C17.1595 10.5786 17.2246 10.6248 17.2784 10.6828C17.3321 10.7408 17.3731 10.8093 17.399 10.884C17.4249 10.9587 17.435 11.0379 17.4287 11.1167L16.9829 16.831C16.9599 17.118 16.8294 17.3857 16.6175 17.5807C16.4056 17.7756 16.128 17.8834 15.8401 17.8825H4.18294C3.89864 17.8839 3.62401 17.7793 3.41268 17.5891C3.20136 17.3989 3.06851 17.1368 3.04009 16.8539Z" stroke={themeType == 'dark-theme' ? "#D7D7D7" : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 5.92843V3.64272C6 3.49117 6.0602 3.34582 6.16737 3.23866C6.27453 3.13149 6.41988 3.07129 6.57143 3.07129H16.2857C16.4373 3.07129 16.5826 3.13149 16.6898 3.23866C16.7969 3.34582 16.8571 3.49117 16.8571 3.64272V8.21415" stroke={themeType == 'dark-theme' ? "#D7D7D7" : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.5713 5.92871H13.9999" stroke={themeType == 'dark-theme' ? "#D7D7D7" : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <ReactTooltip
                style={{ backgroundColor: "#EB4E27", color: "#fff" }}
                place="top" variant="success" events={['click']} id={`tooltipdate${song.entity_id}`} >{ getSongGenres() }</ReactTooltip>
            </span>
        )
   
    
   

}
function mapStateToProps(state) {
    return {
        themeType: state.theme.theme,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(GenresInfoButton);
  