import React, {useState, useEffect} from "react";

import SinglePageLayout from "../../layouts/singlePageLayout/singlePageLayout";
import {connect} from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useFormInputValidation } from "react-form-input-validation";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import logo from './../../assets/images/Logo.svg';

import * as actions from '../../store/customer/actions';

const SignUp = ({ signUp, getUserProfile  }) => {
	const navigate = useNavigate();
	const [fields, errors, form] = useFormInputValidation({
		email: "",
		dj_name: "",
		password: "",
		confirm_password: ""
	  }, {
		email: "required|email",
		dj_name: "required",
		password: "required",
		confirm_password: "required|same:password"
	});
	
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setConfirmShowPass] = useState(false);
	const [passwordType, setPasswordType] = useState('password');
	const [confirmpasswordType, setConfirmPasswordType] = useState('password');
	
	const [processing, setProcessing] = useState(false);
	const [ responseError, setResponseError ] = useState();

	useEffect(() => { 
		
	},[]);

	const showPassword = () => {
		if(!showPass){
		  setPasswordType('text');
		} else {
		  setPasswordType('password');
		}
		setShowPass(!showPass);
	}
	const showConfirmPassword = () => {
		if(!showConfirmPass){
		  setConfirmPasswordType('text');
		} else {
		  setConfirmPasswordType('password');
		}
		setConfirmShowPass(!showConfirmPass);
	}

	
	const onSubmit = async (event) => {
		
		const isValid = await form.validate(event);
		
		if (isValid) {
			setProcessing(true);
			let post = {
				email: fields.email,
				dj_name: fields.dj_name,
				password_hash: fields.password,
				confirm_password: fields.confirm_password
			}
			await signUp(post).then(res => { 
		  
				if(res.payload.response && res.payload.response.status === 401){
				  if(res.payload.response.data.message === 'You have not submitted DJ Application.'){
						setProcessing(false);
						setResponseError(res.payload.response.data.message);
				  } else {
					setResponseError(res.payload.response.data.message.errors[0]);
				  }
				} else if(res.payload.data) {
					setResponseError('');
					localStorage.setItem('user', res.payload.data.token);
					if(res.payload.data.is_active == 0){
						navigate(`/dj-app/${fields.email}`);
					}
					
					setProcessing(false)
				}
				setProcessing(false)
			}).catch(err=>{ 
				  setProcessing(false)
			});
		}
	}

	
  return (
    <SinglePageLayout>
        <div className="sign-in">
			<div className="sign-in-left">
				<div className="top">
					<Link to={`/sign-in`} className="logo"><img src={logo} alt="Clubkillers sign in" title="Clubkiller sing in"/></Link>
					<Link to={`/sign-in`} className="close">
						<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
						</svg>
					</Link>
				</div>
				<div className="form-sec">
					<h4>Welcome to Club Killers!</h4>
					<h1>Create An Account</h1>
					
					<div>
						{ responseError ? <div className="alert alert-danger">{responseError}</div> : ''}
						<form className="myForm"
								noValidate
								onSubmit={onSubmit}>
							
							<div className="form-group">
								<label className="text-5">Email</label>
								<input className={ errors.email ? "text-3 form-control error-input" : "text-3 form-control" }
										type="text" 
										name="email"
										onBlur={form.handleBlurEvent}
          								onChange={form.handleChangeEvent}
										placeholder="Enter your email"/>
								<span className="error">{ errors.email ? errors.email : ""}</span>
							</div>
							<div className="form-group">
								<label className="text-5">DJ Name</label>
								<input className={ errors.dj_name ? "text-3 form-control error-input" : "text-3 form-control" }
										type="text" 
										name="dj_name"
										onBlur={form.handleBlurEvent}
          								onChange={form.handleChangeEvent}
										placeholder="Enter your DJ Name"/>
								<span className="error">{ errors.dj_name ? errors.dj_name : ""}</span>
							</div>
							<div className="form-group">
								<label className="text-5">Password</label>
								<div className="input-pass">
									<input className={ errors.password ? "text-3 form-control error-input" : "text-3 form-control" }
											name="password"
											type={passwordType}
											onBlur={form.handleBlurEvent}
          									onChange={form.handleChangeEvent}
											placeholder="Password"/>
									{ passwordType === 'password' ?
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={showPassword}>
										<path d="M2.80658 7.06992C6.03344 1.64336 13.9666 1.64336 17.1934 7.06992C18.2689 8.87846 18.2689 11.1215 17.1934 12.9301C13.9666 18.3566 6.03344 18.3566 2.80658 12.9301C1.73114 11.1215 1.73114 8.87846 2.80658 7.06992Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
										<path fillRule="evenodd" clipRule="evenodd" d="M13.1615 10.0531C13.1615 11.7991 11.7455 13.2141 9.9995 13.2141C8.2535 13.2141 6.8385 11.7991 6.8385 10.0531C6.8385 8.30611 8.2535 6.89111 9.9995 6.89111C11.7455 6.89111 13.1615 8.30611 13.1615 10.0531Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M17.5 3L2 17" stroke="#D7D7D7" strokeLinecap="round"/>
									</svg>
									:
									<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={showPassword}>
										<path d="M1.80658 5.06992C5.03344 -0.35664 12.9666 -0.356639 16.1934 5.06992C17.2689 6.87846 17.2689 9.12154 16.1934 10.9301C12.9666 16.3566 5.03344 16.3566 1.80658 10.9301C0.731141 9.12154 0.731141 6.87846 1.80658 5.06992Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
										<path fillRule="evenodd" clipRule="evenodd" d="M12.1615 8.05311C12.1615 9.79911 10.7455 11.2141 8.9995 11.2141C7.2535 11.2141 5.8385 9.79911 5.8385 8.05311C5.8385 6.30611 7.2535 4.89111 8.9995 4.89111C10.7455 4.89111 12.1615 6.30611 12.1615 8.05311Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
									}
								</div>
								<span className="error text-5">{ errors.password ? errors.password : ""}</span>
							</div>
							<div className="form-group">
								<label className="text-5">Connfirm Password</label>
								<div className="input-pass">
									<input className={ errors.confirm_password ? "text-3 form-control error-input" : "text-3 form-control" }
											name="confirm_password"
											type={confirmpasswordType}
											onBlur={form.handleBlurEvent}
          									onChange={form.handleChangeEvent}
											placeholder="Password"/>
									{ confirmpasswordType === 'password' ?
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={showConfirmPassword}>
										<path d="M2.80658 7.06992C6.03344 1.64336 13.9666 1.64336 17.1934 7.06992C18.2689 8.87846 18.2689 11.1215 17.1934 12.9301C13.9666 18.3566 6.03344 18.3566 2.80658 12.9301C1.73114 11.1215 1.73114 8.87846 2.80658 7.06992Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
										<path fillRule="evenodd" clipRule="evenodd" d="M13.1615 10.0531C13.1615 11.7991 11.7455 13.2141 9.9995 13.2141C8.2535 13.2141 6.8385 11.7991 6.8385 10.0531C6.8385 8.30611 8.2535 6.89111 9.9995 6.89111C11.7455 6.89111 13.1615 8.30611 13.1615 10.0531Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
										<path d="M17.5 3L2 17" stroke="#D7D7D7" strokeLinecap="round"/>
									</svg>
									:
									<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={showConfirmPassword}>
										<path d="M1.80658 5.06992C5.03344 -0.35664 12.9666 -0.356639 16.1934 5.06992C17.2689 6.87846 17.2689 9.12154 16.1934 10.9301C12.9666 16.3566 5.03344 16.3566 1.80658 10.9301C0.731141 9.12154 0.731141 6.87846 1.80658 5.06992Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
										<path fillRule="evenodd" clipRule="evenodd" d="M12.1615 8.05311C12.1615 9.79911 10.7455 11.2141 8.9995 11.2141C7.2535 11.2141 5.8385 9.79911 5.8385 8.05311C5.8385 6.30611 7.2535 4.89111 8.9995 4.89111C10.7455 4.89111 12.1615 6.30611 12.1615 8.05311Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
									</svg>
									}
								</div>
								<span className="error text-5">{ errors.confirm_password ? errors.confirm_password : ""}</span>
							</div>
							<p className="text-5">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our</p>
							<div className="form-group">
								{ processing ? <button className="primary-btn" disabled={processing}><Spinner animation="border" variant="dark" size="sm" /></button> : <button type="submit" className="primary-btn">Create An Account</button> }
							</div>
						</form>
						<Link to="/sign-in" className="text-2 fgp">Already have an account? Login in</Link>
					</div>
				</div>
			</div>
			<div className="sign-in-right">
				<div className="inner-bg">
					<div className="content">
						<div className="custom-font">Enjoy Your Favourite Music With Club Killers</div>
						<h2>Club Killers - an mp3 pool, offering special edits to Dj's and disc jockeys.</h2>
					</div>
				</div>
			</div>
		</div>
    </SinglePageLayout>
  );
}

function mapStateToProps(state) {
  return {
	
  };
}

function mapDispatchToProps(dispatch) {
  return {
		signUp: (post) => dispatch(actions.signUp(post)),
		getUserProfile: (token) => dispatch(actions.getCustomerProfile(token)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);