import React, {useState, useEffect} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form'
import { Link } from 'react-router-dom';
import  API_BASE_URL  from '../../config/config';
import { FilePond, File, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {connect} from 'react-redux';
import * as commonActions from '../../store/common/actions';
import * as customerAction from '../../store/customer/actions';






const EditProfile = ({customer, getCountryList, countryList, authentication, updateProfile, getUserProfile, user, displayLoading, hideLoading}) => {
  
    const [formData, setFormData] = useState();
    const [formDataEnter, setFormDataEnter] = useState(false);
    const [isCountrySelected, setIsCountrySelected] = useState(false);
    const [ processing, setProcessing ] = useState(false);
    const [ responseError, SetResponseError ] = useState();
    const [ responseSuccess, SetResponseSuccess ] = useState();
    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    const [region, setRegion] = useState();
    const [files, setFiles] = useState([]);
    const uploadURL = API_BASE_URL.apiUrl+"/customer/upload-profile-image.json?token="+authentication.user+"&store_id=1&lang_code=en";

    useEffect(() => { 
        getCountryList();
    },[]);

    useEffect(() => { 
        if(customer && customer.customerProfile && formDataEnter === false){
            setFormData({
                firstname: customer && customer.customerProfile && customer.customerProfile.firstname ? customer.customerProfile.firstname : '',
                lastname: customer && customer.customerProfile && customer.customerProfile.lastname ? customer.customerProfile.lastname : '',
                street: customer && customer.customerProfile && customer.customerProfile.street ? customer.customerProfile.street : '',
                country_id: customer && customer.customerProfile && customer.customerProfile.country_id ? customer.customerProfile.country_id : '',
                region_id: customer && customer.customerProfile && customer.customerProfile.region_id ? customer.customerProfile.region_id : '',
                city: customer && customer.customerProfile && customer.customerProfile.city ? customer.customerProfile.city : '',
                postcode: customer && customer.customerProfile && customer.customerProfile.postcode ? customer.customerProfile.postcode : '',
                email: customer && customer.customerProfile && customer.customerProfile.email ? customer.customerProfile.email : '',
                dj_name: customer && customer.customerProfile && customer.customerProfile.dj_name ? customer.customerProfile.dj_name : '',
            });
            setFormDataEnter(true);
        }
        if(isCountrySelected == false && customer && customer.customerProfile &&  countryList && countryList.length > 0){
            const selectedCountry = countryList.find((element) => {
                return element.id === customer.customerProfile.country_id;
            });
            if(selectedCountry && selectedCountry.region){
                setRegion(selectedCountry.region)
                setIsCountrySelected(true);
            }
            
            
        }
    },[customer])
  
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });    
        
        if(name === 'country_id'){
            const selectedCountry = countryList.find((element) => {
                return element.id === value;
            });
            setRegion(selectedCountry.region)
        } 
    }

    const findFormErrors = () => {
        const { firstname, lastname, street, country_id, region_id, postcode, email, city } = formData
        const newErrors = {}

        
        // password errors
        if ( !firstname || firstname === '' ) newErrors.firstname = 'First Name cannot be blank!'
        if ( !lastname || lastname === '' ) newErrors.lastname = 'Last Name cannot be blank!'
        if ( !email || email === '' ) newErrors.email = 'Email cannot be blank!'
        if ( !country_id || country_id === '' ) newErrors.country_id = 'Country cannot be blank!'
        if ( !region_id || region_id === '' ) newErrors.region_id = 'State cannot be blank!'
        if ( !city || city === '' ) newErrors.city = 'City cannot be blank!'
        if ( !postcode || postcode === '' ) newErrors.postcode = 'Zip Code cannot be blank!'
        if ( !street || street === '' ) newErrors.street= 'Address cannot be blank!'

        return newErrors
    }

    const handleSubmit = (event) => { 
        displayLoading(); 
        setProcessing(true);

        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        // console.log(newErrors)
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            SetResponseError('');
                    SetResponseSuccess('');
            updateProfile(formData).then(res => {
                if(res && res.payload && res.payload.message && res.payload.message.errors){
                    SetResponseError(res.payload.message.errors[0]);
                    SetResponseSuccess('');
                    hideLoading();
                    setProcessing(false);
                } else {
                    SetResponseError('');
                    SetResponseSuccess('Profile successfully updated');
                    hideLoading();
                    setProcessing(false);
                }
            });
            
        }
    }

    const getProfile =  async() => {
        
        await getUserProfile({token: user}).then(res => {
            // window.location.href= "/";
        })
    }

  return (
    <MusicDashboard  accountPages={true} middleSectionCssClass={'account-info-sec'}>
       	<div className="content-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>Account Settings</h2>
                </div>
                <AccountNav />
                <div className="account-inner-block">
                    <div className="edit-profile">
                        <div className="title-heading">
                            <h3>Edit profile</h3>
                            <Link to={`/logout`} className="log-link text-5">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 14V16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16V5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H12C12.2652 4 12.5196 4.10536 12.7071 4.29289C12.8946 4.48043 13 4.73478 13 5V7" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10 10.5H17" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M15 8.5L17 10.5L15 12.5" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                Log Out
                            </Link> 
                        </div>
                        <div className="edit-photo">
                            {
                                customer && customer.customerProfile ?
                                    <div className="photo">
                                        {
                                            customer.customerProfile.profile_picture ?
                                                <img src={customer.customerProfile.profile_picture} title={customer.customerProfile.dj_name}/>
                                            :
                                                <div className="circle">
                                                    <p className="circle-inner">{customer.customerProfile.dj_name ? Array.from(customer.customerProfile.dj_name)[0] : Array.from(customer.customerProfile.firstname)[0]}</p>
                                                </div>
                                        }
                                        
                                        
                                    </div>
                                : ''
                                        }
                            <span>
                                <FilePond
                                            files={files}
                                            onupdatefiles={setFiles}
                                            allowMultiple={true}
                                            maxFiles={1}
                                            server={uploadURL}
                                            name="UploadFilesForm[files]" 
                                            labelIdle='Edit photo'
                                            onprocessfile={(error, file) => {
                                                // Set currently active file objects to this.state
                                                getProfile(); 
                                            }}
                                        />
                            </span>
                        </div>
                        
                        
                        <Form  validated={validated} onSubmit={handleSubmit}>
                            <div className="form-content">
                            <div className="mt-1 mb-1 response-msg">
                                {responseSuccess ? <div className="alert alert-success">{responseSuccess}</div>: ''}
                                {responseError ? <div className="alert alert-danger">{responseError}</div>: ''}
                            </div>
                                <div>
                                    <Form.Group className="form-group" controlId="formBasicEmail" >
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" placeholder="First Name" name="firstname" defaultValue={customer.customerProfile.firstname} onChange={handleChange} isInvalid={ !!errors.firstname } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.firstname }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group" controlId="formBasicEmail">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" placeholder="Last Name" name="lastname" defaultValue={customer.customerProfile.lastname} onChange={handleChange} isInvalid={ !!errors.lastname } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.lastname }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div>
                                    <Form.Group className="form-group" controlId="formBasicEmail">
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control type="text" placeholder="Email" name="email" defaultValue={customer.customerProfile.email} onChange={handleChange} isInvalid={ !!errors.email } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.email }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group" controlId="formBasicEmail">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="text" placeholder="Phone Number" name="telephone" defaultValue={customer.customerProfile.telephone} onChange={handleChange} isInvalid={ !!errors.telephone } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.telephone }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div>
                                    <Form.Group className="form-group" controlId="formBasicEmail">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control type="text" placeholder="Address" name="street" defaultValue={customer.customerProfile.street} onChange={handleChange} isInvalid={ !!errors.street } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.street }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group" controlId="formBasicEmail">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control type="text" placeholder="City" name="city" defaultValue={customer.customerProfile.city} onChange={handleChange} isInvalid={ !!errors.city } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.city }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div>
                                {
                                    isCountrySelected && customer.customerProfile && customer.customerProfile.country_id  ? 
                                        <Form.Group className="form-group" controlId="formBasicEmail">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control required as="select" type="select" name="country_id" onChange={handleChange} defaultValue={parseInt(customer.customerProfile.country_id)}>
                                                <option value="">Select country</option> 
                                                {
                                                    countryList && countryList.length > 0 ?
                                                    countryList.map((country, index)=>{
                                                        return <option value={country.id} key={`country-${country.id}`} selected={customer.customerProfile.country_id == country.id ? true :  false}>{country.name}</option>
                                                    })
                                                    : ''
                                                }
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.country_id }
                                            </Form.Control.Feedback>
                                        </Form.Group> :
                                        <Form.Group className="form-group" controlId="formBasicEmail">
                                            <Form.Label>Country </Form.Label>
                                            <Form.Control required as="select" type="select" name="country_id" onChange={handleChange} defaultValue={customer.customerProfile.country_id}>
                                                <option value="">Select Country </option> 
                                                {
                                                    countryList && countryList.length > 0 ?
                                                    countryList.map((country, index)=>{
                                                        return <option value={country.id} key={`country-${country.id}`}>{country.name}</option>
                                                    })
                                                    : ''
                                                }
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.country_id }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                }
                                    
                                {
                                    region && region.length > 0 ?
                                        <Form.Group className="form-group" controlId="formBasicEmail">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control required as="select" type="select" name="region_id" onChange={handleChange} defaultValue={customer.customerProfile.region_id}>
                                                <option value="">Select Region</option> 
                                                {
                                                    region.map((reg, index)=>{
                                                    return <option value={reg.id} key={`region-${reg.id}`}>{reg.name}</option>
                                                    })
                                                }
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.region_id }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    : ''
                                }
                                </div>
                                <div>
                                    <Form.Group className="form-group" controlId="formBasicEmail">
                                        <Form.Label>Zip Code</Form.Label>
                                        <Form.Control type="text" placeholder="Zip Code" name="postcode" defaultValue={customer.customerProfile.postcode} onChange={handleChange} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.postcode }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="save-btn">
                            { processing ? <button className="primary-btn"><Spinner animation="border" variant="dark" size="sm" /></button> : <button type="submit" className="primary-btn">Save changes</button> } 
                            </div>
                        </Form>
                        
                    </div>
                </div>
            </div>
		</div>
		
        
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
    return {
        customer: state.customer,
        countryList: state.common.countryList,
        user: state.authentication.user,
        authentication: state.authentication
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCountryList: () => dispatch(commonActions.getCountryList()),
        updateProfile: (post) => dispatch(customerAction.updateProfile(post)),
        getUserProfile: (token) => dispatch(customerAction.getCustomerProfile(token)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);