import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const Dropbox = {
    addRemoveToDropBox,
    downloadAllFromDropBox,
    removeAllFromDropBox,
    getDropboxSongs,
    uploadFilesOnDropBox,
    addSongEntityToDropBox
}

function addRemoveToDropBox(data){
    
    const url = 'dropbox/add-remove.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function removeAllFromDropBox(){
    
    const url = 'dropbox/clear-dropbox.json?store_id=1&lang_code=en';
    const data = {
        token: USER_TOKEN
    }
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function downloadAllFromDropBox(data){
    
    const url = 'dropbox/download-all.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}


function getDropboxSongs(){
    
    const url = 'dropbox/get-dropbox-items.json?store_id=1&lang_code=en';
    const data = {
        token: USER_TOKEN
    }
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}


function uploadFilesOnDropBox(){
    
    const url = 'dropbox/upload-to-dropbox.json?store_id=1&lang_code=en';
    const data = {
        token: USER_TOKEN
    }
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
}

function addSongEntityToDropBox(data){
    
    const url = 'dropbox/add-song-to-dropbox.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
}

export default Dropbox