import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const Trending = {
    getLatestTrendingSong,
    getLatestTrendingTrackAlbums,
    getTrendingTrackSongs
}

function getLatestTrendingSong(){
    const url = 'trending-track/get-trending-tracks.json?store_id=1&lang_code=en';
    const data = {token:USER_TOKEN };
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function getLatestTrendingTrackAlbums(data){
    const url = 'trending-track/trending-albums.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN ;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}


function getTrendingTrackSongs(data){
    const url = 'trending-track/trending-track-songs.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN ;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

export default Trending;