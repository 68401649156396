import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import {connect} from 'react-redux';
import * as actions from '../../store/dropbox/actions';

const DropboxButton = ({ songId, version, songSectionId, addRemoveToDropBox, dropbox }) => {

    const [ downloadInProcess, setDownloadInProcess ] = useState(false);
    const addRemoveSongToDropbox = async() => {
        setDownloadInProcess(true);
        await addRemoveToDropBox({songId: songId, versionId: version.song_version_id});
        setDownloadInProcess(false)
    }
    
        if(downloadInProcess){
            return <Spinner animation="border" variant="danger" size="sm" />
        } else {
            return (
                <span onClick={() => addRemoveSongToDropbox()} className="cp" title="Add to Dropbox Queue">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 6.16667L5.66667 8.825L9.83333 6.16667L5.66667 3.5L1.5 6.16667ZM9.83333 6.16667L14 8.825L18.1667 6.16667L14 3.5L9.83333 6.16667ZM1.5 11.4583L5.66667 14.1167L9.83333 11.4583L5.66667 8.79167L1.5 11.4583ZM14 8.79167L9.83333 11.4583L14 14.1167L18.1667 11.4583L14 8.79167ZM5.69167 15L9.86667 17.6667L14.0333 15L9.86667 12.3417L5.69167 15Z" 
                        fill={ dropbox && dropbox.verions && dropbox.verions.length > 0 && dropbox.verions.indexOf(version.song_version_id.toString()) >= 0 ? '#EB4E27' : "#D7D7D7" } />
                    </svg>
                </span>
            )
        }
    
   

}
function mapStateToProps(state) {
    return {
        dropbox: state.dropbox.dropboxSongs.dropbox
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        addRemoveToDropBox: (songId, versionId) => dispatch(actions.addRemoveToDropBox(songId, versionId)),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DropboxButton);
  