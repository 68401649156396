import * as types from './type';


export function setActiveDownloadBtn(data) {
  
    return {
      type: types.SET_ACTIVE_DOWNLOAD_BTN,
      payload: data,
    };
};
