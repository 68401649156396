import React from "react";
import {connect} from 'react-redux';


const  TrendingTrackLoader = ({ itemCount }) => {

    var indents = [];
    
    for (var i = 0; i < itemCount; i++) {
    indents.push(
            <div className="wrapper-row">
                <div className="wrapper-cell">
                    <div className="image"></div>
                    <div className="text">
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                    </div>
                </div>
            </div>      
                );
    }

    return (
        <div>{indents}</div>	
    );
  
}


function mapStateToProps(state) {
	return {
		
	};
}
 
function mapDispatchToProps(dispatch) {
	return {
		
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendingTrackLoader);

            