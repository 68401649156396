import React, { useState, useEffect } from "react";

import {connect} from 'react-redux';
import SingleDownloadButton from '../../elements/songElements/singleDownloadButton';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';

const InfoButton = ({ song, themeType }) => {

    const getVersionsHtml = () => {
        return song.version && song.version.length > 0 ?
            song.version.map((version, index) => {
                    return <div key={`downloadVersionInfo${version.song_version_id}${index}`}>
                    <span className="text-2">{version.version_label}</span>
                    {/* <SingleDownloadButton songId={song.entity_id} version={version} /> */}
                </div>
            }) 
        : ''
    }
        return (
            <span data-tooltip-id={`tooltipInfo${song.entity_id}`}>
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.99986 15.9284C12.1025 15.9284 15.4284 12.6025 15.4284 8.49986C15.4284 4.39717 12.1025 1.07129 7.99986 1.07129C3.89717 1.07129 0.571289 4.39717 0.571289 8.49986C0.571289 12.6025 3.89717 15.9284 7.99986 15.9284Z" stroke={themeType == 'dark-theme' ? "#D7D7D7" : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8 4.5V7.92857" stroke={themeType == 'dark-theme' ? "#D7D7D7" : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7.99916 11.928C8.31475 11.928 8.57059 11.6722 8.57059 11.3566C8.57059 11.041 8.31475 10.7852 7.99916 10.7852C7.68357 10.7852 7.42773 11.041 7.42773 11.3566C7.42773 11.6722 7.68357 11.928 7.99916 11.928Z" stroke={themeType == 'dark-theme' ? "#D7D7D7" : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <ReactTooltip  events={['click']} id={`tooltipInfo${song.entity_id}`}>{getVersionsHtml()}</ReactTooltip>
            </span>
        )
   
    
   

}
function mapStateToProps(state) {
    return {
        themeType: state.theme.theme,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(InfoButton);
  