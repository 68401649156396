import * as types from './type';

const initialState = {
    isPlaying: false
};

export default function isplayingReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_IS_PLAYING:{
            // console.log('reducer', action.payload)
            // if (action.payload && action.payload) {
                return {
                    isPlaying:  action.payload
                }
            // }
        }
        case types.GET_IS_PLAYING: {
            return {
                isPlaying: state.isPlaying
            }
        }
        default: {
            return {
              ...state
            };
          }
    }
}