import React, { useState, useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link }  from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner';
import ImageSliderLoader from '../../components/loader/imageSliderLoader';

import {connect} from 'react-redux';
import * as actions from '../../store/curated/actions';


const  CuratedSpotlightCarousel = ({ curatedItems, getCuratedItems, title }) => {

	useEffect(() => { 
		getCuratedItems();
	},[])

	const settings = {
		className: "slider variable-width",
		dots: false,
		infinite: true,
		centerMode: false,
		slidesToShow: 4,
		slidesToScroll: 4,
		variableWidth: true,
		arrows: true,
		responsive: [
		  {
			breakpoint: 1700,
			settings: {
			  slidesToShow: 3,
			  slidesToScroll: 3,
			}
		  },
		  {
			breakpoint: 1600,
			settings: {
			  slidesToShow: 2,
			  slidesToScroll: 2,
			}
		  },
		  {
			breakpoint: 1000,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			}
		  },
		]
	};

	// var settings = {
	// 	dots: true,
	// 	infinite: true,
	// 	speed: 500,
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1
	// };
    
    return (
      <div className="slider-sec">
			<div className="top-heading">
				<h2>{title}</h2>
				<Link to={`/mp3/spotlight/home`} className="text-2">See all</Link>
			</div>
			{
				curatedItems && curatedItems.length > 0 ?
					<Slider {...settings}>
						{
							curatedItems.map((item, index) => {
								return <div key={`curatedSpotlight${item.curated_list_id}`} className="item">
									<div className="box">
									<Link to={`/mp3/spotlight/detail/${item.curated_list_id}`}><img src={item.image} alt={item.title} title={item.title} /></Link>
									<Link to={`/mp3/spotlight/detail/${item.curated_list_id}`}>	<h5>{item.title}</h5> </Link>
									</div>
								</div>
							})
						}
					</Slider>
				: 
				// <div style={{textAlign: 'center'}}><Spinner animation="border" variant="danger" size="lg" /></div>
				<ImageSliderLoader itemCount={8} />
			}
			
		</div>
				
				
    );
  
}


function mapStateToProps(state) {
	return {
		curatedItems: state.curated.homeCuratedList
	};
}
 
function mapDispatchToProps(dispatch) {
	return {
		getCuratedItems: (orderId) => dispatch(actions.getHomeCuratedLists()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CuratedSpotlightCarousel);
