import React, { useState, useEffect } from "react";

import {connect} from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import * as themeAction from '../../store/theme/actions';
import logoDark from './../../assets/images/cklogo.png';
import logoLight from './../../assets/images/cklogo.png';

const  LeftNav = ({ themeType, changeTheme, userProfile, isLeftmenuExpand }) => {

	const [theme, setTheme ] = useState('light');
	const [isLeftExpanded, setIsLeftExpanded] = useState(isLeftmenuExpand);
	const location = useLocation();
	const changeMode = (mode) => {
      
		if(themeType === 'light-theme'){
		  setTheme('dark');
		  changeTheme('dark-theme');
		  document.body.classList.remove('light-theme');
		  document.body.classList.add('dark-theme');
		} else {
		  setTheme('light');
		  changeTheme('light-theme');
		  document.body.classList.remove('dark-theme');
		  document.body.classList.add('light-theme');
		}
	}

	useEffect(() => { 
		setIsLeftExpanded(!isLeftmenuExpand);
	},[isLeftmenuExpand]);
    
    return (
        <div className={isLeftmenuExpand ? 'left-nav active' : 'left-nav'}>
			<div className="top-logo">
				<Link to={`/`}>
					{ themeType == 'light-theme' ? <img src={logoLight} alt="Clubkillers" title="Clubkiller"  /> : <img src={logoDark} alt="Clubkillers" title="Clubkiller"  /> }
				</Link>
				<div className="close-nav" onClick={() => console.log('clicked')}>
					<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 1L9 9M9 1L1 9" stroke={ themeType == 'light-theme' ? "black" : "white"} strokeLinecap="round"/>
					</svg>
				</div>
			</div>
			
			<div className="nav-link">
				<ul>
					<li className={location.pathname === '/' ? 'active' : ''}>
						<Link to={`/`}>
							<svg width="14" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M11.9996 7.42814C12.4608 7.47238 12.9261 7.41409 13.3622 7.25743C13.7983 7.10077 14.1943 6.84962 14.522 6.52198C14.8496 6.19433 15.1008 5.79829 15.2574 5.36221C15.4141 4.92614 15.4724 4.46082 15.4281 3.99957C15.4724 3.53832 15.4141 3.073 15.2574 2.63693C15.1008 2.20085 14.8496 1.80481 14.522 1.47716C14.1943 1.14952 13.7983 0.898372 13.3622 0.741713C12.9261 0.585055 12.4608 0.526758 11.9996 0.570999C8.85671 0.570999 7.14243 7.42814 3.99957 7.42814C3.53833 7.47238 3.073 7.41409 2.63693 7.25743C2.20085 7.10077 1.80481 6.84962 1.47716 6.52198C1.14952 6.19433 0.898371 5.79829 0.741713 5.36221C0.585054 4.92614 0.526758 4.46082 0.570999 3.99957C0.526758 3.53832 0.585054 3.073 0.741713 2.63693C0.898371 2.20085 1.14952 1.80481 1.47716 1.47716C1.80481 1.14952 2.20085 0.898372 2.63693 0.741713C3.073 0.585055 3.53833 0.526758 3.99957 0.570999C7.14243 0.570999 8.85671 7.42814 11.9996 7.42814Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
							<span className="text-1 left-nav-text">Explore</span>
						</Link>
					</li>
					<li className={location.pathname === '/mp3/charts' ? 'active' : ''}>
						<Link to={`/mp3/charts`}>
							<svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1.41699 7.47421L14.5598 1.49707" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M12.1035 0.571289L14.5607 1.497L13.6464 3.95415" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M15.1436 15.4395H12.2864V7.43945C12.2864 7.2879 12.3466 7.14256 12.4538 7.03539C12.5609 6.92823 12.7063 6.86803 12.8578 6.86803H14.5721C14.7237 6.86803 14.869 6.92823 14.9762 7.03539C15.0834 7.14256 15.1436 7.2879 15.1436 7.43945L15.1436 15.4395Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M9.42871 15.4395H6.57157L6.57157 9.15374C6.57157 9.00219 6.63177 8.85684 6.73894 8.74968C6.8461 8.64251 6.99144 8.58231 7.143 8.58231H8.85728C9.00883 8.58231 9.15418 8.64251 9.26134 8.74968C9.36851 8.85684 9.42871 9.00219 9.42871 9.15374V15.4395Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M3.71484 15.4395H0.857701L0.857701 10.868C0.857701 10.7165 0.917905 10.5711 1.02507 10.464C1.13223 10.3568 1.27758 10.2966 1.42913 10.2966H3.14342C3.29497 10.2966 3.44031 10.3568 3.54748 10.464C3.65464 10.5711 3.71484 10.7165 3.71484 10.868L3.71484 15.4395Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>

							<span className="text-1 left-nav-text">Charts</span>
						</Link>
					</li>
					<li className={location.pathname === '/mp3/new-release' ? 'active' : ''}>
						<Link to={`/mp3/new-release`}>
							<svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5.14314 8.57129H4.00028C3.3691 8.57129 2.85742 9.08296 2.85742 9.71415V14.2856C2.85742 14.9168 3.3691 15.4284 4.00028 15.4284H5.14314C5.77432 15.4284 6.28599 14.9168 6.28599 14.2856V9.71415C6.28599 9.08296 5.77432 8.57129 5.14314 8.57129Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M11.9996 8.57129H10.8567C10.2255 8.57129 9.71387 9.08296 9.71387 9.71415V14.2856C9.71387 14.9168 10.2255 15.4284 10.8567 15.4284H11.9996C12.6308 15.4284 13.1424 14.9168 13.1424 14.2856V9.71415C13.1424 9.08296 12.6308 8.57129 11.9996 8.57129Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M0.571289 10.857V7.99986C0.571289 6.02968 1.35394 4.14019 2.74707 2.74707C4.14019 1.35394 6.02968 0.571289 7.99986 0.571289C9.97004 0.571289 11.8595 1.35394 13.2527 2.74707C14.6458 4.14019 15.4284 6.02968 15.4284 7.99986V10.857" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>

							<span className="text-1 left-nav-text">New Releases</span>
						</Link>
					</li>
					<li className={location.pathname === '/mp3/genres/home' ? 'active' : ''}>
						<Link to={`/mp3/genres/home`}>
							<svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1.04009 14.3539L0.571514 6.35388C0.56177 6.27329 0.56931 6.19155 0.593631 6.1141C0.617952 6.03665 0.658495 5.96527 0.712561 5.90472C0.766628 5.84416 0.832975 5.79582 0.907186 5.76292C0.981396 5.73001 1.06177 5.7133 1.14294 5.71388H6.41151C6.53961 5.71467 6.66387 5.75764 6.7651 5.83614C6.86632 5.91464 6.93886 6.0243 6.97151 6.14817L7.42866 7.9996H14.8572C14.9363 7.99934 15.0145 8.01549 15.087 8.04703C15.1595 8.07856 15.2246 8.12479 15.2784 8.18279C15.3321 8.24079 15.3731 8.30931 15.399 8.384C15.4249 8.45869 15.435 8.53794 15.4287 8.61674L14.9829 14.331C14.9599 14.618 14.8294 14.8857 14.6175 15.0807C14.4056 15.2756 14.128 15.3834 13.8401 15.3825H2.18294C1.89864 15.3839 1.62401 15.2793 1.41268 15.0891C1.20136 14.8989 1.06851 14.6368 1.04009 14.3539Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M4 3.42843V1.14272C4 0.991165 4.0602 0.84582 4.16737 0.738657C4.27453 0.631493 4.41988 0.571289 4.57143 0.571289H14.2857C14.4373 0.571289 14.5826 0.631493 14.6898 0.738657C14.7969 0.84582 14.8571 0.991165 14.8571 1.14272V5.71415" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M8.57129 3.42871H11.9999" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>

							<span className="text-1 left-nav-text">Genres</span>
						</Link>
					</li>
					<li className={location.pathname === '/mp3/spotlight/home' ? 'active' : ''}>
						<Link to={`/mp3/spotlight/home`}>
							<svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M8.55976 1.24548L10.3769 4.91405C10.4182 5.00717 10.4832 5.08782 10.5655 5.14798C10.6477 5.20814 10.7442 5.2457 10.8455 5.25691L14.8569 5.8512C14.9731 5.86613 15.0826 5.91379 15.1726 5.98863C15.2627 6.06347 15.3296 6.16239 15.3656 6.27384C15.4015 6.38529 15.405 6.50467 15.3757 6.61803C15.3463 6.7314 15.2853 6.83407 15.1998 6.91405L12.3083 9.78263C12.2345 9.85157 12.1792 9.93787 12.1472 10.0337C12.1153 10.1295 12.1078 10.2318 12.1255 10.3312L12.8226 14.3655C12.8428 14.4814 12.83 14.6007 12.7858 14.7097C12.7415 14.8187 12.6676 14.9131 12.5723 14.9822C12.477 15.0513 12.3643 15.0922 12.2469 15.1004C12.1295 15.1085 12.0122 15.0836 11.9083 15.0283L8.2969 13.1198C8.20443 13.0744 8.10278 13.0508 7.99976 13.0508C7.89674 13.0508 7.79509 13.0744 7.70262 13.1198L4.09119 15.0283C3.98729 15.0836 3.86997 15.1085 3.75259 15.1004C3.63521 15.0922 3.52247 15.0513 3.4272 14.9822C3.33194 14.9131 3.25798 14.8187 3.21373 14.7097C3.16948 14.6007 3.15672 14.4814 3.1769 14.3655L3.87404 10.2855C3.89171 10.186 3.88424 10.0838 3.8523 9.98797C3.82037 9.89216 3.76498 9.80586 3.69119 9.73691L0.765473 6.91405C0.678916 6.83188 0.618044 6.72637 0.590232 6.6103C0.56242 6.49423 0.56886 6.37259 0.608773 6.26011C0.648685 6.14763 0.720358 6.04913 0.815108 5.97656C0.909859 5.90398 1.02362 5.86043 1.14262 5.8512L5.15404 5.25691C5.2553 5.2457 5.35185 5.20814 5.43406 5.14798C5.51628 5.08782 5.58129 5.00717 5.62262 4.91405L7.43976 1.24548C7.48924 1.13864 7.56826 1.04817 7.66749 0.984778C7.76672 0.921382 7.88201 0.887695 7.99976 0.887695C8.11751 0.887695 8.2328 0.921382 8.33203 0.984778C8.43125 1.04817 8.51027 1.13864 8.55976 1.24548V1.24548Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>

							<span className="text-1 left-nav-text">Curated Spotlights</span>
						</Link>
					</li>
					{/* <li>
						<a href="#">
							<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M15.4284 8.90262C15.4284 8.59952 15.308 8.30883 15.0937 8.0945C14.8794 7.88017 14.5887 7.75977 14.2856 7.75977H10.857V8.90262H5.14272V7.75977H1.71415C1.41104 7.75977 1.12035 7.88017 0.906024 8.0945C0.691697 8.30883 0.571289 8.59952 0.571289 8.90262V12.3312C0.571289 12.6343 0.691697 12.925 0.906024 13.1393C1.12035 13.3536 1.41104 13.4741 1.71415 13.4741H14.2856C14.5887 13.4741 14.8794 13.3536 15.0937 13.1393C15.308 12.925 15.4284 12.6343 15.4284 12.3312V8.90262Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M1.71387 4.33105H14.2853" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M1.71387 0.902344H14.2853" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>

							<span className="text-1">Mixes</span>
						</a>
					</li> */}
				</ul>
			</div>
			<div className="nav-link">
				<ul>
					{/* <li>
						<a href="#">
							<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M8.00003 13.2282L1.69146 7.51389C-1.73711 4.08532 3.30289 -2.49754 8.00003 2.82818C12.6972 -2.49754 17.7143 4.10818 14.3086 7.51389L8.00003 13.2282Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>

							<span className="text-1">Favorites</span>
						</a>
					</li> */}
					<li className={location.pathname === '/my-library/favourite' ? 'active' : ''}>
						<Link to={`/my-library/favourite`}>
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M15.9231 5H4.07692C3.48215 5 3 5.58203 3 6.3V16.7C3 17.418 3.48215 18 4.07692 18H15.9231C16.5178 18 17 17.418 17 16.7V6.3C17 5.58203 16.5178 5 15.9231 5Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M5 2H15" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M7.9348 14.9995C8.45108 14.9995 8.86961 14.581 8.86961 14.0647C8.86961 13.5484 8.45108 13.1299 7.9348 13.1299C7.41853 13.1299 7 13.5484 7 14.0647C7 14.581 7.41853 14.9995 7.9348 14.9995Z" stroke="#D7D7D7" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M12.1399 13.8315C12.6562 13.8315 13.0747 13.413 13.0747 12.8967C13.0747 12.3804 12.6562 11.9619 12.1399 11.9619C11.6236 11.9619 11.2051 12.3804 11.2051 12.8967C11.2051 13.413 11.6236 13.8315 12.1399 13.8315Z" stroke="#D7D7D7" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M13.0758 12.8968V9.23239C13.0756 9.19663 13.0672 9.16139 13.0512 9.12939C13.0352 9.09739 13.0121 9.06948 12.9837 9.04781C12.9552 9.02614 12.9222 9.01128 12.8871 9.00438C12.852 8.99749 12.8158 8.99874 12.7813 9.00804L9.04208 10.0457C8.99267 10.059 8.94899 10.0881 8.91774 10.1287C8.8865 10.1692 8.86943 10.2189 8.86914 10.27V14.0653" stroke="#D7D7D7" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M8.86914 11.4947L13.0758 10.3262" stroke="#D7D7D7" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>


						{ userProfile.is_premium_member && userProfile.is_premium_member == 1 ?  <span className="text-1 left-nav-text">My Library</span> : <span className="text-1 left-nav-text">Favorites</span> }
						</Link>
					</li>
					<li className={location.pathname === '/mp3/crates' ? 'active' : ''}>
						<Link to={`/mp3/crates`}>
							<svg width="14" height="13" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M4.8002 0.599609H11.2002C11.6245 0.599609 12.0315 0.740085 12.3316 0.990134C12.6316 1.24018 12.8002 1.57932 12.8002 1.93294V2.99961H3.2002V1.93294C3.2002 1.57932 3.36877 1.24018 3.66882 0.990134C3.96888 0.740085 4.37585 0.599609 4.8002 0.599609Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M14.2856 3H1.71415C1.08296 3 0.571289 3.51167 0.571289 4.14286V13.2857C0.571289 13.9169 1.08296 14.4286 1.71415 14.4286H14.2856C14.9168 14.4286 15.4284 13.9169 15.4284 13.2857V4.14286C15.4284 3.51167 14.9168 3 14.2856 3Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M4 7H12" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M4 10.4277H12" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
							<span className="text-1  left-nav-text">Crate</span>
						</Link>
					</li>
					<li className={location.pathname === '/trending-tracks/albums' ? 'active' : ''}>
						<Link to={`/trending-tracks/albums`}>
							<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M6.02849 0.605551C5.95939 0.564275 5.8804 0.54248 5.79991 0.54248C5.71943 0.54248 5.64044 0.564275 5.57134 0.605551C5.51435 0.65071 5.47589 0.715201 5.46325 0.786812C5.45061 0.858423 5.46468 0.932183 5.50277 0.994122C6.90849 3.42841 7.20563 6.74269 5.28563 8.57127C4.54692 7.95049 3.96045 7.16853 3.57134 6.28555C2.86132 6.67989 2.27289 7.26121 1.86993 7.96638C1.46698 8.67155 1.2649 9.47363 1.28563 10.2856C1.31461 11.0013 1.48647 11.704 1.79108 12.3524C2.0957 13.0007 2.52691 13.5816 3.05936 14.0608C3.59181 14.54 4.21473 14.9078 4.89146 15.1427C5.5682 15.3776 6.28508 15.4747 6.99991 15.4284C10.6799 15.4284 12.5885 13.1427 12.7142 10.2856C12.8628 6.85698 10.4285 2.63984 6.02849 0.605551Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M9.857 10.2856C9.857 10.8919 9.61619 11.4732 9.18753 11.9019C8.75888 12.3305 8.1775 12.5714 7.57129 12.5714" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
							<span className="text-1  left-nav-text">Weekly Trending</span>
						</Link>
					</li>
					
				</ul>
			</div>
			
			<div className="nav-link">
				<ul>
					<li>
						<a href="//www.clubkillers.com/updates/category/blog/" target="_blank">
							<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M15.4284 4.14286V11.5714C15.4284 11.9503 15.2779 12.3137 15.01 12.5816C14.7421 12.8495 14.3787 13 13.9999 13C13.621 13 13.2576 12.8495 12.9897 12.5816C12.7218 12.3137 12.5713 11.9503 12.5713 11.5714V1.57143C12.5713 1.41988 12.5111 1.27453 12.4039 1.16737C12.2968 1.0602 12.1514 1 11.9999 1H1.14272C0.991165 1 0.84582 1.0602 0.738657 1.16737C0.631493 1.27453 0.571289 1.41988 0.571289 1.57143V11.8571C0.571289 12.1602 0.691697 12.4509 0.906024 12.6653C1.12035 12.8796 1.41104 13 1.71415 13H13.9999" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M9.14286 3.85742H4V6.71456H9.14286V3.85742Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M4 10.1426H9.14286" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>

							<span className="text-1  left-nav-text">What’s New</span>
						</a>
					</li>
					<li>
						<a href="//gear.clubkillers.com/" target="_blank">
							<svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.1427 6.28516H2.857C1.59464 6.28516 0.571289 7.30851 0.571289 8.57087V13.1423C0.571289 14.4047 1.59464 15.428 2.857 15.428H13.1427C14.4051 15.428 15.4284 14.4047 15.4284 13.1423V8.57087C15.4284 7.30851 14.4051 6.28516 13.1427 6.28516Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M4.57129 6.28557L7.99986 0.571289L11.4284 6.28557" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>


							<span className="text-1 left-nav-text">CK’s Store</span>
						</a>
					</li>
				</ul>
			</div>
			
			<div className="theme-mode">
				<div className="inner-mode">
					<div className={themeType == 'light-theme' ? "mode active" : 'mode'} onClick={()=> changeMode('dark')}>
						<svg width="14" height="13" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7.50014 7.57059C7.81573 7.57059 8.07157 7.31475 8.07157 6.99916C8.07157 6.68357 7.81573 6.42773 7.50014 6.42773C7.18455 6.42773 6.92871 6.68357 6.92871 6.99916C6.92871 7.31475 7.18455 7.57059 7.50014 7.57059Z" stroke="#828282" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M7.50098 13.2854V12.1426" stroke="#828282" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M11.9462 11.4452L11.1348 10.6338" stroke="#828282" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M13.7864 7H12.6436" stroke="#828282" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M11.9462 2.55371L11.1348 3.36514" stroke="#828282" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M7.50098 0.713867V1.85672" stroke="#828282" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M3.05469 2.55371L3.86612 3.36514" stroke="#828282" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M1.21484 7H2.3577" stroke="#828282" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M3.05469 11.4452L3.86612 10.6338" stroke="#828282" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
						<span className="text-5 left-nav-text">Light</span>
					</div>
					<div className={themeType == 'dark-theme' ? "mode active" : 'mode'} onClick={()=> changeMode('light')}>
						<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M16.2142 13.977C14.8866 13.9689 13.585 13.6078 12.443 12.9309C11.3009 12.2539 10.3594 11.2854 9.71503 10.1246C9.07067 8.9638 8.74661 7.65253 8.77609 6.32522C8.80557 4.99791 9.18752 3.70232 9.88278 2.57129C8.05098 2.89287 6.40449 3.88482 5.26403 5.35392C4.12357 6.82302 3.57083 8.66405 3.71347 10.5184C3.85611 12.3727 4.68392 14.1075 6.03565 15.3849C7.38737 16.6623 9.16621 17.3908 11.0256 17.4284C12.2978 17.4317 13.5492 17.1062 14.6585 16.4834C15.7678 15.8607 16.6974 14.9619 17.3571 13.8741C16.9792 13.9366 16.5972 13.971 16.2142 13.977V13.977Z" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
						<span className="text-5 left-nav-text">Dark</span>
					</div>
				</div>
			</div>
			
		</div>
    );
  
}


function mapStateToProps(state) {
	return {
		themeType: state.theme.theme,
		userProfile: state.customer.customerProfile,
		isLeftmenuExpand: state.common.isLeftmenuExpand
	};
 }
 
 function mapDispatchToProps(dispatch) {
	return {
		changeTheme: (data) => dispatch(themeAction.changeTheme(data))
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(LeftNav);
