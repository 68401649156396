import * as types from './type';

import { Dropbox }  from  '../../services/dropbox';

export function addRemoveToDropBox(data) {
    return {
      type: types.ADD_OR_REMOVE_TO_DROPBOX,
      payload: Dropbox.addRemoveToDropBox(data),
    };
};

export function removeAllFromDropBox(){
  return {
    type: types.REMOVE_ALL,
    payload: Dropbox.removeAllFromDropBox(),
  };
}

export function downloadAllFromDropBox(data){
  return {
    type: types.DOWNLOAD_ALL,
    payload: Dropbox.addRemoveToDropBox(data),
  };
}

export function enableDisableDropBox(bool){
  return {
    type: types.DROPBOX_MODE_ENABLED,
    payload: bool
  }
}

export function getDropboxSongs(){
  return {
    type: types.GET_DROPBOX_SONGS,
    payload: Dropbox.getDropboxSongs(),
  }
}

export function uploadFilesOnDropBox(){
  return {
    type: types.UPLOAD_SONGS_TO_DROPBOX,
    payload: Dropbox.uploadFilesOnDropBox(),
  }
}

export function addSongEntityToDropBox(data){
  return {
    type: types.ADD_SONG_ENTITY_TO_DROPBOX,
    payload: Dropbox.addSongEntityToDropBox(data),
  }
}
