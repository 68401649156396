import * as types from './type';

const initialState = {
    playIndex: 0
};

export default function playindexReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_PLAY_INDEX:{
            if (action.payload && action.payload) {
                return {
                    playIndex:  action.payload
                }
            }
        }
        case types.GET_PLAY_INDEX: {
            return {
               playIndex: [...state.playIndex]
            }
        }
        default: {
            return {
              ...state
            };
          }
    }
}