import * as types from './type';

const initialState = {
  activeDownloadBtn: 'download'
};



export default function activeDownloadBtnReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.SET_ACTIVE_DOWNLOAD_BTN: {
        return {
          ...state,
          activeDownloadBtn: action.payload,
      }
    }

    default: {
      return {
        ...state
      };
    }
    
  }

}