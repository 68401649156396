import React, {useState, useEffect} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import SubmitTicket from '../../components/helpdesk/submitTicket';
import RecentTickets from '../../components/helpdesk/recentTickets';
import TicketSummary from '../../components/helpdesk/ticketSummary';
import {connect} from 'react-redux';

const  HelpdeskIndex = ({ recentTickets }) => {
  
    

  return (
    <MusicDashboard  accountPages={true} middleSectionCssClass={'account-info-sec'}>
       	<div className="content-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>Helpdesk</h2>
                </div>
                <AccountNav />
                <div className="account-inner-block">
                    <div className="ticket-sec">
                        <div className="title-heading">
                            <h3>Tickets</h3>
                        </div>
                        <div className="inner-ticket-block">
                            <div className="left">
                                <TicketSummary />
                                <h3>Submit a ticket</h3>
                                <p>Choose the correct department to submit your ticket. Ticket are answered as soon as possible. If you have not heard back in 24 hours please revisit the support as you may not have not received the email notification.</p>
                                <SubmitTicket />
                            </div>
                            <RecentTickets />
                        </div>
                    </div>
                </div>
            </div>
		</div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
    return {
        recentTickets: state.helpdesk.recentTickets
    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpdeskIndex);