import * as types from './type';

let user = localStorage.getItem('user');

const initialState = user && user != 'undefined' ? { loggedIn: true, user } : { loggedIn: false };


export default function authentication(state = initialState, action) {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            if(action.payload){
                if(action.payload && action.payload.data){
                    return {
                        ...state,
                        loggingIn: true,
                        user: action.payload.data.token
                    };
                }
            } else {
                return {
                ...state
                }
            }
        // case types.LOGIN_SUCCESS:
        //     return {
        //         loggedIn: true,
        //         user: action.user
        //     };
        // case types.LOGIN_FAILURE:
        //     return {};
        case types.LOGOUT:
            return {};
        default:
            
            return state
    }
}