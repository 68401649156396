import * as types from './type';

const initialState = {
    homeGenreData: '',
    homeNewRelease: '',
    top20Albums: '',
    customerCurates: [],
    currentSong: '',
    renewSong: '',
    createSong: '',
    editSongEntity: '',
    updateSongEntity: '',
    deleteSucces: '',
    searchResult: '',
    chartData:'',
    favouriteSongs: '',
    customerFavouriteSongs: '',
    playerSong: ''
};



export default function songReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_HOME_GENRE_SONG: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          homeGenreData: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.GET_NEW_RELEASE_SONG: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          homeNewRelease: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    case types.GET_TOP_20_ALBUMS: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          top20Albums: action.payload.data.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    case types.ADD_SONG_TO_CRATE: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerCurates: action.payload.data.crates
        }
      } else {
        return {
          ...state
        }
      }
    }

    case types.ADD_SONG_TO_FAVOURITE: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          favouriteSongs: action.payload.data.favouriteSongs
        }
      } else {
        return {
          ...state
        }
      }
    }

    case types.GET_CUSTOMER_FAVOURITE_SONGS: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerFavouriteSongs: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    case types.GET_FAVOURITE_SONGS: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          favouriteSongs: action.payload.data.favouriteSongs
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.CUSTOMER_CRATES: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerCurates: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
        
    case types.RENEW_SONG: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          renewSong: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    case types.SET_CURRENT_SONG: {
        return {
            ...state,
            currentSong: action.payload
        }
    }
    case types.SET_PLAYER_SONGS: {
      return {
          ...state,
          playerSong: action.payload
      }
  }

    case types.CREATE_NEW_SONG: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          createSong: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.EDIT_SONG_ENTITY: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          editSongEntity: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.UPDATE_SONG_ENTITY: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          updateSongEntity: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    case types.DELETE_SONG_ENTITY: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          deleteSucces: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    case types.MP3_SEARCH_RESULT: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          searchResult: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.CHART_SONGS: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          chartData: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }


    default: {
      return {
        ...state
      };
    }
    
  }

}