import * as types from './type';


export function setPlayerInstanceGlobal(player) {
    return {
      type: types.SET_PLAYER_INSTANCE,
      payload: player,
    };
};

export function getPlayerInstance(){
  return {
    type: types.GET_PLAYER_INSTANCE
  }
}

