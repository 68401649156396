import * as types from './type';


export function setPlayIndex(playIndex) {
    return {
      type: types.SET_PLAY_INDEX,
      payload: playIndex,
    };
};

export function getPlayIndex(){
  return {
    type: types.GET_PLAY_INDEX
  }
}

