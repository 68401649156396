import React , { useEffect } from "react";

import {connect} from 'react-redux';
import * as commonActions from '../../store/common/actions';

const Announcement = ({page_name, getAnnouncement, announcement, hideAnnouncement}) =>  {

  
 
  useEffect(() => { 
    // Get Announcement
    getAnnouncement({page_name: page_name});
    
  },[])
     
  const markReadMessage = (messageId) => {
		hideAnnouncement({message_id: messageId, page_name: 'mp3_home'});
	}
  
  return(
          announcement && announcement.length > 0 ? 
            announcement.map((item, index) => {
              return <div className="announcement-bar position-relative" key={`announcement-${index}`}> 
                <div dangerouslySetInnerHTML={{__html: item.message}}></div>
                <span className="close" onClick={()=> markReadMessage(item.message_id)}>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L9 9M9 1L1 9" stroke="black" strokeLinecap="round"/>
                    </svg>
                </span>
              </div>
            })
          : ''
      )
  
}


function mapStateToProps(state) {
  return {
    announcement: state.common.announcement
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAnnouncement:(data) => dispatch(commonActions.getAnnouncement(data)),
	  hideAnnouncement:(data) => dispatch(commonActions.hideAnnouncement(data))
  };
 }
export default connect(mapStateToProps, mapDispatchToProps)(Announcement);