import React, { useState, useEffect } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";

import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import RightSide from "../../components/rightSide/rightSide";
import Pagination from "react-js-pagination";

import MusicDashboard from '../../layouts/musicDashboard';
import ImageLoader from '../../components/loader/imageLoader';

import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import * as actions from '../../store/curated/actions';



const  CuratedSpotlightInedx = ({ loggingIn, getCuratedAlbums, curatedAlbums }) => {

    const navigate = useNavigate();
    const [ page, setPage ] = useState(1);
    const [dataProcessing, setDataProcessing] = useState();
    
    useEffect(() => { 
		if(loggingIn !== undefined && loggingIn === false){
			navigate('/sign-in');
		} else {
			loadData()
		}
	},[])

	const loadData = async() => {
		await getCuratedAlbums({page: page, pageLimit: 35});
    }
    
    const handlePageChange = async (pageNumber) => {
		setPage(pageNumber);
		await getCuratedAlbums({page: pageNumber, pageLimit: 35});
	}

	return (
			<MusicDashboard rightContent={<RightSide blocks={{curatedFilter: true }} />}>
			    <div className="content-sec">
                    <div className="most-download">
                        <CuratedSpotlightCarousel />
                    </div>
                    <div className="latest-spotlights">
                        <div className="top-heading">
                            <h2>Latest Spotlight</h2>
                        </div>
                        <div className="latest-spot-block">
                        {
                            curatedAlbums && curatedAlbums.curatedlist && curatedAlbums.curatedlist.length > 0 ?
                                curatedAlbums.curatedlist.map((curatedSpotlight, index) => {
                                        return <Link className="box" key={`newreleasespotlight${curatedSpotlight}`} to={`/mp3/spotlight/detail/${curatedSpotlight.curated_list_id}`}>
                                                    <img src={curatedSpotlight.image ? curatedSpotlight.image : ''} />
                                                    <div className="latest-info"> <h5>{curatedSpotlight.title}</h5></div>            
                                            </Link>
                                })
                            : ''
                        }
                        
                        </div>
                    </div>
                    <div className="pagingnation-sec">
                        {
                        curatedAlbums && curatedAlbums.pagination ?
                            <Pagination
                                activePage={curatedAlbums.pagination.page}
                                itemsCountPerPage={curatedAlbums.pagination.perPageLimit}
                                totalItemsCount={curatedAlbums.pagination.totalCount}
                                // pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                        /> : <ImageLoader itemCount={35} />
                        }
                    </div>
                </div>
            </MusicDashboard>
        );
}

function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        curatedAlbums: state.curated.curatedAlbums
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
		getCuratedAlbums: (data) => dispatch(actions.getCuratedAlbums(data)),
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(CuratedSpotlightInedx);
