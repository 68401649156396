import React, { useState, useEffect } from "react";

import {connect} from 'react-redux';
import * as actions from '../../store/song/actions';
import Spinner from 'react-bootstrap/Spinner';

const FavouriteButton = ({ song, addSongToFavourite, getCustomerFavouriteSongs, favouriteSongs, themeType }) => {

    const [ downloadInProcess, setDownloadInProcess ] = useState(false);
    const [ customerFavSongs, setCustomerFavSongs ] = useState(favouriteSongs);
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");

    useEffect(() => { 
        setCustomerFavSongs(favouriteSongs);
   	},[favouriteSongs])

    const addRemoveSongToFavourite = async(song) => {
        setDownloadInProcess(true);
        await addSongToFavourite(song.entity_id);
        await  getCustomerFavouriteSongs();
        setDownloadInProcess(false)
    }
    
    if(downloadInProcess){
        return <Spinner animation="border" variant="danger" size="sm" />
    } else {
        return (
            
            <span onClick={() => addRemoveSongToFavourite(song)} className="cp" title="Add to Favourite">
                <svg width="16" height="14" viewBox="0 0 16 14" fill={customerFavSongs && customerFavSongs && customerFavSongs.length > 0 && ((customerFavSongs.indexOf(song.entity_id.toString()) > -1) ) ? '#EB4E27' : 'none'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.00003 13.2282L1.69146 7.51389C-1.73711 4.08532 3.30289 -2.49754 8.00003 2.82818C12.6972 -2.49754 17.7143 4.10818 14.3086 7.51389L8.00003 13.2282Z" stroke={themeType == 'dark-theme' ? "#D7D7D7" : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>
        )
    }
}
function mapStateToProps(state) {
    return {
        favouriteSongs: state.song.favouriteSongs,
        themeType: state.theme.theme,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        addSongToFavourite: (songId) => dispatch(actions.addSongToFavourite(songId)),
        getCustomerFavouriteSongs: () => dispatch(actions.getCustomerFavouriteSongs()),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FavouriteButton);
  