import React, { useEffect, useState} from "react";

import Slider from "react-slick";
import Spinner from 'react-bootstrap/Spinner';
import ImageSliderLoader from "../../components/loader/imageSliderLoader";

import {connect} from 'react-redux';
import { Link } from "react-router-dom";

const  GenresMoods = ({ genresWithCount }) => {
    const settings = {
		className: "slider",
		dots: false,
		infinite: false,
		centerMode: false,
		slidesToShow: 7,
		slidesToScroll: 7,
		// variableWidth: true,
		arrows: true
	};

  return (
        <div className="slider-sec">
            <div className="top-heading">
                <h2>Moods</h2>
            </div>
            {
               genresWithCount && genresWithCount.length > 0 ?
                    <Slider {...settings}>
                        {
                            genresWithCount.map((genre, index) => {
                                return <div key={`genresimage-${genre.genre_id}`} className="item">
                                    <div className="box">
                                    <Link to={`/mp3/genres/${genre.genre_id}`}><img src={genre.image} alt={genre.name} title={genre.name} /></Link>
                                    <Link to={`/mp3/genres/${genre.genre_id}`}>	<h5 dangerouslySetInnerHTML={{__html: genre.name}}></h5> </Link>
                                    </div>
                                </div>
                            })
                        }
                    </Slider>
                : <ImageSliderLoader itemCount={8} />
            }
            
        </div>
  )
}
function mapStateToProps(state) {
    return {
      genresWithCount: state.genre.genresWithCount,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(GenresMoods);