import React, {useState, useEffect} from "react";
import "./mp3Modal.scss";
import Spinner from 'react-bootstrap/Spinner';
import {  useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {connect} from 'react-redux';
import closeImage from '../../assets/images/close.svg';
import * as dropboxAction from '../../store/dropbox/actions';

const DropboxItemsModal = ({getDropboxSongs, ShowFunction, dropbox, removeAllFromDropBox, userProfile  }) =>  {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const [uploadProcess, setUploadProcess ] = useState(false);
    const [scriptLoadingStarted, setScriptLoadingStarted] =  useState(false);
   
    const closeModel = () => {
        ShowFunction();
    }
    const geTDropBoxFiles = () => {
        if(dropbox && dropbox.items && dropbox.items.length > 0){
            let files = []
            for(let i=0; i < dropbox.items.length; i++){
                files.push({'url': dropbox.items[i].version.mp3, 'filename': dropbox.items[i].version.uploaded_mp3_name})
            }
            return files;
        }
    }

    const canDownload = () => {
        if(userProfile && userProfile.group_id == 2){
            if(userProfile.payment_profile_status == 'paid' && userProfile.profile_status == 'ACTIVE'){
                return true;
            } else if(userProfile.payment_profile_status == 'unpaid'){
                return false;
            } else if(userProfile.payment_profile_status == "pending"){
                MySwal.fire({
                    title: "Payment under processing",
                    text: "Your payment is under processing. You can add song to your crate.",
                    type: "info",
                    allowOutsideClick: false,
                });
            } else if(userProfile.payment_profile_status == 'canceled' && userProfile.profile_status == 'Cancelled'){
                var d1 = new Date();
                var d2 = new Date(userProfile.cancel_download_date);
                var same = d1.getTime() === d2.getTime();
                // console.log('checked', userProfile.cancel_download_date)
                if(d2.getTime() > d1.getTime()){
                    return true;
                } else {
                    return false;
                }
            }
        } else if(userProfile && userProfile.group_id == 3){
            
            var d1 = new Date();
            var d2 = new Date(userProfile.staff_membership_expire_date);
            if(d2.getTime() > d1.getTime()){
               return true;
            } else {
                return false;
            }
            
        }
    }
    
    const uploadSongsToDrive = async () => {
        if(canDownload()){
            const filesToUpload =  geTDropBoxFiles();
            if(window.Dropbox){
                var options = {
                    files: filesToUpload,
                
                success: function () {
                        // alert("Success! Files saved to your Dropbox.");
                        setUploadProcess(false);
                        removeAllFromDropBox().then(res => {
                            getDropboxSongs();
                        });
                        closeModel();
                    },
                
                    
                    progress: function (progress) {
                        setUploadProcess(true);
                    },
                
                    cancel: function () {},
                
                    error: function (errorMessage) {
                        alert('Something went wrong')
                    }
                }
                window.Dropbox.save(options);
            }
        } else {
            closeModel();
            navigate('/subscription/plans');
        }
    }
    
    const clearDropbox = () => {
        setUploadProcess(true);
        removeAllFromDropBox().then(res => {
            getDropboxSongs();
        });
        setUploadProcess(false);
        closeModel();
    }
    
        return(
                <div id="dropbox-btn" className="show">
					<div id="db-close-bg"></div>
                    <h2><span>{dropbox && dropbox.totalDropbox  > 0 ? dropbox.totalDropbox : '0' } tracks</span> ready to download </h2>
                    <div className="button-container">
                        {
                            uploadProcess ? <a href="#" className=" dropbox-dropin-btnn "><Spinner animation="border" variant="dark" size="sm" /></a>
                            :
                                <a href="#" className=" dropbox-dropin-btnn " onClick={() => uploadSongsToDrive()}>	
                                    <svg width="24" height="25" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.5 6.16667L5.66667 8.825L9.83333 6.16667L5.66667 3.5L1.5 6.16667ZM9.83333 6.16667L14 8.825L18.1667 6.16667L14 3.5L9.83333 6.16667ZM1.5 11.4583L5.66667 14.1167L9.83333 11.4583L5.66667 8.79167L1.5 11.4583ZM14 8.79167L9.83333 11.4583L14 14.1167L18.1667 11.4583L14 8.79167ZM5.69167 15L9.86667 17.6667L14.0333 15L9.86667 12.3417L5.69167 15Z" fill="#fff" />
                                    </svg> 
                                    Save to Dropbox
                                </a>
                        }
                    </div>
                    <div className="db-secondary-btns">
                        <a href="javascript:void(0);" onClick={() => clearDropbox()}>
                            <i className="fa fa-trash"></i> Clear Dropbox Queue
                        </a> 
                        <a href="javascript:void(0);" className=" db-close" onClick={() => ShowFunction()}><img src={closeImage} />Close</a>
                    </div>
                </div>
       
        )  
        
}

function mapStateToProps(state) {
  return {
    dropbox: state.dropbox.dropboxSongs.dropbox,
    userProfile: state.customer.customerProfile,
  };
}
 
function mapDispatchToProps(dispatch) {
  return {
    getDropboxSongs: () => dispatch(dropboxAction.getDropboxSongs()),
    removeAllFromDropBox: () => dispatch(dropboxAction.removeAllFromDropBox()), 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DropboxItemsModal);