import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const Common = {
    getCountryList,
    getAnnouncement,
    hideAnnouncement,
    applyBooking,
    getPageContent
}

function getCountryList(){
    const url = 'common/get-country-list.json?store_id=1&lang_code=en';
    return axiosReqGet()
            .get(url)
            .then(res => {
            return res; 
        })
        .catch(err => { 
            alert(err.response.data.message)      
            return err; 
        })
}


function getAnnouncement(data){
    const url = 'common/announcement.json?store_id=1&lang_code=en';
    data.token =  USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function hideAnnouncement(data){
    const url = 'common/hide-announcement.json?store_id=1&lang_code=en';
    data.token =  USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function applyBooking(data){
    const url = 'common/apply-bookings.json?store_id=1&lang_code=en';
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}


function getPageContent(data){
    const url = 'common/get-page.json?store_id=1&lang_code=en&page='+data.page;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}