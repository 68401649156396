import * as types from './type';

import { Common }  from  '../../services/common';

export function getCountryList() {
    return {
      type: types.GET_COUNTRY_LIST,
      payload: Common.getCountryList(),
    };
};

export function getAnnouncement(data) {
  return {
    type: types.GET_ANNOUNCEMENT,
    payload: Common.getAnnouncement(data),
  };
};


export function hideAnnouncement(data) {
  return {
    type: types.HIDE_ANNOUNCEMENT,
    payload: Common.hideAnnouncement(data),
  };
};

export function applyBooking(data) {
  return {
    type: types.BOOKING_REQUEST,
    payload: Common.applyBooking(data),
  };
};

export function getPageContent(data) {
  return {
    type: types.PAGE_CONTENT_REQUEST,
    payload: Common.getPageContent(data),
  };
};

export function setLeftMenuDisplay(data) {
  return {
    type: types.SET_LEFT_MENU_DISPLAY,
    payload: data,
  };
}; 