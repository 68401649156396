import React, {useEffect, useState} from "react";


import {connect} from 'react-redux';
import { Link } from 'react-router-dom';


const TicketSummary = ({  recentTickets }) => {
    
    return (
        <div className="ticket-query">
            <div className="box">
                <Link to={`/helpdesk/tickets/open`} className="text-5">
                    <span>{recentTickets && recentTickets.summary ? recentTickets.summary.total : 0}</span>
                    <h5>Your Ticket Inquries</h5>
                </Link>
            </div>
            <div className="box">
                <Link to={`/helpdesk/tickets/open`}>
                    <span>{recentTickets && recentTickets.summary ? recentTickets.summary.open : 0}</span>
                    <h5>Open Ticket</h5>
                </Link>
            </div>
            <div className="box">
                <Link to={`/helpdesk/tickets/close`}>
                    <span>{recentTickets && recentTickets.summary ? recentTickets.summary.close : 0}</span>
                    <h5>Close Ticket</h5>
                </Link>
            </div>
        </div>                       
    )
}
function mapStateToProps(state) {
    return {
        recentTickets: state.helpdesk.recentTickets
    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(TicketSummary);