import React, {useEffect, useState} from "react";

//custom components

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import MusicDashboard from "../../layouts/musicDashboard";
import AccountNav from '../../elements/accountElements/accountNav';
import Table from 'react-bootstrap/Table';
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import { useNavigate } from "react-router-dom";
import * as uploadsAction from '../../store/uploads/actions';
import * as songAction from '../../store/song/actions';

const UploadsList = ({songLists, getUploadedSongs, displayLoading, hideLoading, renewSong, deleteSong, getSearchResultSongs, userProfile}) => {
    const navigate = useNavigate();
    const [ page, setPage ] = useState(1);
    const [ order, setOrder ] = useState('entity_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [deleteEntityId, setDeleteEntityId ] = useState();
    const [ searchTerm, setSearchTerm ] = useState();
    const MySwal = withReactContent(Swal)
    const [ processingSearch, setProcessingSearch ] = useState(false);
  
    useEffect(() => { 
        // load Page Data
        if(userProfile && userProfile.user_song && userProfile.user_song == 1){
            loadPageData();
        } else {
            navigate(`/profile/update`);
        }
    },[]);
    
    const loadPageData = async() => {
        displayLoading();
        await getUploadedSongs({song_sectoin_id: 1,  page: 1, pageLimit: 20, orderDir: orderDir, order: order});
        hideLoading();
    }
  
    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        displayLoading();
        await getUploadedSongs({song_sectoin_id: 1,  page: pageNumber, pageLimit: 20, orderDir: orderDir, order: order, searchTerm: searchTerm});
        hideLoading();
    }
  
    /**
     * Method: renewSong
     */
    const makeSongRenew = async (entity_id, event) => {
          const renewSongConfirm = window.confirm('Are you sure you want to renew this song?');
          if(renewSongConfirm){
              displayLoading();
              await renewSong({entity_id: entity_id, page: 0, pageLimit: 20});
              await getUploadedSongs({song_sectoin_id: 1,  page: 1, pageLimit: 20, orderDir: orderDir, order: order});
              hideLoading();
          }
    }

    const confirmDelete = (entity_id) => {
       
        var entity_id = entity_id;
        setDeleteEntityId(entity_id);
        MySwal.fire({
            title: "Are you sure?",
            text: "You want to remove the song uploaded?",
            type: "info",
            showCancelButton: true,
            confirmButtonClass: "btn-danger",
            confirmButtonText: "Yes, delete it!",
            closeOnConfirm: false,
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
        }).then(res => {
            if(res.value){
                deleteSongEntity(entity_id);
            }
        });
    }
  
    const deleteSongEntity = async(entity_id) => {
        displayLoading();
        await deleteSong({entity_id: entity_id}).then(res => {
    
        });
        await getUploadedSongs({song_sectoin_id: 1,  page: 1, pageLimit: 20, orderDir: orderDir, order: order});
        hideLoading();
    }
  
    const handleChangeSearch = (e) => {
        const {name, value } = e.target;
        setSearchTerm(value); 
    }
    
    const searchSong = () => {
        setProcessingSearch(true);
        if(searchTerm){
          displayLoading();
          getUploadedSongs({page: 1, order: order, orderDir, song_section_id: 1, searchTerm: searchTerm}).then((res) => {
            hideLoading();
          })
        }
        setProcessingSearch(false);
    }
    
  
    return (
        <MusicDashboard accountPages={true} middleSectionCssClass={"account-info-sec"}>
            <div className="content-sec">
                <div className="account-block">
                    <div className="top-heading">
                        <h2>Account Setting</h2>
                    </div>
                    <AccountNav/> 
                    <div className="account-inner-block">
                        <div className="billing-history">
                            <div className="title-heading">
                                <h3>Uploads</h3>
                            </div>
                            <div className="top-actions">
                                <div className="search-items form-group">
                                    <input type="text" placeholder="Search Song" name="search" onChange={handleChangeSearch} className="search-input form-control" />
                                    { processingSearch ? <button className="secondary-btn"><Spinner animation="border" variant="dark" size="sm" /></button> : <button className="secondary-btn" onClick={searchSong}>Go</button> }
                                </div>
                                <div className="">
                                    <Link to={`/uploads/new`} className="btn primary-btn" style={{height: '35px'}}>Add New</Link>
                                </div>
                            </div>
                               
                            <div className="table-content">
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                        <th>Title</th>
                                        <th>Artist</th>
                                        <th>Corrupt Versions</th>
                                        <th>Action</th>
                                        </tr>
                                    </thead>
                                    {
                                        songLists && songLists.data && songLists.data.length > 0 ?
                                        <tbody>
                                        {
                                            songLists.data.map((item, index)=>{
                                            return <tr key={`uploadlist${index}`}>
                                                <td>{item.title}</td>
                                                <td>{item.artist}</td>
                                                <td>{item.corrupt_version}</td>
                                                <td className="text-uppercase">	
                                                    <span  onClick={()=> makeSongRenew(item.entity_id)}>Renew</span> &nbsp;
                                                    <Link to={`/uploads/edit-song/${item.entity_id}`}>Edit</Link> &nbsp;
                                                    <span onClick={()=> confirmDelete(item.entity_id)}>Delete</span>
                                                </td>
                                            </tr>
                                            })
                                        }	
                                        
                                        </tbody>
                                        : ''
                                    }
                                    {
                                        songLists && songLists.length == 0 ?
                                            <tbody><tr><td>You have no billing details.</td></tr></tbody>
                                        :
                                        ''
                                    }
                                </Table>
                            </div>
                            <div className="pagingnation-sec">
                            {
                                songLists && songLists.pagination ? 
                                    <Pagination
                                        activePage={songLists.pagination.page}
                                        itemsCountPerPage={songLists.pagination.perPageLimit}
                                        totalItemsCount={songLists.pagination.totalCount}
                                        // pageRangeDisplayed={pageRangeDisplayed}
                                        onChange={handlePageChange}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                /> : ''
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MusicDashboard>
    );
  }
  function mapStateToProps(state) {
    return {
        songLists: state.uploads.songList,
        userProfile: state.customer.customerProfile,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        getUploadedSongs: (post) => dispatch(uploadsAction.getUploadedSongs(post)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        renewSong: (post) => dispatch(songAction.renewSong(post)),
        deleteSong: (post) => dispatch(songAction.deleteSong(post))
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(UploadsList);