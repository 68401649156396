import React, {useState, useEffect} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import RecentTickets from '../../components/helpdesk/recentTickets';
import TicketSummary from '../../components/helpdesk/ticketSummary';
import { Link, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Pagination from "react-js-pagination";
import {connect} from 'react-redux';
import * as helpdeskAction from '../../store/helpdesk/actions';

const  HelpdeskTickets = ({ getTickets, tickets }) => {
  
    const [ page, setPage ] = useState();
    const [ status, setStatus ] = useState();
    const location = useLocation();
    
    useEffect(() => { 
		// load Page Data
		loadPageData();
    },[location]);


    const loadPageData = async() => {
        let ticketStatus = 'all';
        if(location.pathname == '/helpdesk/tickets/open'){
            ticketStatus = 'open';
        } else if(location.pathname == '/helpdesk/tickets/close'){
            ticketStatus = 'close';
        }
        console.log(ticketStatus)
        setStatus(ticketStatus);
        await getTickets({page: 1, pageLimit: 10, status: ticketStatus});
    }
    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        await getTickets({page: pageNumber, pageLimit: 10, status: status}).then((res) => {});
    }
    

  return (
    <MusicDashboard  accountPages={true} middleSectionCssClass={'account-info-sec full-section'}>
       	<div className="content-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>Helpdesk</h2>
                </div>
                <AccountNav />
                <div className="account-inner-block">
                    <div className="ticket-sec">
                        <div className="title-heading">
                            <h3>Tickets</h3>
                        </div>
                        <div className="inner-ticket-block">
                            <div className="left">
                                <TicketSummary />
                                <div className="title-heading">
                            <h3>Tickets</h3>
                        </div>
                                <div className="table-content">
								<Table hover responsive>
									<thead>
                                        <tr>
										<th>#Ticet</th>
                                            <th>Last Updated</th>
                                            <th>Subject</th>
                                            <th>&nbsp;</th>
									</tr>
                                    </thead>
                                    {
                                        tickets && tickets.tickets && tickets.tickets.length > 0 ?
                                        <tbody>
                                            {
                                                tickets.tickets.map((ticket, index)=>{
                                                    return <tr key={`ticket-list${index}`}>
                                                            <td>{ticket.ticket_number}</td>
                                                            <td>{ticket.modified_at}</td>
                                                            <td>{ticket.subject}</td>
                                                            <td><Link to={`/helpdesk/ticket/details/${ticket.ticket_number}`}>View</Link></td>
                                                    </tr>
                                                })
                                            }	
                                            
                                        </tbody>
                                        : ''
                                    }
                                </Table>
							</div>
                                {/* <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>#Ticet</th>
                                            <th>Last Updated</th>
                                            <th>Subject</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    {
                                        tickets && tickets.tickets && tickets.tickets.length > 0 ?
                                        <tbody>
                                            {
                                                tickets.tickets.map((ticket, index)=>{
                                                    return <tr key={`ticket-list${index}`}>
                                                            <td>{ticket.ticket_number}</td>
                                                            <td>{ticket.modified_at}</td>
                                                            <td>{ticket.subject}</td>
                                                            <td><Link to={`/helpdesk/ticket/details/${ticket.ticket_number}`}>View</Link></td>
                                                    </tr>
                                                })
                                            }	
                                            
                                        </tbody>
                                        : ''
                                    }
                                </Table> */}
                            </div>
                            <RecentTickets />
                        </div>
                        <div className="pagingnation-sec">
                        {
                        tickets && tickets.pagination ? 
                            <Pagination
                                activePage={tickets.pagination.page}
                                itemsCountPerPage={tickets.pagination.perPageLimit}
                                totalItemsCount={tickets.pagination.totalCount}
                                // pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                        /> : ''
                        }
                        </div>
                    </div>
                </div>
            </div>
		</div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
    return {
        tickets: state.helpdesk.tickets
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTickets: (post) => dispatch(helpdeskAction.getTickets(post))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpdeskTickets);