import * as types from './type';


export function setIsPlaying(isPlaying) {
    return {
      type: types.SET_IS_PLAYING,
      payload: isPlaying,
    };
};

export function getIsPlaying(){
  return {
    type: types.GET_IS_PLAYING
  }
}

