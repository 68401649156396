import React, {useState, useEffect} from "react";

import SinglePageLayout from "../../layouts/singlePageLayout/singlePageLayout";
import {connect} from 'react-redux';
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
// import { useFormInputValidation } from "react-form-input-validation";
import InputGroup from 'react-bootstrap/InputGroup'

import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import logo from './../../assets/images/Logo.svg';

import * as actions from '../../store/customer/actions';

const RecoverPassword = ({ forgetPassword  }) => {
	const navigate = useNavigate();
	const [validated, setValidated] = useState(false);
	const [formData, setFormData] = useState({
		email: ''
	});
	const [processing, setProcessing] = useState(false);
	const [forgetErr, setForgetErr] = useState();
	const [successMessage, setSuccessMessage] = useState(null);
	// useEffect(() => { 
	//   console.log('his')
	//   document.getElementById("forgetPasswordForm").reset();
	// },[showState])
  
	
  
	const handleChange = (e) => {
	  const { name, value } = e.target;
	  setFormData({
		  ...formData,
		  [name]: value
	  });
	}
  
	const handleSubmit = (event) => {
	  const form = event.currentTarget;
	  event.preventDefault();
	  event.stopPropagation();
	  if (form.checkValidity() === false) {
		setValidated(false);
	  } else {
		forgetPasswordRequest(formData);
	  }
	  setValidated(true);
	}
	
	const forgetPasswordRequest = async (post) => {
		setProcessing(true);
		await forgetPassword(post).then(res => { 
		// console.log('hi', res.payload.response)
			if(res.payload.response && res.payload.response.status === 401){
				setForgetErr(res.payload.response.data.message.errors);
			} else if(res.payload.data) {
				setSuccessMessage('Please check your email to reset your password');
			}
			setProcessing(false)
		}).catch(err=>{ 
		// console.log('hello', err)
			setProcessing(false)
		});
	}

	
  return (
    <SinglePageLayout>
        <div className="sign-in">
			<div className="sign-in-left">
				<div className="top">
					<Link to={`/sign-in`} className="logo"><img src={logo} alt="Clubkillers sign in" title="Clubkiller sing in"/></Link>
					<Link to={`/sign-in`} className="close">
						<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
						</svg>
					</Link>
				</div>
				<div className="form-sec">
					<Link to={`/sign-in`} className="back">
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
					</Link>
					<h1>Recover Password</h1>
					
					<div>
						{
							forgetErr && forgetErr !== '' ?
								<div className="alert alert-danger">{forgetErr}</div>
							: ''
						}
						{
							successMessage && successMessage !== '' ?
								<div className="alert alert-success">{successMessage}</div>
							: ''
						}
						<Form noValidate validated={validated} onSubmit={handleSubmit} id="forgetPasswordForm" className="popup-form">
							
							{/* <div className="form-group">
								<label className="text-5">Email</label>
								<input className={ errors.email ? "text-3 form-control error-input" : "text-3 form-control" }
										type="text" 
										name="email"
										onBlur={form.handleBlurEvent}
          								onChange={handleChange}
										placeholder="Enter your email"/>
								<span className="error">{ errors.email ? errors.email : ""}</span>
							</div> */}
							<InputGroup className="form-group">
							<Form.Label className="text-5">Email</Form.Label>
							<Form.Control type="email" name="email" placeholder="Email" onChange={handleChange} required  />
							<Form.Control.Feedback type="invalid">
								Invalid Email
							</Form.Control.Feedback>
							</InputGroup>
							
							<p className="text-5">We will send you an email confirming to recover your password, please check your email.</p>
							<div className="form-group">
								{ processing ? <button className="primary-btn" disabled={processing}><Spinner animation="border" variant="dark" size="sm" /></button> : <button type="submit" className="primary-btn">Continue</button> }
							</div>
						</Form>
						
					</div>
				</div>
			</div>
			<div className="sign-in-right">
				<div className="inner-bg">
					<div className="content">
						<div className="custom-font">Enjoy Your Favourite Music With Club Killers</div>
						<h2>Club Killers - an mp3 pool, offering special edits to Dj's and disc jockeys.</h2>
					</div>
				</div>
			</div>
		</div>
    </SinglePageLayout>
  );
}

function mapStateToProps(state) {
  return {
	
  };
}

function mapDispatchToProps(dispatch) {
  return {
		forgetPassword: (post) => dispatch(actions.forgetPassword(post)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);