import React, {useState, useEffect} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import './faq.scss';
import {connect} from 'react-redux';
import * as faqAction from '../../store/faq/actions';

const  HelpdeskFAQS = ({ getFAQS, faqs, displayLoading, hideLoading  }) => {
  
    useEffect(() => { 
            // load Page Data
            loadPageData();
    },[]);

    const loadPageData = async() => {
        displayLoading();
        await getFAQS();
        hideLoading();
    }
    
  return (
    <MusicDashboard  accountPages={true} middleSectionCssClass={'account-info-sec'}>
       	<div className="content-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>Helpdesk</h2>
                </div>
                <AccountNav />
                <div className="account-inner-block">
                    <div className="faq-sec">
                        <div className="title-heading">
                            <h3>Frequently Asked Questions</h3>
                        </div>
                        <div className="faq-block">
                        {
                            faqs && faqs.faqs && faqs.faqs.length > 0 ? 
                            <Accordion defaultActiveKey="0"> 
                            {
                            faqs.faqs.map((faq, index) => {
                                return <Card key={`faq-${index+1}`}>
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header ><span dangerouslySetInnerHTML={{__html:faq.question}}></span></Accordion.Header>
                                    <Accordion.Body ><p dangerouslySetInnerHTML={{__html:faq.answer}}></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                    {/* <Accordion.Toggle  eventKey={index+1} >{ faq.question ? <span >jjj</span> : 'no'}</Accordion.Toggle>
                                    <Accordion.Collapse eventKey={index+1}>
                                    {faq.answer ? <div dangerouslySetInnerHTML={{__html:faq.answer}}></div> : 'answer'}
                                    </Accordion.Collapse> */}
                                </Card>
                                
                            })
                            }
                            </Accordion>
                            : ''
                        }
                        </div>
                    </div>
                </div>
            </div>
		</div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
    return {
        faqs: state.faq.faqs,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getFAQS: () => dispatch(faqAction.getFAQS()),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpdeskFAQS);