
import React, {useEffect, useState} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav'; 
import config from '../../config/config';
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form'
// import NumberFormat from 'react-number-format';
import { PatternFormat } from 'react-number-format';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content' 
import Spinner from 'react-bootstrap/Spinner';   
import InputGroup from 'react-bootstrap/InputGroup';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {connect} from 'react-redux';
import * as subscriptionAction from '../../store/subscription/actions'
import * as couponAction from '../../store/coupon/actions';

import { CanAddApplePay } from './canAddApplePay';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe("pk_test_51H0KA8IY2jIY0hCM9nSxMhp2YlTAcGtZ2HGyPJtuAumWNDgiK8RSJpzqh3Gj1czhAbGcpV0eYhEIgT9lIUT37ItV00NJv7OCYC");



const PaymentMethod = ({ displayLoading, hideLoading, getServices, plans, validateCoupon, getCustomerAgreement, agreement, processCardPaymentMethod, processPaypalPayment, getPlanDetails, customerProfile, subscriptionDetail, processApplePayment }) => {

    let match = useParams("/subscription/payment-method/:plan_id");
    const location = useLocation();
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const [ planId, setPlanId ] = useState();
    const [ subscriptionData, setSubscriptionData ] = useState();
    const [ paymentMethod, setPaymentMethod ] = useState();
    const [ couponCode, setCouponCode ] = useState();
    const [ coupon, setCoupon ] = useState();
    const [ formSubmited, setForrmSubmited ] = useState(false);
    const [formData, setFormData] = useState({
        cardholder_name: '',
        card_number: '',
        card_type: '',
        expire_date: '',
        ccv: '', 
        payment_method: '', 
        terms_condition: ''
    });
    const visaTypeOptions = [
        {label: "Visa", value: "Visa"},
        {label: "MasterCard", value: "MasterCard"},
        {label: "Discover", value: "Discover"},
        {label: "Amex", value:"Amex"}
    ];
    const [ processCardPayment, setProcessCardPayment ] = useState(false);
    const [ errors, setErrors ] = useState({});
    const [ validated, setValidated] = useState(false);
    const [ couponApplied, setCouponApplied ] = useState(false);
    const [ selectedPlan, setSelectedPlan] = useState();
    const [ value, setValue] = useState();
    const [processCoupon, setProcessCoupon ] = useState(false)


    useEffect(() => { 
		// load Page Data
		loadPageData();
    },[]);
    
    useEffect(() => { 
		if(match.plan_id){
            setPlanId(match.plan_id)
        }else{
            navigate("/subscription/plans");
        }
    },[location]);

    // Profile is already created and paymment processing then redirect them to plan page
    if(agreement && agreement.profile_status == 'CREATED'){
        navigate('/subscription/plans');
    }

    const loadPageData = async() => {
        displayLoading();
        await getServices({subscription_type_id: 1}).then((res) => {
            if(res && res.payload && res.payload.data && res.payload.status == 'true'){
                let selectedPlanFromRes = res.payload.data.find(  service  => service.id ==  match.plan_id);
                setSelectedPlan(selectedPlanFromRes);
                getPlanDetails({coupon: coupon,  subscription: selectedPlanFromRes}).then(res => {
                
                })
            }
        });
        await getCustomerAgreement();
        
        hideLoading();
    }

    const setPromoCode = (event) => {
        const { name, value } = event.target;
        setCouponCode(value)
    }

    const checkCoupon = async() => {
        if(couponCode){
            setProcessCoupon(true);
            // call Validate coupon code
            await validateCoupon({coupon_code: couponCode, subscription_id: planId}).then((res)=>{
                if(res && res.payload && res.payload.data && res.payload.data.status && res.payload.status == 'true'){
                    setCoupon(res.payload.data);
                    setCouponApplied(true);
                    getPlanDetails({coupon: res.payload.data,  subscription: selectedPlan}).then(res => {
                    })
                   
                } else {
                    setCoupon('');
                    setCouponApplied(false);
                    Swal.fire({
                        icon: 'error',
                        title: "Invalid Coupon!", 
                        text: res.payload.message.errors[0],
                        type: "error", 
                        closeOnConfirm: true
                    }, function(){
                        
                    });
                }
            });
            setProcessCoupon(false);
           
            // setCouponApplied(true)
        } else {
            Swal.fire({
                icon: 'error',
                title: "Invalid Coupon!", 
                text: 'Please enter coupon code',
                type: "error", 
                closeOnConfirm: true
            }, function(){
                
            });
            
        }
    }

    const processPayment = async (formData) => { 
        displayLoading();
        setProcessCardPayment(true);
        if(paymentMethod && paymentMethod == 'credit_card') {
            await processCardPaymentMethod({formData: formData, coupon: coupon,  subscription: selectedPlan}).then(res => {
                if(res && res.payload && res.payload.data){

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "Transcation Failed!", 
                        text: res.payload.message.errors[0],
                        type: "error", 
                        closeOnConfirm: true
                    }, function(){
                        
                    });
                }
            });
            hideLoading();
            setProcessCardPayment(false);
        } else if(paymentMethod && paymentMethod == 'paypal') {
            let purchasePlan = selectedPlan;
            purchasePlan.payment_method = paymentMethod;
            
            await processPaypalPayment({subscription: purchasePlan, coupon: coupon}).then((res) => {
                if(res && res.payload && res.payload.data && res.payload.data.status && res.payload.status == 'true'){
                    hideLoading();
                    window.location.href = res.payload.data.paypal;
                    setProcessCardPayment(false);
                }
            });
        } else if(paymentMethod && paymentMethod == 'mobile_wallet') {
            await getPlanDetails({coupon: coupon,  subscription: selectedPlan}).then(res => {
                hideLoading();
                setProcessCardPayment(false);
                navigate(`/subscription/in-app-payment/${planId}`)
            })
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });    
        if(name == 'payment_method'){
            setPaymentMethod(value);
            setForrmSubmited(false);
        }
        if(formSubmited && paymentMethod && paymentMethod == 'credit_card'){
            const newErrors = findFormErrors();
            setErrors(newErrors)
        }
        if(name == 'payment_method' && value == 'mobile_wallet'){
            getPlanDetails({coupon: coupon,  subscription: selectedPlan}).then(res => {
                
            })
        }
    }

    const findFormErrors = () => {
        const { cardholder_name, card_number, expire_date, ccv, payment_method, terms_condition, card_type } = formData
        const newErrors = {}

        if(payment_method && payment_method == 'credit_card'){
            if ( !cardholder_name || cardholder_name === '' ) newErrors.cardholder_name = 'Card Holder Name cannot be blank!'
            if ( !card_number || card_number === '' ) newErrors.card_number = 'Card Number cannot be blank!'
            if ( !expire_date || expire_date === '' ) newErrors.expire_date = 'Expire Date cannot be blank!'
            if ( !ccv || ccv === '' ) newErrors.ccv = 'CCV cannot be blank!'
            if(!card_type || card_type === '') newErrors.card_type = 'Select card type'
        }
        
        if( !payment_method || payment_method === '')  newErrors.payment_method = 'Select a payment method'
        if( !terms_condition || terms_condition === '')  newErrors.terms_condition = 'Terms & Condition is require'

        return newErrors
    }
    const handleSubmit = (event) => { 
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setForrmSubmited(true);
        // console.log('formData', formData)
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors);
            setForrmSubmited(false);
        } else {
            
            processPayment(formData);
        }
    }

    const displayTermsCondition = () => {
        MySwal.fire({
            title: "<div class='terms-title'>Terms & Condition</div>",
            html: "<div class='terms-content'><p>Before proceeding you must agree to Club Killers, Inc terms and conditions</p>"+
            "<ul>"+
            "<li>You agree each bill is automatically deducted from your checking or savings account based on your selected plan. You’ll never be bothered with having to remember to pay your bill. Club Killers takes care of it for you, even when you’re away on vacation.</li>"+
            "<li>It is your responsibility to: Ensure there are sufficient funds available in your checking or savings account to meet each withdrawal on the due date. If there are insufficient funds in your account, your financial institution may charge you a fee and/or interest. You are solely responsible for paying any fee and/or interest charged by your financial institution.</li>"+
        "</ul></div>",
            type: "info",
            allowOutsideClick: false,
        });
    }

    const processWalletPayment = (event) => {
            processApplePayment({applePay: event, coupon: coupon, subscription: subscriptionDetail}).then( res => {
                if(res && res.payload && res.payload.data && res.payload.data.status && res.payload.data.status == true){
                    event.complete('success');
                    navigate(`/subscription/success/${res.payload.data.agreement.stripe_subscription_id}`);
                } else {
                    event.complete('fail');
                    navigate(`/subscription/failed`);
                }
                
            });
    }
    
    return (
        <MusicDashboard middleSectionCssClass={"account-info-sec "}>
            <div className="content-sec">
                <div className="account-block">
                    <div className="top-heading">
                        <h2>Account Setting</h2>
                    </div>
                    <AccountNav/> 
                    <div className="account-inner-block">
                        <div className="subscription-sec">
                        <div className="top">
								<h5>Hi, {customerProfile.dj_name}! You haven’t subscribed yet</h5>
								<h1>Choose your payment method</h1>
								<p className="text-3">Please review your membership details and billing information to complete your order.</p>
							</div>
							<div className="payment-block">
                                <Form  validated={validated} onSubmit={handleSubmit}>
								<div className="left">
									<div className="pay-sec">
										<h5>Select payment method</h5>
							                <Form.Check 
                                                name="payment_method"
                                                type="radio" 
                                                className=""
                                                id={`wallet`}
                                                label={'Google/Apple Pay' }
                                                onChange={handleChange} 
                                                value="mobile_wallet" 
                                                isInvalid={ !!errors.payment_method }
                                                
                                            />
                                            <Form.Check 
                                                    name="payment_method"
                                                    type="radio"
                                                    id={`paypal`}
                                                    label={'PayPal'}
                                                    onChange={handleChange}
                                                    value="paypal"
                                                    isInvalid={ !!errors.payment_method }
                                                />
                                            <Form.Check 
                                                    name="payment_method"
                                                    type="radio"
                                                    id={`creditcard`}
                                                    label={'Credit Card'}
                                                    onChange={handleChange}
                                                    value="credit_card" 
                                                    isInvalid={ !!errors.name }
                                                    isInvalid={ !!errors.payment_method }
                                                />
                                            
									</div>
                                    {
                                        paymentMethod && paymentMethod == 'paypal' ?
                                            <div className="paypal-payment">
                                                <span>We'll redirect you to PayPal to complete your purchase.</span>
                                            </div>
                                        : ''
                                    }
                                    <Form.Control.Feedback type="invalid">
                                        { errors.payment_method }
                                    </Form.Control.Feedback>
                                    { 
                                        paymentMethod && paymentMethod == 'credit_card' ?
                                            <div className="form-content">
                                                <Form.Group className="form-group" controlId="formCardHolderName" >
                                                    <Form.Label>Card Holder Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Card Holder Name" name="cardholder_name" onChange={handleChange} isInvalid={ !!errors.cardholder_name } />
                                                    <Form.Control.Feedback type="invalid">
                                                        { errors.cardholder_name }
                                                    </Form.Control.Feedback>
                                                    
                                                </Form.Group>
                                                <Form.Group className="form-group" controlId="formCardNumber">
                                                    <Form.Label>Card Number</Form.Label>
                                                    {/* <Form.Control type="tel" inputmode="numeric" pattern="\d*" autocomplete="cc-number" maxlength="19" placeholder="xxxx xxxx xxxx xxxx" name="card_number" onChange={handleChange} isInvalid={ !!errors.card_number } />  */}
                                                    <PatternFormat customInput={Form.Control} name="card_number" format="#### #### #### ####" mask="X" placeholder="XXXX XXXX XXXX XXXX" onChange={handleChange} isInvalid={ !!errors.card_number } />
                                                    <Form.Control.Feedback type="invalid">
                                                        { errors.card_number }
                                                    </Form.Control.Feedback>
                                                    
                                                </Form.Group>
                                                {
                                                    visaTypeOptions && visaTypeOptions.length > 0 ?
                                                        <Form.Group className="form-group" controlId="formCardType">
                                                            <Form.Label>Card Type</Form.Label>
                                                            <Form.Control  as="select" type="select" name="card_type" onChange={handleChange} isInvalid={ !!errors.card_type }>
                                                                <option value="">Select Card Type</option> 
                                                                {
                                                                    visaTypeOptions.map((type, index)=>{
                                                                    return <option value={type.value} key={`cardtype-${index}`}>{type.label}</option>
                                                                    })
                                                                }
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                { errors.card_type }
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    : ''
                                                }
                                                <div className="two-column">
                                                    <Form.Group className="form-group" controlId="formCardNumber">
                                                        <Form.Label>Expiration</Form.Label>
                                                        {/* <Form.Control type="text" placeholder="MM/YY" name="expire_date" onChange={handleChange} isInvalid={ !!errors.expire_date } /> */}
                                                        <PatternFormat customInput={Form.Control} name="expire_date" format="##/##"  placeholder="MM/YY" onChange={handleChange} isInvalid={ !!errors.expire_date } />
                                                        <Form.Control.Feedback type="invalid">
                                                            { errors.expire_date }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group className="form-group" controlId="formCardNumber">
                                                        <Form.Label>CCV</Form.Label>
                                                        <Form.Control className="text-3 form-control" type="text" placeholder="" name="ccv" onChange={handleChange} isInvalid={ !!errors.ccv } />
                                                        <Form.Control.Feedback type="invalid">
                                                            { errors.ccv }
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                
                                            </div>
                                    : ''
                                    }
									<div className="custominput">
										<Form.Check 
                                                value="1"
                                                type='checkbox'
                                                id={`terms-vondition`}
                                                onChange={handleChange}
                                                name="terms_condition"
                                                label={<span className="text-5" >By check you accept our <span onClick={() => displayTermsCondition()}>Terms & Condition</span>.</span>} 
                                                isInvalid={ !!errors.terms_condition }
                                            />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.terms_condition }
                                        </Form.Control.Feedback>
									</div>
                                {
                                //    subscriptionDetail &&  paymentMethod && paymentMethod == 'mobile_wallet' ?   
                                //         <Elements stripe={stripePromise}>
                                //             <CanAddApplePay subscriptionData={subscriptionDetail} onConfirmPayment={processWalletPayment}/>
                                //         </Elements>
                                //     : formSubmited ? 
                                     formSubmited ?  <button disabled="disabled" className="primary-btn"><Spinner animation="border" size="sm" variant="dark" /></button> : <button className="primary-btn">Proceed to payment</button> 
                                }
                                
								</div>
                                </Form>
                                { 
                                    selectedPlan ? 
								    <div className="right">
                                        <div className="your-plan box">
                                            <div className="top">
                                                <h2>Your plan</h2>
                                { coupon && coupon.discount_amount > 0 ? <h2>Total: ${selectedPlan.price} - ${coupon.discount_amount} = ${selectedPlan.price - coupon.discount_amount}</h2> : <h2>Total: ${selectedPlan.price}</h2> }
                                            </div>
                                            <div className="body-text">
                                                <span className="text-2">Starting Today:</span>
                                                {
                                                    selectedPlan.trial_price ?
                                                        <span className="text-3">${selectedPlan.trial_price} First {selectedPlan.trial_interval} {selectedPlan.trial_period} thereafter ${selectedPlan.price} every {selectedPlan.payment_interval} {selectedPlan.payment_period}.</span>
                                                    : 
                                                        coupon && coupon.discount_amount > 0 ? <span className="text-3">${selectedPlan.price - coupon.discount_amount} every {selectedPlan.payment_interval} {selectedPlan.payment_period}</span> : <span className="text-3">${selectedPlan.price} every {selectedPlan.payment_interval} {selectedPlan.payment_period}</span>
                                                }
                                                <br />
                                                <span className="text-2">Billing detail:</span>
                                                {
                                                    selectedPlan.trial_price ?
                                                        <span className="text-3">${selectedPlan.trial_price} First {selectedPlan.trial_interval} {selectedPlan.trial_period} thereafter ${selectedPlan.price} every {selectedPlan.payment_interval} {selectedPlan.payment_period}.</span>
                                                    :
                                                    coupon && coupon.discount_amount > 0 ? <span className="text-3">${selectedPlan.price - coupon.discount_amount} every {selectedPlan.payment_interval} {selectedPlan.payment_period}.</span> : <span className="text-3">${selectedPlan.price} every {selectedPlan.payment_interval} {selectedPlan.payment_period}.</span>
                                                }
                                            </div>
                                            <p className="text-5">Cancel anytime. <a href="#">Offer term</a> apply</p>
                                        </div>
                                        <div className="box promo-apply">
                                            <h2>Have a promo code?</h2>
                                            <div className="form-content">
                                                <InputGroup className="form-group">
                                                    <Form.Label className="text-5">Promotion code</Form.Label>
                                                    <FormControl
                                                        placeholder="Have a Promo Code?"
                                                        aria-label="Promo Code"
                                                        className="text-3 form-control" 
                                                        onChange={setPromoCode}
                                                    />
                                                    
                                                </InputGroup>
                                            </div>
                                            { couponApplied == true && coupon ? <p>Discount <span className="text-danger">${coupon.discount_amount}</span> applied {couponApplied}</p> : ''}
                                            { couponApplied == true ? <InputGroup.Text className="border-0 bg-none px-2 ">{processCoupon ? <button className="secondary-btn"><Spinner animation="border" size="sm" variant="dark" /></button> : <button className="secondary-btn" onClick={() => checkCoupon()}>Change</button>}</InputGroup.Text> : ''}
                                            { couponApplied == false ? processCoupon ? <button className="secondary-btn"><Spinner animation="border" size="sm" variant="dark" /></button> :<button className="secondary-btn" onClick={() => checkCoupon()}>Apply</button> : ''}
                                        </div>
								    </div>
                                     : ''
                                }
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </MusicDashboard>
    );
}
function mapStateToProps(state) {
  return {
	agreement: state.subscription.agreement,
    plans: state.subscription.plans,
    customerProfile: state.customer.customerProfile,
    subscriptionDetail: state.subscription.subscriptionDetail
  };
}

function mapDispatchToProps(dispatch) {
  	return {
        getServices: (post) => dispatch(subscriptionAction.getServices(post)),
        validateCoupon: (post) => dispatch(couponAction.validateCoupon(post)),
        getCustomerAgreement: () => dispatch(subscriptionAction.getCustomerAgreement()),
        processCardPaymentMethod: (post) => dispatch(subscriptionAction.processCardPaymentMethod(post)),
        processPaypalPayment: (post) => dispatch(subscriptionAction.processPaypalPayment(post)),
        getPlanDetails: (post) => dispatch(subscriptionAction.getPlanDetails(post)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        processApplePayment: (post) => dispatch(subscriptionAction.processApplePayment(post))
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);