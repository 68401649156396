import React, { useState, useEffect } from "react";

import FavouriteButton from '../../elements/songElements/favouriteButton';
import CrateDownloadButton from '../../elements/songElements/crateDownloadButton';
import PlayPauseButton from '../../elements/songElements/playPauseButton';
// import DownloadCrateVersionButton from '../actionBtn/downloadCrateVersion';
import {connect} from 'react-redux';
import * as actions from '../../store/song/actions';
import * as playlistActions from '../../store/playlist/actions';
import * as downloadActions from '../../store/download/actions';
import * as songActions from '../../store/song/actions';
import * as crateActions from '../../store/crate/actions';

const  CrateSongTrack = ({ songs, crateSongs, getCrateList, addSongToCrate, getParentCustoemrCrates, currentGenreId, perPageLimit, page }) => {

    const [activeSong, setActiveSong] = useState();
	const [activeSongId, setActiveSongId] = useState(0);
	const [isListExpanded, setIsListExpanded] = useState(false);
	const [ firstVersionAdded, setFirstVersionAdded ] = useState(0);
	const [activeAlbumSong, setActiveAlbumSong ] = useState(false);
    const removeSongFromCrate = async (songId,  versionId ) => {
        await addSongToCrate(songId, versionId);
        await getCrateList({page: page, pageLimit: perPageLimit, genre_id: currentGenreId}, () => {
                    
        });
        getParentCustoemrCrates();
        
    }
 
    
    return (
        <div className="play-list">
        {
            songs && songs.length > 0 ? 
                songs.map((song, index)=>{
                    return <div className={ activeSongId == song.entity_id ? "block" : "block"} key={`songcrate${index}${song.entity_id}`}>
                                <div className="play-track">
                                    <PlayPauseButton parentComponent="songVersion" song={song} version={song.version} />
                                    <div className="track-name">
                                        <span className="text-2" dangerouslySetInnerHTML={{__html: song.title}}></span>
                                        <span className="text-5" dangerouslySetInnerHTML={{__html: song.artist}}></span>
                                    </div>
                                </div>
                                <div className="play-track-content">
                                    <div>
                                        <span className="text-4">{song.max_bpm}</span>
                                        <span className="text-4">{song.song_key}</span>
                                        <span className="text-5">{song.year}</span>
                                        <span className="text-4">{song.version.version_label}</span>
                                    </div>
                                    
                                    <div className="divider">
                                        <FavouriteButton song={song} />
                                        <CrateDownloadButton songId={song.entity_id} version={song.version} songSectionId={song.song_section_id} getCustomerCrateSongs={getParentCustoemrCrates} />
                                        <span onClick={() => removeSongFromCrate(song.entity_id, song.version.song_version_id)}>
											<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
											</svg>
										</span>
                                    </div>
                                    
                                </div>
                                
                        </div>
                    })
            : ''
        }
        </div>
    );
  
}


function mapStateToProps(state) {
  return {
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    addSongToCrate: (songId, versionId) => dispatch(actions.addSongToCrate(songId, versionId)),
    getCrateList: (post) => dispatch(crateActions.getCrateList(post)),
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(CrateSongTrack);
