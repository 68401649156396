import React, { useEffect} from "react";
import MusicDashboard from "../../layouts/musicDashboard";
import {connect} from 'react-redux';
import * as authAction from '../../store/authentication/actions';
import { useNavigate } from "react-router-dom";

const Logout = ({ logout}) => {
    const navigate = useNavigate();
    useEffect(() => { 
        logout();
        // window.location.href = '/';
        navigate('/sign-in');
	},[])
    

    

// console.log('profile', profile)
    return (
        <MusicDashboard>
        

        </MusicDashboard>
    );
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication
    };
}

function mapDispatchToProps(dispatch) {
    return {
       logout: () => dispatch(authAction.logout())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);