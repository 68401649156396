import React, { useState, useEffect } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";


import GenresMoods from './genresMood';
import RightSide from "../../components/rightSide/rightSide";

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import MusicDashboard from '../../layouts/musicDashboard';

import ImageLoader from '../../components/loader/imageLoader';
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import * as genreActions from '../../store/genres/actions';



const  GenresIndex = ({  getGenresWithCount, genresWithCount, displayLoading, hideLoading }) => {

    const navigate = useNavigate();
   
    
    useEffect(() => { 
		loadData();
	},[])

	const loadData = async() => {
        displayLoading();
        await getGenresWithCount();
        hideLoading();
    }

	return (
			<MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true}} />}>
                <div className="content-sec">
                    <GenresMoods />
                    <div className="genres-sec">
                        <div className="top-heading">
                            <h2>Genres</h2>
                        </div>
                        {
                            genresWithCount  && genresWithCount.length > 0 ? 
                                genresWithCount.map((genre, index) => {
                                    return <div className="genres-block" key={`genresHome${genre.genre_id}`}>
                                        <div className="img-block">
                                            <Link to={`/mp3/genres/${genre.genre_id}`}><img src={genre.image} title={genre.name} /></Link>
                                            <Link to={`/mp3/genres/${genre.genre_id}`}><h5 dangerouslySetInnerHTML={{__html: genre.name}}></h5></Link>
                                        </div>
                                        {
                                            genre.subgenre && genre.subgenre.length > 0 ?
                                                <div className="genres-right-block" >
                                                    <div className="tags">
                                                    {
                                                        genre.subgenre.map((subGenre, subGenreIndex) => {
                                                            return <Link to={`/mp3/genres/${subGenre.genre_id}`} key={`genreSubGEnreHome${subGenre.genre_id}`}><span  dangerouslySetInnerHTML={{__html: subGenre.name}}></span></Link>
                                                        })
                                                    }
                                                        
                                                    </div>
                                                </div>
                                                
                                            : ''
                                        }
                                    </div>
                                })
                            : <ImageLoader itemCount={1} />
                        }
                    </div>
                </div>
        </MusicDashboard>
    );
}

function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        genresWithCount: state.genre.genresWithCount,
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
		getGenresWithCount: () => dispatch(genreActions.getGenresWithCount()),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(GenresIndex);
