import * as types from './type';

const initialState = {
    playList: [],
    playListIndex: '',
    playerState: {
        muted: false,
        volume: 0.5,
        curPlayId: 1
    }
};

export default function playlistReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_SONG_TO_PLAYLIST: {

            if (action.payload && action.payload) {
                return {
                    ...state,
                    playList: [...state.playList, action.payload]
                }
            } else {
                return {
                    ...state
                }
            }
        
        }
        case types.SET_PLAYER_STATE:{
            console.log('action.payload', action.payload)
            if (action.payload && action.payload) {
                return {
                    playerState:  action.payload
                }
            }
        }
        case types.GET_CUSTOMER_PLAYLIST: {
            return {
                ...state,
                playList: [...state.playList]
            }
        }
        case types.SET_PLAYLIST_INDEX: {
            return {
                ...state,
                playListIndex: action.payload
            }
        }
        
        default: {
            return {
              ...state
            };
          }
    }
}