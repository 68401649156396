
import React, {useEffect, useState} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav'; 
import config from '../../config/config';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import * as subscriptionAction from '../../store/subscription/actions'
import * as billingAction from '../../store/billing/actions';
import CalanderSgv from './../../assets/images/calendar-group.svg';

const SubscriptionIndex = ({ displayLoading, hideLoading, getCustomerAgreement, agreement, getServices, plans, downloadBillingEntry, cancelSubscription, cancelCustomerFeedback, customerProfile}) => {

    const MySwal = withReactContent(Swal) 
    
    useEffect(() => { 
		// load Page Data
		loadPageData();
    },[]);
    

    const loadPageData = async() => {
        displayLoading();
        await getCustomerAgreement();
        await getServices({subscription_type_id: 1});
        hideLoading();
    }

    const cancelCurrentSubscription = async() => {
        displayLoading();
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ffa800',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, cancel it!'
		  }).then((result) => {
			if (result.isConfirmed) {
				cancelSubscription().then(res => {
					getCustomerAgreement().then(res => {
						hideLoading();
					});
					Swal.fire({
						title: 'Subsciption Cancelled',
						text: "Your subscription has been cancelled.no payment will be charge.",
						input: 'text',
						inputAttributes: {
						  autocapitalize: 'off',
						  placeholder: 'Sad to see you go.We would love some constructive criticism on our site'
						},
						showCancelButton: true,
						confirmButtonText: 'Submit',
						showLoaderOnConfirm: true,
						preConfirm: (message) => {
							if(message != ''){
								sendCustomerCancelFeedback(message)
							}
						},
						allowOutsideClick: () => !Swal.isLoading()
					  }).then((result) => {
						
					  })
				})
			
			}
		  })
    }

    const sendCustomerCancelFeedback = (message) => {
        
        cancelCustomerFeedback({message: message}).then(res  => {
			Swal.fire({
				title: "Thank you for your valuable feedback!", 
				text: "Hope to see you soon",
				type: "success", 
				closeOnConfirm: true
			}, function(){
                
            });
        });
    }
    
    return (
        <MusicDashboard middleSectionCssClass={"account-info-sec "}>
            <div className="content-sec">
                <div className="account-block">
                    <div className="top-heading">
                        <h2>Account Setting</h2>
                    </div>
                    <AccountNav/> 
                    <div className="account-inner-block">
                        <div className="subscription-sec">
                        {
                            agreement ?
                            <div className="top">
                                {
                                    agreement.profile_status == 'Active' ?
                                        <h5>Hi, {customerProfile.dj_name}! Your current plan is “monthly”. <span className="text-3 cp" onClick={() => cancelCurrentSubscription()}>Cancel </span></h5>
                                    :
                                    agreement.profile_status == 'CREATED' ?
                                        <h5>Hi, {customerProfile.dj_name}! Your subscription created successfully. It takes maximum 24 hours time to process payment.</h5>
                                    :
                                    agreement.profile_status == 'Cancelled' ? 
                                        <h5>Hi, {customerProfile.dj_name}! Your subscription has been ended. Subscription again.</h5>
                                    : ''
                                }
                            </div>
                            : 
                            <div className="top">
                                <h5>Hi, {customerProfile.dj_name}! You haven’t subscribed yet</h5>
                                <h1>Choose your plan</h1>
                                <p className="text-3">Create your account to experience on Clubkillers site</p>
                            </div>
                        }
                            <div className="subscription-block">
                            {
                                plans && plans.length > 0 ?
                                    plans.map((plan, index) =>{
                                        return <div className="subscription-box" key={`subscriptionplan${plan.id}`}>
                                                    <div className="inner-block">
                                                        <span className="icon"><img src={CalanderSgv} title=""/></span>
                                                        <h2>{plan.title}</h2>
                                                        <h1>${plan.price}<span>/month</span></h1>
                                                        {
                                                            plan.trial_price ?
                                                                <div>
                                                                    <span>${plan.trial_price}</span> First {plan.trial_interval} {plan.trial_period} thereafter <br /> <span>${plan.price}</span> every {plan.payment_interval} {plan.payment_period}
                                                                </div>
                                                            :
                                                                <div className="plan-text">
                                                                    <span>${plan.price}</span> every {plan.payment_interval} {plan.payment_period}
                                                                </div>
                                                        }
                                                        <div dangerouslySetInnerHTML={{__html: plan.features}} className="feature-list"></div>
                                                    </div>
                                                    { 
                                                        plan.is_upgrade_plan == 'yes' ? 
                                                            <Link to={`/subscription/payment-method/${plan.id}`} className="primary-btn">
                                                                {
                                                                    agreement ?
                                                                            agreement.profile_status == 'Active' ?
                                                                                <span>Upgrade to Premium</span>
                                                                            :
                                                                            agreement.profile_status == 'CREATED' ?
                                                                                <h5>Payment under process</h5>
                                                                            :
                                                                            agreement.profile_status == 'Cancelled' ? 
                                                                                <span>Subscribe to Premium</span>
                                                                            : ''
                                                                    : 
                                                                    <span>Subscribe to Premium</span>
                                                                }
                                                            </Link> 
                                                        : 
                                                            <Link to={`/subscription/payment-method/${plan.id}`} className="primary-btn">
                                                                {
                                                                    agreement ?
                                                                            agreement.profile_status == 'Active' ?
                                                                                <span>Upgrade to Monthly</span>
                                                                            :
                                                                            agreement.profile_status == 'CREATED' ?
                                                                                <h5>Payment under process</h5>
                                                                            :
                                                                            agreement.profile_status == 'Cancelled' ? 
                                                                                <span>Subscribe to Monthly</span>
                                                                            : ''
                                                                    : 
                                                                    <span>Subscribe to Monthly</span>
                                                                }
                                                            </Link>}
                                                </div>
                                    })
                                : ''
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MusicDashboard>
    );
}
function mapStateToProps(state) {
  return {
	agreement: state.subscription.agreement,
    plans: state.subscription.plans,
    customerProfile: state.customer.customerProfile
  };
}

function mapDispatchToProps(dispatch) {
  	return {
      	getCustomerAgreement: () => dispatch(subscriptionAction.getCustomerAgreement()),
        getServices: (post) => dispatch(subscriptionAction.getServices(post)),
		downloadBillingEntry: (post) => dispatch(billingAction.downloadBillingEntry(post)),
		cancelSubscription: () => dispatch(subscriptionAction.cancelSubscription()),
        cancelCustomerFeedback: (post) => dispatch(subscriptionAction.cancelCustomerFeedback(post)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionIndex);